import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  wrapper: {
    maxWidth: "700px",
    height: "100vh",
    marginLeft: "auto",
    marginRight: "auto",
  },
  errorIconContainer: {
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  icon: {
    color: "#BE0F05",
  },
});
