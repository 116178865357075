import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  wrapper: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    width: "522px",
    height: "calc(100vh - 61px)",
    padding: "0px",
  },
  cardWrapper: {
    paddingLeft: "64px",
    paddingRight: "64px",
    border: "1px solid #E0E0E0",
    boxShadow: "0px 2px 4px rgba(94, 124, 143, 0.15)",
  },
  title: {
    marginTop: "24px",
  },
  top: {
    justifyContent: "space-between",
    padding: "var(--epods--space-inset-m)",
  },
  bottomButtons: {
    float: "right",
    paddingBottom: "24px",
  },
  preWrap: {
    whiteSpace: "pre-wrap",
  },
  inputPhone: {
    width: "55%",
    marginTop: "20px",
  },
  sizeText18: {
    fontSize: "16px",
    fontFamily: "opensans-regular",
  },
  marginMiddle: {
    marginTop: "30px",
  },
  marginButtons: {
    marginBottom: "60px",
  },
});
