import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Notification, Link } from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";

import { config } from "../../../config/config";
import CardWithLogo from "../../common/cardwidthlogo/CardWithLogo";
import { NOTIFICATION_CLOSE_TIME } from "../../../constants";
import { useStyles } from "./styles";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const [t] = useTranslation("global");

  return (
    <div role="alert">
      <p>{t("COMMON.something-went-wrong")}</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>{t("COMMON.try-again")}</button>
    </div>
  );
};

const ErrorFunction = (error: Error, info: { componentStack: string }) => {
  const { authState } = useOktaAuth();
  let configHeaders = {
    headers: {
      Authorization: `Bearer ${authState.accessToken.accessToken}`,
    },
  };

  axios.post(
    config.api.baseUrl + "/logs/" + authState.idToken.claims.sub + "/log/error",
    {
      msg: error.message,
      level: { info, error },
      stacktrace: error.stack,
      timestamp: Date.now(),
      component: "Mailbox-confirmation",
      client: "CIAM APP",
    },
    configHeaders
  );
};

const MailboxConfirmation = withErrorBoundary(
  () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [feedback, setFeedback] = useState(false);

    const [t] = useTranslation("global");
    let { username } = useParams();
    const classes = useStyles();

    const handleSubmit = (event: any) => {
      axios
        .post(
          `${config.api.baseUrl}/users/${username}/resend_registration_email`
        )
        .then((response) => {
          setFeedback(false);
          if (response.status === 200) {
            setFeedback(true);
            setSuccessMessage(t("VIEWS.REGISTRATION.email-sent-successfully"));
          }
        })
        .catch((error) => {
          setFeedback(false);
          if (error.response.status === 400) {
            setFeedback(true);
            setErrorMessage(t("VIEWS.REGISTRATION.username-dont-exist"));
          } else {
            setFeedback(true);
            setErrorMessage(t("COMMON.generic-error"));
          }
        });
      event.preventDefault();
    };

    return (
      <>
        {feedback && (
          <div className={classes.feedbackMessage}>
            <Notification
              text={errorMessage || successMessage}
              theme={errorMessage ? "negative" : "positive"}
              autoHideDuration={NOTIFICATION_CLOSE_TIME}
            />
          </div>
        )}

        <CardWithLogo centered>
          <h1>{t("VIEWS.REGISTRATION.MAILBOX.mailbox-title")}</h1>

          <p>{t("VIEWS.REGISTRATION.MAILBOX.mailbox-message")}</p>

          <p className="mb-s">
            {t("VIEWS.REGISTRATION.MAILBOX.email-not-received-message")}{" "}
            <Link
              onClick={(e) => handleSubmit(e)}
              id="resend"
              data-testid="resend-email-link"
            >
              {t("VIEWS.REGISTRATION.MAILBOX.resend-email-link")}
            </Link>
          </p>

          <p className="mt-l">{t("VIEWS.REGISTRATION.MAILBOX.closing-info")}</p>
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError: ErrorFunction,
  }
);

export default MailboxConfirmation;
