import {
  Avatar,
  Button,
  Dialog,
  DropdownButton,
} from "@epo/epods-react-components";
import { DialogFooter } from "@epo/epods-react-components/lib/Components/Dialog";
import { OktaAuth } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { config } from "../../config/config";
import { useUser } from "../../context/user-context";
import IdleTimer from "../common/IdleTimer";
import logo from "./../../assets/images/epo-logo.jpg";
import { useStyles } from "./styles";

const Navbar = () => {
  const [t] = useTranslation("global");
  const classes = useStyles();
  const { authState } = useOktaAuth();
  const location = useLocation();
  const [pathName, setPathName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUser();
  const authClient = new OktaAuth(config.oidc);
  let configHeaders = {
    headers: {
      Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
    },
    withCredentials: true,
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const timer = new IdleTimer({
        timeout: config.idle.timeout, // Seconds of idle for timeout
        alertTimeout: config.idle.alert, // Seconds before timeout warning,
        onAlert: () => {
          setIsOpen(true);
        },
        //onTimeout: The callback will be triggered if the users are in the app and have the idle timeout.
        onTimeout: () => {
          timeoutLogout();
          window.alert(t("COMMON.timeout-alert"));
        },
        //onExpired: The callback will be triggered if the users re-open the app after the expired time.
      });
      return () => {
        timer.cleanUp();
      };
    } else {
      sessionStorage.removeItem("_expiredTime");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, authState?.isAuthenticated]);

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  const timeoutLogout = async () => {
    axios
      .get(
        `${config.api.baseUrl}/users/${authState.idToken.claims.sub}/clear_session_cookies`,
        configHeaders
      )
      .then(async () => {
        sessionStorage.removeItem("_expiredTime");
        await logout();
      });
  };

  const logout = async () => {
    await authClient.revokeAccessToken(); // strongly recommended
    await authClient.closeSession().catch((e) => {
      if (e.xhr && e.xhr.status === 429) {
        // Too many requests
      }
    });
    await authClient.signOut({
      postLogoutRedirectUri: `${window.location.origin}/logout`,
    });
  };

  const onItemSelected = (selection) => {
    if (selection.id === "logout") {
      axios
        .get(
          `${config.api.baseUrl}/users/${authState.idToken.claims.sub}/clear_session_cookies`,
          configHeaders
        )
        .then(async () => {
          await logout();
        });
    } else if (selection.id === "help") {
      goToHelp();
    }
  };

  const goToHelp = () => {
    const helpPage: string =
      pathName === "/migration" ? "migration" : "account";
    const langId = user.locale;
    if (langId === "de_DE") {
      window.open(`${window.location.origin}/help/${helpPage}_de.html`);
    } else if (langId === "fr_FR") {
      window.open(`${window.location.origin}/help/${helpPage}_fr.html`);
    } else {
      window.open(`${window.location.origin}/help/${helpPage}.html`);
    }
  };

  const TimeoutAlertDialog = () => {
    return (
      <>
        <Dialog
          header={t("VIEWS.NAVBAR.dialog-header")}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <div>{t("VIEWS.NAVBAR.dialog-body")}</div>
          <DialogFooter>
            <Button onClick={() => setIsOpen(false)}>
              {t("VIEWS.NAVBAR.close-dialog")}
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  };
  
  const Body = () => {
    if (
      !authState?.isAuthenticated ||
      pathName === "/" ||
      pathName === "/registration" ||
      pathName === "/registration-error" ||
      pathName === "/signup/confirmation" ||
      pathName === "/migration-confirmation" ||
      pathName === "/logout"
    ) {
      return <></>;
    } else {
      return (
        <>
          {isOpen && <TimeoutAlertDialog />}
          <div className={classes.navBar}>
            <div className={classes.left}>
              <img
                className={classes.logo}
                src={logo}
                alt={t("COMMON.epo-logo-alt-text")}
              />
            </div>
            <div className={classes.right}>
              <DropdownButton
                className={classes.profileDropdown}
                items={[
                  {
                    id: "help",
                    label: t("VIEWS.NAVBAR.dropdown-help"),
                  },
                  {
                    id: "logout",
                    label: t("VIEWS.NAVBAR.dropdown-logout"),
                  },
                ]}
                onItemSelect={(selection) => onItemSelected(selection.item)}
                styleListContainer={{
                  width: "228px",
                }}
                popoverPlacement="bottomRight"
                data-testid="dropdown-navbar"
              >
                <Avatar name={`${user.firstName} ${user.lastName}`}></Avatar>
              </DropdownButton>
            </div>
          </div>
        </>
      );
    }
  };

  return <Body />;
};

export default Navbar;
