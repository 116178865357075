import { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";

const SignIn = () => {
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    const login = async () => {
      try {
        await oktaAuth.signInWithRedirect();
      } catch (err) {
        console.log(err);
      }
    };

    login();
  }, [oktaAuth]);

  if (!authState) {
    return null;
  } else {
    return <></>;
  }
};

export default SignIn;
