import { Button } from "@epo/epods-react-components";
import { CheckCircle } from "@epo/epods-react-components/lib/Icons/CheckCircle";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../../config/config";
import { useMigrated, useUnlocked } from "../../../context/user-context";
import CardWithLogo from "../../common/cardwidthlogo/CardWithLogo";
import { useStyles } from "./styles";
import { ErrorFallback, logError } from '../../errorHandling';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const EnrolledFactors = withErrorBoundary(
  () => {
    let query = useQuery();
    const [t] = useTranslation("global");
    const classes = useStyles();
    const history = useHistory();
    const { migratedUser } = useMigrated();
    const { setUnlocked } = useUnlocked();
    const { authState } = useOktaAuth();
    let configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };
    const redirect = (
      path: string,
      queryParams?: { name: string; value: string }
    ) => {
      path = "/" + path;
      if (queryParams) {
        path = path.concat(`?${queryParams.name}=${queryParams.value}`);
      }
      history.push(path);
    };

    return (
      <CardWithLogo centered noLogo withNavbar>
        <div className="text-center">
          <CheckCircle className={classes.circleIcon} size="48px" />
          <h1>
            {t(`VIEWS.MFA.mfa-added-title-${query.get("mfa_name")}`, {
              key_name: query.get("key_name"),
            })}
          </h1>
        </div>
        <p>{t(`VIEWS.MFA.mfa-added-subtext-${query.get("mfa_name")}`)}</p>
        <p>{t("VIEWS.MFA.additional-verification-methods")}</p>

        <div className="text-center mt-l">
          <Button
            onClick={() => {
              if (migratedUser?.migrated) {
                if (migratedUser.numRegisteredFactors === 0) {
                  setUnlocked(false);
                }
                redirect("migration");
              } else {
                axios
                  .get(
                    `${config.api.baseUrl}/users/${authState.idToken.claims.sub}/clear_session_cookies`,
                    configHeaders
                  )
                  .then(() => {
                    window.location.replace("/account");
                  });
              }
            }}
            data-testid="back-to-settings"
          >
            {migratedUser?.migrated
              ? t("VIEWS.MFA.go-back-to-migration")
              : t("VIEWS.MFA.go-back-to-account-settings")}
          </Button>
        </div>
      </CardWithLogo>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError: logError,
  }
);

export default EnrolledFactors;
