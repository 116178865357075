import { Button } from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { config } from "../../../../config/config";
import CardWithLogo from "../../../common/cardwidthlogo/CardWithLogo";
import Notification from "../../../common/notification/Notification";
import { ErrorFallback, logError } from "../../../errorHandling";

const OktaSmsSent = withErrorBoundary(
  () => {
    const { authState } = useOktaAuth();
    const [t] = useTranslation("global");
    const history = useHistory();
    const configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };
    const [pollingFactorResult, setPollingFactorResult] = useState("WAITING");
    let { factorId } = useParams();
    const [feedback, setFeedback] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const redirect = (
      path: string,
      queryParams?: { name: string; value: string }
    ) => {
      path = "/" + path;
      if (queryParams) {
        path = path.concat(`?${queryParams.name}=${queryParams.value}`);
      }
      history.push(path);
    };

    const polling = () => {
      axios
        .post(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors/" +
            factorId +
            "/activate/poll",
          {},
          configHeaders
        )
        .then((response) => {
          setPollingFactorResult(response.data.factorResult);
          if (response.data.status === "ACTIVE") {
            redirect("MFA/enrolled-factors", {
              name: "mfa_name",
              value: "okta",
            });
          } else {
            setTimeout(function () {
              polling();
            }, 3000);
          }
        })
        .catch((error) => {
          setFeedback(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
    };

    useEffect(() => {
      axios
        .post(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors/" +
            factorId +
            "/activate_okta_factor",
          {},
          configHeaders
        )
        .then((response) => {
          if (response.data.status === "ACTIVE") {
            redirect("MFA/enrolled-factors", {
              name: "mfa_name",
              value: "okta",
            });
          } else if (
            response.data.factorResult === "TIMEOUT" ||
            response.data.factorResult === "REJECTED"
          ) {
            console.log("registration error");
          } else {
            if (pollingFactorResult === "WAITING") {
              polling();
            }
          }
        })
        .catch((error) => {
          setFeedback(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.idToken.claims.sub, authState.accessToken.accessToken]);

    return (
      <>
        {feedback ? (
          <Notification text={errorMessage} theme="negative" />
        ) : null}
        <CardWithLogo centered noLogo withNavbar>
          <h1 className="text-center">{t("VIEWS.MFA.sent-you-sms")}</h1>

          <p>{t("VIEWS.MFA.please-follow-sms-link-to-finish-okta")}</p>

          <div className="row mt-xl">
            <div className="col text-center">
              <Button
                onClick={() => redirect("account")}
                data-testid="go-back-account-btn"
              >
                {t("VIEWS.MFA.go-back-to-account-settings")}
              </Button>
            </div>
          </div>
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError: logError,
  }
);

export default OktaSmsSent;
