import { initReactI18next } from "react-i18next";
import i18next from "i18next";

// Languages
import en from "./en/en.json";
import fr from "./fr/fr.json";
import de from "./de/de.json";

const resources =  {
    en: { global: en },
    fr: { global: fr },
    de: { global: de }
};
export const defaultLanguage = "en";

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: defaultLanguage,
        fallbackLng: defaultLanguage,
        interpolation: {
            escapeValue: false
        }
    });

export const lang = [
    { value: "en", name: "English" },
    { value: "fr", name: "French" },
    { value: "de", name: "German" },
];

export default i18next;