import { Button } from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { config } from "../../../config/config";
import { useMigrated, useUser } from "../../../context/user-context";
import CardWithLogo from "../../common/cardwidthlogo/CardWithLogo";
import Notification from "../../common/notification/Notification";
import CryptoUtil from "./CryptoUtil";
import { ErrorFallback, logError } from "../../errorHandling";

const WebauthnVerify = withErrorBoundary(
  () => {
    const { user } = useUser();
    const { authState } = useOktaAuth();
    let history = useHistory();
    let configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    const [t] = useTranslation("global");
    const [feedback, setFeedback] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { migratedUser } = useMigrated();

    useEffect(() => {
      logOpenWebauthnVerifyPage();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logOpenWebauthnVerifyPage = () => {
      axios.post(
        config.api.baseUrl +
          "/logs/" +
          authState.idToken.claims.sub +
          "/log/info",
        {
          msg: "Opening Webauthn Verify page",
          component: "WebauthnVerify",
          client: "CIAM APP",
          loginName: user?.login,
          emailAddress: user?.email,
          epolinenumber: user?.epolineNumber,
        },
        configHeaders
      );
    };

    const redirect = (
      path: string,
      queryParams?: [
        { name: string; value: string },
        { name: string; value: string }
      ]
    ) => {
      path = "/" + path;
      if (queryParams) {
        path = path.concat(
          `?${queryParams[0].name}=${queryParams[0].value}&${queryParams[1].name}=${queryParams[1].value}`
        );
      }
      history.push(path);
    };

    useEffect(() => {
      axios
        .post(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors",
          {
            factorType: "webauthn",
            provider: "FIDO",
          },
          configHeaders
        )
        .then((response) => {
          let fidoFactorId = response.data.id;
          let activation: PublicKeyCredentialCreationOptions =
            response.data._embedded.activation;

          // Convert activation object's challenge and user id from string to binary
          activation.challenge = CryptoUtil.strToBin(
            response.data._embedded.activation.challenge
          );
          activation.user.id = CryptoUtil.strToBin(
            response.data._embedded.activation.user.id
          );
          activation.excludeCredentials.forEach((credential) => {
            credential.id = CryptoUtil.strToBin(credential.id);
          });

          //PETER

          const myHost = window.location.hostname;
          if (myHost.includes("localhost")) {
            activation.rp.id = "localhost";
            activation.rp.name = "PETER LOCALHOST";
          } else {
            ////activation.rp.id = "epo.org";
            activation.rp.id = myHost;
            activation.rp.name = "PETER EPO";
          }
          //activation.authenticatorSelection.userVerification = "required";

          if (user.login === "webauthn.test@test.dum") {
            activation.rp.id = "epo.org";
            activation.rp.name = "SUBDOMAIN";
          }

          // navigator.credentials is a global object on WebAuthn-supported clients, used to access WebAuthn API
          navigator.credentials
            .create({
              publicKey: activation,
            })
            .then(function (newCredential: any) {
              // Get attestation and clientData from callback result, convert from binary to string
              var attestation = CryptoUtil.binToStr(
                newCredential.response.attestationObject
              );
              var clientData = CryptoUtil.binToStr(
                newCredential.response.clientDataJSON
              );

              axios
                .post(
                  config.api.baseUrl +
                    "/users/" +
                    authState.idToken.claims.sub +
                    "/factors/" +
                    fidoFactorId +
                    "/activate_security_key_factor",
                  {
                    attestation: attestation,
                    clientData: clientData,
                  },
                  configHeaders
                )
                .then((res) => {
                  redirect("MFA/enrolled-factors", [
                    {
                      name: "mfa_name",
                      value: "webauthn",
                    },
                    {
                      name: "key_name",
                      value:
                        res.data.profile.authenticatorName ||
                        t("COMMON.authenticator"),
                    },
                  ]);
                })
                .catch(() => {
                  setFeedback(true);
                  setErrorMessage(t("COMMON.generic-error"));
                });
            })
            .catch((error) => {
              setFeedback(true);
              setErrorMessage(t("COMMON.generic-error"));
            });
        })
        .catch(() => {
          setFeedback(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.idToken.claims.sub]);

    return (
      <>
        {feedback && <Notification text={errorMessage} theme="negative" />}
        <CardWithLogo centered noLogo withNavbar>
          <h1>{t("VIEWS.MFA.follow-the-instructions")}</h1>

          <p>{t("COMMON.fido-key-text")}</p>

          <div className="row mt-xl">
            <div className="col text-right">
              <Button
                type="submit"
                className="ml-m"
                onClick={() =>
                  migratedUser?.migrated
                    ? redirect("migration")
                    : redirect("account")
                }
                data-testid="back-to-settings"
                theme="secondary"
              >
                {migratedUser?.migrated
                  ? t("VIEWS.MFA.go-back-to-migration")
                  : t("VIEWS.MFA.go-back-to-account-settings")}
              </Button>
            </div>
          </div>
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError: logError,
  }
);

export default WebauthnVerify;
