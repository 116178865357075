import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  passwordCondition: {
    //make password condition one line
    marginRight: "-65px",
    fontSize: "14px",
    lineHeight: "18px",
    "& > span": {
      fontFamily: "var(--epods--font-secondary)",
    },
  },
  icon: {
    width: "40px",
  },
  errorIconContainer: {
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  spinnerWrapper: {
    // -80px revert padding left
    marginLeft: "-150px",
    textAlign: "center",
    // 700px (width) - 24px (container padding)
    width: "676px",
  },
  spinner: {
    marginBottom: "var(--epods--space-inset-m)",
    color: "var(--epods--color-primary)",
    marginTop: "128px",
  },
  spinnerText: {
    lineHeight: "30px",
  },
});
