import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  feedbackMessage: {
    position: "absolute",
    // Dropdown z-index is 1000
    zIndex: 1001,
    right: "20px",
  },
});
