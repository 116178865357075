import {
  Button,
  Card,
  FormControl,
  PhoneInput,
  Radio,
  RadioGroup,
} from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { config } from "../../../config/config";
import Notification from "../../common/notification/Notification";
import { useStyles } from "./styles";
import { SpinnerCircular } from "../../common/spinner/Spinner";
import { ErrorFallback, logError } from "../../errorHandling";

const useIsMountedRef = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    const currentTime = new Date().getTime();
    localStorage.setItem("loginTime", currentTime.toString());

    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

const PhoneEnterNumber = withErrorBoundary(
  () => {
    const { authState } = useOktaAuth();
    let history = useHistory();
    let configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    const [t] = useTranslation("global");
    const classes = useStyles();
    const [feedback, setFeedback] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberIsCorrect, setPhoneNumberIsCorrect] = useState(false);
    const [phoneNumberHasError, setPhoneNumberHasError] = useState(false);
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");
    const [smsEnabled, setSmsEnabled] = useState(false);
    const [callEnabled, setCallEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const isMountedRef = useIsMountedRef();

    const [value, setValue] = useState("sms");
    const radioHandler = (e: any) => {
      setValue(e.target.value);
    };

    useEffect(() => {
      setIsLoading(true);
      getFactorsCatalog();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [phoneValue, setPhoneValue] = useState<any>("");
    const [country, setCountry] = useState<any>({
      dialCode: "+31",
      id: "NL",
      label: "Netherlands (Nederland)",
    });

    const redirect = (path: string, state: {}) => {
      history.push({ pathname: "/" + path, state: state });
    };

    const validatePhoneNumberField = () => {
      setPhoneNumberIsCorrect(false);
      setPhoneNumberHasError(false);
      setPhoneNumberErrorMessage("");
      if (!/^\+(?:[0-9] ?){6,14}[0-9]$/.test(phoneNumber)) {
        setPhoneNumberHasError(true);
        setPhoneNumberErrorMessage(t("COMMON.VALIDATION.wrong-phone-format"));
      } else {
        setPhoneNumberIsCorrect(true);
      }
    };

    async function getFactorsCatalog() {
      await axios
        .get(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors/catalog",
          configHeaders
        )
        .then((response) => {
          if (isMountedRef.current) {
            if (
              response.data?.find((myFactor) => myFactor.factorType === "call")
            ) {
              setCallEnabled(true);
              setValue("call");
            }

            if (
              response.data?.find((myFactor) => myFactor.factorType === "sms")
            ) {
              setSmsEnabled(true);
              setValue("sms");
            }
            //setFactorsCatalog(response.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setFeedback(true);
          //to be fixed setAnErrorHappened(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
    }

    const handleSubmit = () => {
      validatePhoneNumberField();
      if (!authState?.isAuthenticated) {
        return null;
      } else if (phoneNumberIsCorrect) {
        setPhoneNumberErrorMessage("");
        setPhoneNumberHasError(false);
        setPhoneNumberIsCorrect(false);
        axios
          .post(
            config.api.baseUrl +
              "/users/" +
              authState.idToken.claims.sub +
              "/factors",
            {
              factorType: value,
              provider: "OKTA",
              profile: {
                phoneNumber: phoneNumber,
              },
            },
            configHeaders
          )
          .then((response) => {
            if (response.data.factorType === "sms")
              redirect("MFA/sms-enter-code/", { phone: phoneNumber });
            else redirect("MFA/phone-enter-code/", { phone: phoneNumber });
          })
          .catch((error) => {
            if (error.response?.data?.errorCode) {
              setPhoneNumberHasError(true);
              setPhoneNumberErrorMessage(t("VIEWS.MFA.invalid-phone"));
            } else {
              setFeedback(true);
              setErrorMessage(t("COMMON.generic-error"));
            }
          });
      }
    };

    const handleTextChange = (newText) => {
      if (/^[0-9]+$/.test(newText.target.value)) {
        setPhoneValue(newText.target.value);
        setPhoneNumber(country.dialCode + newText.target.value);
      }
    };

    const handleCountryChange = (newCountry) => {
      setCountry(newCountry.option);
      setPhoneValue("");
    };

    return (
      <>
        {feedback && <Notification text={errorMessage} theme="negative" />}
        <div className={classes.wrapper + " container"}>
          <Card hideHeader className={classes.cardWrapper}>
            <div className={classes.marginButtons}>
              <h1 className={classes.title}>
                {t("VIEWS.MFA.receive-sms-code")}
              </h1>

              {isLoading ? (
                <div className="text-center">
                  <SpinnerCircular
                    size={45}
                    thickness={180}
                    speed={100}
                    color="#066AE3"
                    secondaryColor="#C4C4C4"
                  />
                </div>
              ) : (
                <FormControl
                  caption={phoneNumberErrorMessage}
                  error={phoneNumberHasError}
                  positive={phoneNumberIsCorrect}
                  label={
                    <div>
                      <span className={classes.sizeText18}>
                        {value === "sms" ? (
                          <p>{t("VIEWS.MFA.phone-number-sms-code")}</p>
                        ) : (
                          <p>{t("VIEWS.MFA.phone-number-call-code")}</p>
                        )}

                        {smsEnabled && callEnabled ? (
                          <div>
                            <RadioGroup
                              style={{ zIndex: 999 }}
                              name="option"
                              value={value}
                              onChange={radioHandler}
                            >
                              <Radio
                                className="w-100 mb-s"
                                value="sms"
                                key="sms"
                                data-testid="select-sms-radio"
                              >
                                {t("VIEWS.MFA.phone-number-sms-code-option")}
                              </Radio>
                              <Radio
                                className="w-100 mb-s"
                                value="call"
                                key="call"
                                data-testid="select-call-radio"
                              >
                                {t("VIEWS.MFA.phone-number-call-code-option")}
                              </Radio>
                            </RadioGroup>
                          </div>
                        ) : null}
                      </span>
                    </div>
                  }
                >
                  <div className={classes.marginMiddle}>
                    <PhoneInput
                      country={country}
                      onCountryChange={handleCountryChange}
                      onTextChange={handleTextChange}
                      placeholder="Placeholder"
                      size="compact"
                      text={phoneValue}
                      type="text"
                      onBlur={validatePhoneNumberField}
                      data-testid="phone-number"
                    />
                  </div>
                </FormControl>
              )}
            </div>

            <div className={classes.bottomButtons}>
              <Button
                className="mr-m"
                theme="secondary"
                onClick={() => redirect("account", {})}
                data-testid="back-to-settings-btn"
              >
                {t("VIEWS.MFA.go-back-to-account-settings")}
              </Button>
              <Button onClick={handleSubmit} data-testid="next">
                {t("VIEWS.MFA.next")}
              </Button>
            </div>
          </Card>
        </div>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = {componentStack: "EnterCode"}
      return logError(error, info);
    },
  }
);

export default PhoneEnterNumber;
