import React, { useEffect, useRef } from "react";
import axios from "axios";
import { WidgetInstance } from "friendly-challenge";
import { useTranslation } from "react-i18next";
import { useOktaAuth } from "@okta/okta-react";
import { withErrorBoundary } from "react-error-boundary";
import { config } from "../../../config/config";

type Props = {
  onDone: (solution) => void;
  onError: (err) => void;
  language: "en" | "de" | "fr";
};

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const [t] = useTranslation("global");

  return (
    <div role="alert">
      <p>{t("COMMON.something-went-wrong")}</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>{t("COMMON.try-again")}</button>
    </div>
  );
};

const ErrorFunction = (error: Error, info: { componentStack: string }) => {
  const { authState } = useOktaAuth();
  axios.post(
    config.api.baseUrl + "/logs/" + authState.idToken.claims.sub + "/log/error",
    {
      msg: error.message,
      level: { info, error },
      stacktrace: error.stack,
      timestamp: Date.now(),
      component: "Registration",
      client: "CIAM APP",
    }
  );
};

const FriendlyCaptcha = withErrorBoundary(
  ({ onDone, onError, language }: Props) => {
    const container = useRef(null);
    const widget = useRef(null);

    useEffect(() => {
      if (!widget.current && container.current) {
        widget.current = new WidgetInstance(container.current, {
          startMode: "focus",
          doneCallback: onDone,
          errorCallback: onError,
          language,
        });
      }

      return () => {
        if (widget.current !== undefined) widget.current.destroy();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container]);

    return (
      <div
        ref={container}
        className="frc-captcha"
        data-sitekey={config.friendlyCaptcha.captchaSiteKey}
        data-lang={language}
        //data-puzzle-endpoint="https://api.friendlycaptcha.com/api/v1/puzzle"
        data-puzzle-endpoint="https://eu-api.friendlycaptcha.eu/api/v1/puzzle"
      />
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError: ErrorFunction,
  }
);

export default React.memo(FriendlyCaptcha);
