import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  icon: {
    width: "40px",
  },
  errorIconContainer: {
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  spinnerWrapper: {
    // -80px revert padding left
    marginLeft: "-80px",
    textAlign: "center",
    // 700px (width) - 24px (container padding)
    width: "676px",
  },
  spinner: {
    marginBottom: "var(--epods--space-inset-m)",
    color: "var(--epods--color-primary)",
    marginTop: "128px",
  },
  spinnerText: {
    lineHeight: "30px",
  },
  width400: {
    width: "400px",
  }
});
