import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import { useTranslation } from "react-i18next";
import { config } from "../config/config";

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const [t] = useTranslation("global");

  return (
    <div role="alert">
      <p>{t("COMMON.something-went-wrong")}</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>{t("COMMON.try-again")}</button>
    </div>
  );
};

export const logError = (error: Error, info: { componentStack: string }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { authState } = useOktaAuth();
  let configHeaders = {
    headers: {
      Authorization: `Bearer ${authState.accessToken.accessToken}`,
    },
  };

  axios.post(
    config.api.baseUrl + "/logs/" + authState.idToken.claims.sub + "/log/error",
    {
      msg: error.message,
      level: { info: "", error: "" },
      stacktrace: error.stack,
      timestamp: Date.now(),
      component: info.componentStack,
      client: "CIAM APP",
    },
    configHeaders
  );
};
