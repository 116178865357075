import { Button, FormControl, Input } from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { config } from "../../../config/config";
import { PASSWORD_REGEX } from "../../../constants";
import { useMigrated, useUnlocked, useUser  } from "../../../context/user-context";
import CardWithLogo from "../../common/cardwidthlogo/CardWithLogo";
import { SpinnerCircular } from "../../common/spinner/Spinner";
import { useStyles } from "./styles";
import { ErrorFallback, logError } from "../../errorHandling";
import Notification from "../../common/notification/Notification";

const MigrationPass = withErrorBoundary(
  () => {
    const { authState } = useOktaAuth();
    let configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    const [t] = useTranslation("global");
    const classes = useStyles();
    const { migratedUser, setMigratedUser } = useMigrated();
    const { user } = useUser();
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordVerification, setPasswordVerification] = useState("");
    const [passwordHasError, setPasswordHasError] = useState(false);
    const [passwordIsCorrect, setPasswordIsCorrect] = useState(false);
    const { setUnlocked } = useUnlocked();
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const [passwordVerificationHasError, setPasswordVerificationHasError] =
      useState(false);
    const [passwordVerificationIsCorrect, setPasswordVerificationIsCorrect] =
      useState(false);
    const [
      passwordVerificationErrorMessage,
      setPasswordVerificationErrorMessage,
    ] = useState("");
    const [passwordVerificationTouched, setPasswordVerificationTouched] =
      useState(false);

      const [feedback, setFeedback] = useState(false);
      const [errorMessage, setErrorMessage] = useState("");

    const passwordCharacterCondition = PASSWORD_REGEX.test(password);

    const setError = (errMessage: string) => {
      setFeedback(true);
      setErrorMessage(t(errMessage));
    };

    const commonMigration = () => {
      axios
        .post(
          `${config.api.baseUrl}/migrate/${authState.idToken.claims.sub}/smart_card_user`,
          {
            profile: {
              email: migratedUser?.email,
              login: migratedUser?.email,
              epolineNumber: user?.epolineNumber,
            },
            credentials: {
              password: { value:password},
              passwordVerify: { value: passwordVerification },
            },
           terms: migratedUser?.terms,
          },
          configHeaders
        )
        .then(() => {
          setIsLoading(false);
          setUnlocked(false)
          setMigratedUser({
            ...migratedUser,
            password: password,
            isClash: false,
            step: "methods",
          });
        })
        .catch((error) => {
          setFeedback(false);
          setIsLoading(false);

          //error text is "This password was found in a list of commonly used passwords. Try another password"

          const wellKnowError = error.response.data?.errorCauses[0]?.errorSummary.includes("password") && error.response.data?.errorCauses[0]?.errorSummary.includes("list")

          if (error.response.status === 400 && wellKnowError) {
            setFeedback(true);
            setError("VIEWS.MIGRATION.password-well-known");
          } else {
            setFeedback(true);
            setError("VIEWS.MIGRATION.generic-error");
          }
        });
    };

    const validatePasswordField = () => {
      setPasswordIsCorrect(false);
      setPasswordHasError(false);
      setPasswordErrorMessage("");
      if (password.length < 10 || !passwordCharacterCondition) {
        setPasswordErrorMessage(t("VIEWS.MIGRATION.password-enter-valid"));
        setPasswordHasError(true);
      } else {
        setPasswordIsCorrect(true);
      }
      validatePasswordVerificationField(false);
    };

    const validatePasswordVerificationField = (isBlur: boolean) => {
      setPasswordVerificationIsCorrect(false);
      setPasswordVerificationHasError(false);
      setPasswordVerificationErrorMessage("");
      if (passwordVerification) {
        if (password !== passwordVerification) {
          setPasswordVerificationHasError(true);
          setPasswordVerificationErrorMessage(
            t("VIEWS.MIGRATION.password-match-condition")
          );
        } else {
          setPasswordVerificationIsCorrect(true);
        }
        /**
         * Check empty verification password
         * only triggers if field has been touched
         * to prevent error to show when checking
         * on password field check
         */
      } else if (isBlur || passwordVerificationTouched) {
        setPasswordVerificationTouched(true);
        setPasswordVerificationHasError(true);
        setPasswordVerificationErrorMessage(
          t("VIEWS.MIGRATION.password-validation-mandatory-field")
        );
      }
    };

    const handleSubmit = async (event: any) => {
      if (!authState?.isAuthenticated) {
        return null;
      } else if (checkFormValidity()) {
        setIsLoading(true);
        //submitData();
        commonMigration();
      }
      event.preventDefault();
    };

    const checkFormValidity = (): boolean => {
      validatePasswordField();
      validatePasswordVerificationField(true);
      return passwordIsCorrect && passwordVerificationIsCorrect;
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault(); 
      }
    };

    useEffect(() => {
      const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      };
  
      document.addEventListener('keydown', handleKeyPress);
  
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }, []);

    return (
      <>
        {feedback && <Notification text={errorMessage} theme="negative" />}
        <CardWithLogo centered noLogo withNavbar widthPx="567px">
          <>
            <h1>{t("VIEWS.MIGRATION.title-choose-password")}</h1>

            <p>{t("VIEWS.MIGRATION.pass-migration-info")}</p>

            {isLoading ? (
              <div className={classes.spinnerWrapper}>
                <SpinnerCircular
                  className={classes.spinner}
                  size={45}
                  thickness={180}
                  speed={100}
                  color="#066AE3"
                  secondaryColor="#C4C4C4"
                  data-testid="spinner"
                />
              </div>
            ) : (
              <form onSubmit={(e) => handleSubmit(e)}>
                <FormControl
                  className="mt-l"
                  caption={passwordErrorMessage}
                  error={passwordHasError}
                  positive={passwordIsCorrect}
                  label={
                    <>
                      <span className="semi-bold">
                        {t("VIEWS.MIGRATION.input-label-password")}
                      </span>
                    </>
                  }
                >
                  <Input
                    data-testid="password"
                    name="password"
                    type="password"
                    clearable
                    value={password}
                    error={passwordHasError}
                    positive={passwordIsCorrect}
                    aria-label={t("COMMON.password-ariaLabel")}
                    onBlur={() => validatePasswordField()}
                    onChange={(e: any) => {
                      setPassword(e.target.value);
                      setFeedback(false);
                    }}
                    onKeyPress={handleKeyPress}
                  />
                </FormControl>
                <div
                  className={classes.passwordCondition}
                  style={{
                    marginTop: passwordHasError ? "-16px" : "-12px",
                    color: passwordHasError ? "#BF360C" : "",
                  }}
                >
                  <span>{t("VIEWS.MIGRATION.password-length-condition")}</span>
                  <br /> 
                  <span>
                    {t("VIEWS.MIGRATION.password-character-condition")}
                  </span>
                </div>

                <FormControl
                  className="mt-l"
                  caption={passwordVerificationErrorMessage}
                  error={passwordVerificationHasError}
                  positive={passwordVerificationIsCorrect}
                  label={
                    <>
                      <span className="semi-bold">
                        {t("VIEWS.MIGRATION.input-label-repeat-password")}
                      </span>
                    </>
                  }
                >
                  <Input
                    data-testid="passwordVerification"
                    name="passwordVerification"
                    type="password"
                    clearable
                    value={passwordVerification}
                    error={passwordVerificationHasError}
                    positive={passwordVerificationIsCorrect}
                    aria-label={t("COMMON.password-verification-ariaLabel")}
                    onBlur={() => validatePasswordVerificationField(true)}
                    onChange={(e: any) => {
                      setPasswordVerification(e.target.value);
                      setFeedback(false);
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    onKeyPress={handleKeyPress}
                  />
                </FormControl>
                <div className="row mt-xl">
                  <div className="col text-right">
                    <Button
                      theme="secondary"
                      data-testid="back"
                      onClick={() => {
                        setMigratedUser({ ...migratedUser, step: "email" });
                      }}
                    >
                      {t("VIEWS.MIGRATION.back")}
                    </Button>
                    <Button type="submit" className="ml-m" data-testid="next">
                      {t("VIEWS.MIGRATION.next")}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </>
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {

console.log("Fallbackcomponent",error)

      const info = {componentStack: "EnterCode"}
      return logError(error, info);
    },
  }
);

export default MigrationPass;
