import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  dropdown: {
    width: "150px",
  },
  feedbackMessage: {
    position: "absolute",
    zIndex: 1,
    right: "20px",
  },
});
