/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Input,
  Dropdown,
  FormControl,
  Notification,
} from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { config } from "../../../config/config";
import {
  NAME_EXCLUSION_REGEX,
  NAME_MAX_LENGTH,
  NOTIFICATION_CLOSE_TIME,
} from "../../../constants";
import { useUser } from "../../../context/user-context";
import { useStyles } from "./styles";
import CardWithLogo from "../../common/cardwidthlogo/CardWithLogo";
import { Value } from "@epo/epods-react-components/lib/Components/Dropdown/types";
import { ErrorFallback, logError } from "../../errorHandling";

const EditPersonalInformation = withErrorBoundary(
  () => {
    const { authState } = useOktaAuth();
    let history = useHistory();
    let configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    const [t, i18n] = useTranslation("global");
    const classes = useStyles();
    const { user } = useUser();

    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [errorMessage, setErrorMessage] = useState("");
    const [feedback, setFeedback] = useState(false);
    const [dropdownValue, setDropdownValue] = useState<Value>([
      { id: user.locale },
    ]);

    const redirect = (path: string) => {
      history.push("/" + path);
    };

    useEffect(() => {
      if (!user.firstName || !user.lastName) {
        redirect("account");
      } else {
        logOpenEditPersonalInformationPage();
      }
    }, []);

    const logOpenEditPersonalInformationPage = () => {
      axios.post(
        config.api.baseUrl +
          "/logs/" +
          authState.idToken.claims.sub +
          "/log/info",
        {
          msg: "Opening Edit Personal Information page",
          component: "EditPersonalInformation",
          client: "CIAM APP",
          loginName: user?.login,
          emailAddress: user?.email,
          epolinenumber: user?.epolineNumber,
        },
        configHeaders
      );
    };

    const dropDownChange = (e: any) => {
      setDropdownValue(e.value);
      i18n.changeLanguage(e.value[0].id.substring(0, 2));
    };

    const handleLastNameInput = (e) => {
      setFeedback(false);
      setLastName(e.target.value);
    };

    const handleFirstNameInput = (e) => {
      setFeedback(false);
      setFirstName(e.target.value);
    };

    // input validations
    const isFirstNameChanged = useMemo(
      () => user.firstName !== firstName,
      [firstName]
    );

    const isLastNameChanged = useMemo(
      () => user.lastName !== lastName,
      [lastName]
    );

    const isLanguageChanged = useMemo(
      () => user.locale !== dropdownValue[0].id,
      [dropdownValue[0].id]
    );

    const isFirstNameInvalid = useMemo(
      () =>
        isFirstNameChanged &&
        (!firstName ||
          !firstName.trim() ||
          NAME_EXCLUSION_REGEX.test(firstName)),
      [firstName]
    );

    const isLastNameInvalid = useMemo(
      () =>
        isLastNameChanged &&
        (!lastName || !lastName.trim() || NAME_EXCLUSION_REGEX.test(lastName)),
      [lastName]
    );

    const handleSubmit = (event) => {
      setFeedback(false);

      if (!authState?.isAuthenticated) {
        return null;
      } else if (
        (isFirstNameChanged || isLastNameChanged || isLanguageChanged) &&
        !isFirstNameInvalid &&
        !isLastNameInvalid
      ) {
        axios
          .post(
            config.api.baseUrl + "/users/" + authState.idToken.claims.sub,
            {
              profile: {
                firstName: firstName ? firstName : user.firstName,
                lastName: lastName ? lastName : user.lastName,
                locale: dropdownValue[0].id,
              },
            },
            configHeaders
          )
          .then(() => {
            redirect("account");
          })
          .catch((error) => {
            setFeedback(true);
            setErrorMessage(t("COMMON.generic-error"));
          });
      }

      event.preventDefault();
    };

    return (
      <>
        {feedback ? (
          <div className={classes.feedbackMessage}>
            <Notification
              text={errorMessage}
              theme="negative"
              autoHideDuration={NOTIFICATION_CLOSE_TIME}
            />
          </div>
        ) : null}

        <CardWithLogo centered noLogo withNavbar>
          <h1 className="text-center">
            {t("VIEWS.EDIT-PERSONAL-INFORMATION.edit-personal-information")}
          </h1>

          <div className="container mt-xl">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col">
                  <FormControl
                    caption={
                      isFirstNameChanged &&
                      (!firstName || !firstName.trim()
                        ? t(
                            "VIEWS.EDIT-PERSONAL-INFORMATION.first-name-mandatory-field"
                          )
                        : NAME_EXCLUSION_REGEX.test(firstName)
                        ? t(
                            "VIEWS.EDIT-PERSONAL-INFORMATION.first-name-format-condition"
                          )
                        : "")
                    }
                    error={isFirstNameInvalid}
                    positive={
                      isFirstNameChanged &&
                      firstName &&
                      firstName.trim() &&
                      !NAME_EXCLUSION_REGEX.test(firstName)
                    }
                    label={
                      <span className="semi-bold">
                        {t("COMMON.first-name")}
                      </span>
                    }
                  >
                    <Input
                      className="mt-xs"
                      type="text"
                      id="firstName"
                      name="firstName"
                      maxLength={NAME_MAX_LENGTH}
                      aria-label={t("COMMON.firstName-ariaLabel")}
                      value={firstName}
                      placeholder={user.firstName}
                      onChange={handleFirstNameInput}
                      data-testid="firstName"
                    />
                  </FormControl>
                </div>
              </div>

              <div className="row mt-m pt-s">
                <div className="col">
                  <FormControl
                    caption={
                      isLastNameChanged &&
                      (!lastName || !lastName.trim()
                        ? t(
                            "VIEWS.EDIT-PERSONAL-INFORMATION.last-name-mandatory-field"
                          )
                        : NAME_EXCLUSION_REGEX.test(lastName)
                        ? t(
                            "VIEWS.EDIT-PERSONAL-INFORMATION.last-name-format-condition"
                          )
                        : "")
                    }
                    error={isLastNameInvalid}
                    positive={
                      isLastNameChanged &&
                      lastName &&
                      lastName.trim() &&
                      !NAME_EXCLUSION_REGEX.test(lastName)
                    }
                    label={
                      <span className="semi-bold">{t("COMMON.last-name")}</span>
                    }
                  >
                    <Input
                      className="mt-xs"
                      type="text"
                      id="lastName"
                      name="lastName"
                      maxLength={NAME_MAX_LENGTH}
                      placeholder={user.lastName}
                      value={lastName}
                      aria-label={t("COMMON.lastName-ariaLabel")}
                      onChange={handleLastNameInput}
                      data-testid="lastName"
                    />
                  </FormControl>
                </div>
              </div>

              <div className="row mt-m pt-s">
                <div className="col">
                  <FormControl
                    label={
                      <span className="semi-bold">
                        {t(
                          "VIEWS.EDIT-PERSONAL-INFORMATION.select-your-preferred-language"
                        )}
                      </span>
                    }
                  >
                    <Dropdown
                      className={classes.dropdown + " mt-xs"}
                      onChange={dropDownChange}
                      options={[
                        {
                          id: "de_DE",
                          label: "Deutsch",
                        },
                        {
                          id: "en_US",
                          label: "English",
                        },
                        {
                          id: "fr_FR",
                          label: "Français",
                        },
                      ]}
                      preset="select"
                      value={dropdownValue}
                      data-testid="change-language"
                    />
                  </FormControl>
                </div>
              </div>

              <div className="row mt-xl">
                <div className="col text-right">
                  <Button
                    theme="secondary"
                    data-testid="cancel-btn"
                    onClick={() => redirect("account")}
                  >
                    {t("COMMON.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    className="ml-m"
                    onClick={handleSubmit}
                    data-testid="save-btn"
                  >
                    {t("COMMON.save-changes")}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = {componentStack: "EnterCode"}
      return logError(error, info);
    },
  }
);

export default EditPersonalInformation;
