import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import {
  Theme,
  BaseProvider,
} from "@epo/epods-react-components/lib/Themes";
import "@epo/epods-react-components/lib/CSS/base-host.min.css";
import "@epo/epods-react-components/lib/CSS/grid.min.css";
import "../src/assets/css/utilities.min.css";
import "@epo/epods-react-components/lib/CSS/components.min.css";
import "@epo/epods-react-components/lib/CSS/reboot.min.css";
import "@epo/epods-react-components/lib/CSS/fontface-corporate.min.css";
import "@epo/epods-react-components/lib/CSS/fontface-epoicons-full.min.css";
import "@epo/epods-react-components/lib/CSS/iconography-full.min.css";
import "./assets/css/base/body.css";

import i18next from "./i18n";
import App from "./views/App";

const engine = new Styletron({
  prefix: "CIAM",
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={Theme}>
          <App />
        </BaseProvider>
      </StyletronProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
