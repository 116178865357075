import { Button, Link } from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { config } from "../../../../config/config";
import CardWithLogo from "../../../common/cardwidthlogo/CardWithLogo";
import Notification from "../../../common/notification/Notification";
import { ErrorFallback, logError } from '../../../errorHandling';

const GoogleScanQR = withErrorBoundary(
  () => {
    const [qrCodeImage, setQrCodeImage] = useState();
    const { authState } = useOktaAuth();
    const [t] = useTranslation("global");
    const history = useHistory();
    const [sharedSecret, setSharedSecret] = useState("");
    const [feedback, setFeedback] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const redirect = (path: string, state: {}) => {
      history.push({ pathname: "/" + path, state: { sharedSecret: state } });
    };

    useEffect(() => {
      setLoading(true);
      const configHeaders = {
        headers: {
          Authorization: `Bearer ${authState.accessToken.accessToken}`,
        },
        withCredentials: true,
      };
      axios
        .post(
          config.api.baseUrl +
          "/users/" +
          authState.idToken.claims.sub +
          "/factors",
          {
            factorType: "token:software:totp",
            provider: "GOOGLE",
          },
          configHeaders
        )
        .then((response) => {
          setQrCodeImage(response.data._embedded.activation._links.qrcode.href);
          setSharedSecret(response.data._embedded.activation.sharedSecret);
          setLoading(false);
        })
        .catch((error) => {
          setFeedback(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.idToken.claims.sub, authState.accessToken.accessToken]);

    return (
      <>
        {feedback ? (
          <Notification text={errorMessage} theme="negative" />
        ) : null}
        <CardWithLogo centered noLogo withNavbar>
          <h1>{t("VIEWS.MFA.scan-barcode")}</h1>

          <p>{t("VIEWS.MFA.before-clicking-next")}</p>
          <p>{t("VIEWS.MFA.step1-launch-google-authenticator")}</p>
          <p>{t("VIEWS.MFA.step2-tab-plus-icon", { plusIcon: "+" })}</p>
          <p>
            {t("VIEWS.MFA.step3-scan-qr-code", {
              scanQrCode: t("VIEWS.MFA.scan-a-qr-code"),
            })}
          </p>

          {!loading ? (
            <img
              className="position-relative"
              src={qrCodeImage}
              alt={t("COMMON.ALT-TEXT.google-qr-code")}
              style={{ left: "-20px" }}
            />
          ) : null}

          <p>
            {t("VIEWS.MFA.cant-scan")}{" "}
            <Link
              data-testid="enter-setup-key-link"
              onClick={() => redirect("MFA/google-cant-scan-qr", sharedSecret)}
            >
              {t("VIEWS.MFA.enter-setup-key")}
            </Link>
          </p>

          <div className="row mt-xl">
            <div className="col text-right">
              <Button
                theme="secondary"
                onClick={() => redirect("MFA/google-authentication", {})}
                data-testid="back-btn"
              >
                {t("VIEWS.MFA.back")}
              </Button>
              <Button
                type="submit"
                className="ml-m"
                onClick={() => redirect("MFA/google-enter-code", {})}
                data-testid="next-btn"
              >
                {t("VIEWS.MFA.next")}
              </Button>
            </div>
          </div>
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError: logError,
  }
);

export default GoogleScanQR;
