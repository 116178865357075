import axios from "axios";

export const setupInterceptors = (history: any, authClient: any) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response.status === 401) {
        await logout(history, authClient);
      }
      // Factor user locked exception [https://developer.okta.com/docs/reference/error-codes/#E0000069]
      if (error?.response?.data?.errorCode === "E0000069") {
        history.replace("/logout?type_hint=ATTEMPTS");
      }

      return Promise.reject(error);
    }
  );
};

const logout = async (history: any, authClient) => {
  await authClient.signOut().then(() => {
    history.replace("/account");
  });
};
