import {
  Button,
  FormControl,
  Input,
  Link,
} from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { config } from "../../../config/config";
import { useMigrated, useUnlocked } from "../../../context/user-context";
import CardWithLogo from "../../common/cardwidthlogo/CardWithLogo";
import Notification from "../../common/notification/Notification";
import { useStyles } from "./styles";
import { ErrorFallback, logError } from "../../errorHandling";

const PushOktaAuth = withErrorBoundary(
  () => {
    const { authState } = useOktaAuth();
    let history = useHistory();
    const classes = useStyles();
    let configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
        "X-Forwarded-For": "",
      },
      withCredentials: true,
    };

    const [t] = useTranslation("global");
    const [feedback, setFeedback] = useState(false);
    const [enterCodeMode, setEnterCodeMode] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [code, setCode] = useState("");
    const [invalidCode, setInvalidCode] = useState(false);
    const { setUnlocked } = useUnlocked();
    const { migratedUser } = useMigrated();

    let transactionId = "";
    const { factorId } = (history.location && history.location.state) || {};
    const { oktaTokenFactorId } =
      (history.location && history.location.state) || {};

    useEffect(() => {
      sendPushNotification();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirect = (path: string) => {
      history.push("/" + path);
    };

    const polling = (tId: string) => {
      axios
        .get(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors/" +
            factorId +
            "/transactions/" +
            tId,
          configHeaders
        )
        .then(async (response) => {
          if (response.data.factorResult === "SUCCESS") {
            setUnlocked(true);
            if (migratedUser.migrated) {
              redirect("migration");
            } else {
              redirect("account");
            }
          } else if (response.data.factorResult === "TIMEOUT") {
            setFeedback(true);
            setErrorMessage(t("COMMON.time-out"));
          } else {
            setTimeout(function () {
              polling(tId);
            }, 3000);
          }
        })
        .catch((error) => {
          setFeedback(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
    };

    const sendPushNotification = async () => {
      axios
        .post(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors/" +
            factorId +
            "/verify",
          {},
          configHeaders
        )
        .then(async (response) => {
          if (
            response.status === 200 &&
            response.data.factorResult === "SUCCESS"
          ) {
            setUnlocked(true);
            if (migratedUser.migrated) {
              redirect("migration");
            } else {
              redirect("account");
            }
          } else if (
            response.status === 200 &&
            response.data.factorResult === "WAITING"
          ) {
            transactionId = response.data.transactionId;
            polling(transactionId);
          } else {
            setFeedback(true);
            setErrorMessage(t("COMMON.generic-error"));
          }
        })
        .catch((error) => {
          setFeedback(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
    };

    const verifyCode = () => {
      if (code) {
        axios
          .post(
            config.api.baseUrl +
              "/users/" +
              authState.idToken.claims.sub +
              "/factors/" +
              oktaTokenFactorId +
              "/verify",
            { passCode: code },
            configHeaders
          )
          .then(() => {
            setUnlocked(true);
            if (migratedUser.migrated) {
              redirect("migration");
            } else {
              redirect("account");
            }
          })
          .catch((error) => {
            if (
              error.response.status === 403 ||
              error.response.status === 400
            ) {
              setInvalidCode(true);
            } else {
              setFeedback(true);
              setErrorMessage(t("COMMON.generic-error"));
            }
          });
      } else {
        setInvalidCode(true);
      }
    };

    const enterCode = () => {
      setEnterCodeMode(true);
    };

    return (
      <>
        {feedback && <Notification text={errorMessage} theme="negative" />}
        <CardWithLogo noLogo centered withNavbar>
          <h1>{t("VIEWS.UNLOCK.verify-using-okta-authenticator")}</h1>
          {enterCodeMode ? (
            <div>
              <p>{t("VIEWS.UNLOCK.open-okta-application")}</p>
              <p>{t("VIEWS.UNLOCK.enter-code-provided")}</p>

              <FormControl
                label-props={{ style: { display: "none" } }}
                caption={invalidCode && t("VIEWS.UNLOCK.enter-valid-code")}
                error={invalidCode}
              >
                <Input
                  className={classes.width180 + " mt-s"}
                  value={code}
                  clearable
                  onChange={(e) => [
                    setFeedback(false),
                    setCode(e.target.value),
                    setInvalidCode(false),
                  ]}
                  onBlur={() => {
                    if (!code) {
                      setInvalidCode(true);
                    }
                  }}
                  data-testid="code-input"
                />
              </FormControl>
            </div>
          ) : (
            <div>
              <p>{t("VIEWS.UNLOCK.open-okta-verify-and-confirm")}</p>
              <p>
                {t("VIEWS.UNLOCK.havent-receive-okta-notification")}
                <br />
                <Link data-testid="scan-qr-link" onClick={sendPushNotification}>
                  {t("VIEWS.UNLOCK.resend-notification")}
                </Link>{" "}
                {t("VIEWS.UNLOCK.or-okta")}{" "}
                <Link data-testid="enter-code" onClick={enterCode}>
                  {t("VIEWS.UNLOCK.enter-code-okta")}
                </Link>
              </p>
            </div>
          )}

          <div className="row mt-xl">
            <div className="col text-right">
              <Button
                theme="secondary"
                data-testid="back-btn"
                onClick={() => redirect("unlock")}
              >
                {t("VIEWS.UNLOCK.back")}
              </Button>
              {enterCodeMode && (
                <Button type="submit" className="ml-m" onClick={verifyCode}>
                  {t("VIEWS.UNLOCK.verify")}
                </Button>
              )}
            </div>
          </div>
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = { componentStack: "EnterCode" };
      return logError(error, info);
    },
  }
);

export default PushOktaAuth;
