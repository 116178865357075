import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  wrapper: {
    marginTop: "var(--epods--space-inset-l)",
    marginBottom: "var(--epods--space-inset-s)",
    paddingTop: "var(--epods--space-inset-s)",
  },
  centered: {
    marginRight: "auto",
    marginLeft: "auto",
  },
  columnShadow: {
    backgroundColor: "#fff",
    maxWidth: "1050px",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    boxShadow: "0px 2px 4px rgba(94, 124, 143, 0.15)",
  },
  logo: {
    maxHeight: "90px",
    backgroundColor: "blue",
  },
  "@media (min-width: 1281px)": {
    wrapper: {
      marginTop: "140px",
      marginBottom: "140px",
    },
  },
  "@media (min-width: 1921px)": {
    wrapper: {
      marginTop: "250px",
      marginBottom: "250px",
    },
  },
  centeredWrapper: (withNavbar: boolean) => ({
    height: withNavbar ? "calc(100vh - 61px)" : "100vh",
  }),
});
