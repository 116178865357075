import { memo } from "react";
import { useTranslation } from "react-i18next";

import logo from "../../../assets/images/epo-logo-new.png";
import { useStyles } from "./styles";

type Props = {
  children?: React.ReactNode;
  rightMenu?: React.ReactNode;
  centered?: boolean;
  noLogo?: boolean;
  withNavbar?: boolean;
  widthPx?: string;
  style?:React.CSSProperties;
};

function CardWithLogo({
  style,
  children,
  rightMenu,
  centered,
  noLogo,
  withNavbar,
  widthPx = "",
}: Props) {
  const [t] = useTranslation("global");
  const classes = useStyles(withNavbar);

  return (
    <div
      className={(centered ? classes.centered : classes.wrapper) + " container"}
      style={{ width: widthPx }}
    >
      <div
        className={
          centered ? classes.centeredWrapper + " row align-items-center" : "row"
        }
      >
        <div className="col p-0">
          <div className={classes.columnShadow + " mx-auto"} style={style}>
            {(rightMenu || !noLogo) && (
              <div className="d-flex justify-content-between align-items-center">
                {!noLogo && (
                  <img
                    className={classes.logo}
                    src={logo}
                    alt={t("COMMON.epo-logo-alt-text")}
                  />
                )}
                {rightMenu}
              </div>
            )}
            <div className="mx-xl my-l px-m py-s">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(CardWithLogo);
