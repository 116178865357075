import { Button, Card } from "@epo/epods-react-components";
import { AddCircleOutline } from "@epo/epods-react-components/lib/Icons/AddCircleOutline";
import { CheckCircle } from "@epo/epods-react-components/lib/Icons/CheckCircle";
import { Error } from "@epo/epods-react-components/lib/Icons/Error";
import { ModeEdit } from "@epo/epods-react-components/lib/Icons/ModeEdit";
import { RemoveCircleOutline } from "@epo/epods-react-components/lib/Icons/RemoveCircleOutline";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../config/config";
import { useUnlocked, useUser } from "../../context/user-context";
import CardWithLogo from "../common/cardwidthlogo/CardWithLogo";
import Notification from "../common/notification/Notification";
import { SpinnerCircular } from "../common/spinner/Spinner";
import { useStyles } from "./styles";
import Warning from "../common/warning/dialogWarning";
import { ErrorFallback, logError } from "../errorHandling";

const useIsMountedRef = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    const currentTime = new Date().getTime();
    localStorage.setItem("loginTime", currentTime.toString());

    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

const SelfService = withErrorBoundary(
  () => {
    const [t, i18n] = useTranslation("global");
    const classes = useStyles();
    const { unlocked, setUnlocked } = useUnlocked();
    const { user, setUser } = useUser();
    const [webAuthFactors, setWebAuthFactors] = useState([]);
    const [factorsCatalog, setFactorsCatalog] = useState([]);
    const isMountedRef = useIsMountedRef();
    const [feedback, setFeedback] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [anErrorHappened, setAnErrorHappened] = useState(false);
    const [notificationTheme, setNotificationTheme] = useState<
      "negative" | "positive"
    >("negative");
    const [isOpen, setIsOpen] = useState(false);

    let history = useHistory();
    const location = useLocation();
    const { authState } = useOktaAuth();
    let configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };
    const [userCallPhoneNumber, setUserCallPhoneNumber] = useState("12345678");

    const checkChangeEmailTime = () => {
      const loginTime = localStorage.getItem("loginTime");
      const loginTimeMs = parseInt(loginTime, 10);
      const currentTime = new Date().getTime();
      const elapsedTimeMinutes = (currentTime - loginTimeMs) / (1000 * 60);
      if (elapsedTimeMinutes >= 15) {
        setIsOpen(true);
      } else {
        redirect("change-email");
      }
    };

    const formatPhoneNumber = (phoneNumber) => {
      const countryCode = phoneNumber.slice(0, 4);
      const restOfNumber = phoneNumber.slice(4);
      const formattedNumber = restOfNumber.replace(
        /\d{1,3}(?=(\d{3})+(?!\d))/g,
        "XXX "
      );
      return `${countryCode} ${formattedNumber}`;
    };
    
    useEffect(() => {
      if (location?.state?.fromPasswordChange) {
        setFeedback(true);
        setNotificationTheme("positive");
        setErrorMessage(t("VIEWS.SELF-SERVICE.password-change-success"));
      } else if (location?.state?.fromEmailChange) {
        setFeedback(true);
        setNotificationTheme("positive");
        setErrorMessage(t("VIEWS.SELF-SERVICE.email-change-success"));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      document.title = t("VIEWS.SELF-SERVICE.browser-tab-title");
      setIsLoading(true);
      getUsers();
      getFactorsCatalog();
      getFactorsInfo();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.idToken.claims.sub, isMountedRef]); 

    async function getFactorsCatalog() {
      await axios
        .get(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors/catalog",
          configHeaders
        )
        .then((response) => {
          if (isMountedRef.current) {
            if (unlocked) {
              setUnlocked(true);
              setFactorsCatalog(response.data);
            } else {
              if (
                response.data?.find((myFactor) => myFactor.status === "ACTIVE")
              ) {
                setUnlocked(false);
                setFactorsCatalog(response.data);
              } else {
                setUnlocked(true);
                setFactorsCatalog(response.data);
              }
            }
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setFeedback(true);
          setAnErrorHappened(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
    }

    async function getUsers() {
      await axios
        .get(
          config.api.baseUrl + "/users/" + authState.idToken.claims.sub,
          configHeaders
        )
        .then((response) => {
          if (!response.data?.locale) {
            if (response.data?.preferredLanguage === "DE") {
              response.data.locale = "de_DE";
            } else if (response.data?.preferredLanguage === "FR") {
              response.data.locale = "fr_FR";
            } else {
              response.data.locale = "en_US";
            }
          }
          response.data?.locale &&
          (response.data?.locale === "en_US" ||
            response.data?.locale === "fr_FR" ||
            response.data?.locale === "de_DE")
            ? i18n.changeLanguage(response.data?.locale.substring(0, 2))
            : i18n.changeLanguage("en_US");
          setUser(response.data);
          logOpenSelfServicePage(response.data);
        })
        .catch(() => {
          setGenericError();
        });
    }

    async function getFactorsInfo() {
      axios
        .get(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors",
          configHeaders
        )
        .then((response) => {
          let webAuthFactorList = [];
          response.data.forEach((factor) => {
            if (
              factor.factorType === "webauthn" &&
              factor.status === "ACTIVE"
            ) {
              webAuthFactorList.push(factor);
            }
            else if (
              (factor.factorType === "sms" ||
              factor.factorType === "call") &&
              factor.status === "ACTIVE"
            ) {              
              setUserCallPhoneNumber(formatPhoneNumber(factor.profile.phoneNumber));
            }
          });
          setWebAuthFactors(webAuthFactorList);
        })
        .catch(() => {
          setGenericError();
        });
    }

    const setGenericError = () => {
      setFeedback(true);
      setAnErrorHappened(true);
      setErrorMessage(t("COMMON.generic-error"));
    };

    const logOpenSelfServicePage = (data) => {
      axios.post(
        config.api.baseUrl +
          "/logs/" +
          authState.idToken.claims.sub +
          "/log/info",
        {
          msg: "Opening Account page",
          component: "Account",
          client: "CIAM APP",
          loginName: data?.login,
          emailAddress: data?.email,
          epolinenumber: data?.epolineNumber,
        },
        configHeaders
      );
    };

    const redirect = (path: string) => {
      path = path.replace("token:software:totp", "google");
      path = path.replace("push", "okta");
      history.push("/" + path);
    };

    const handleUnlock = (e) => {
      factorsCatalog.find((myFactor) => myFactor.status === "ACTIVE")
        ? redirect("unlock")
        : setUnlocked(false);
    };

    const addEmailMfa = async () => {
      await axios
        .post(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors",
          {
            factorType: "email",
            provider: "OKTA",
            profile: {
              email: user.email,
            },
          },
          configHeaders
        )
        .then(() => {
          history.push("/MFA/enrolled-factors?mfa_name=email");
        })
        .catch((error) => {
          setFeedback(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
    };

    const removeMfa = async (factor, id = "") => {
      let factorId = id;
      if (!id) {
        await axios
          .get(
            config.api.baseUrl +
              "/users/" +
              authState.idToken.claims.sub +
              "/factors",
            configHeaders
          )
          .then((response) => {
            factorId = response.data.find(
              (myFactor) =>
                myFactor.factorType === factor.factorType &&
                myFactor.provider === factor.provider
            ).id;
          })
          .catch((error) => {
            setFeedback(true);
            setErrorMessage(t("COMMON.generic-error"));
          });
      }
      axios
        .delete(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors/" +
            factorId,
          configHeaders
        )
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          setFeedback(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
    };

    const SetUpConstructor = (param) => {
      switch (param.factor) {
        case "phoneOKTA":
          return (
            unlocked && (
              <Button
                className={classes.pointer}
                theme="tertiary"
                onClick={() => redirect("MFA/phone-enter-number")}
                data-testid="sms-verify-button"
              >
                <AddCircleOutline className="mr-xs" size="small" />{" "}
                {t("VIEWS.SELF-SERVICE.add")}
              </Button>
            )
          );
        case "webauthnFIDO":
          return (
            unlocked && (
              <Button
                className={classes.pointer}
                theme="tertiary"
                onClick={() => redirect("MFA/webauthn-verify")}
                data-testid="webauth-button"
              >
                <AddCircleOutline className="mr-xs" size="small" />{" "}
                {t("VIEWS.SELF-SERVICE.add")}
              </Button>
            )
          );
        case "pushOKTA":
          return (
            unlocked && (
              <Button
                className={classes.pointer}
                theme="tertiary"
                onClick={() => redirect("MFA/okta-verify")}
                data-testid="okta-verify-button"
              >
                <AddCircleOutline className="mr-xs" size="small" />{" "}
                {t("VIEWS.SELF-SERVICE.add")}
              </Button>
            )
          );
        case "token:software:totpGOOGLE":
          return (
            unlocked && (
              <Button
                className={classes.pointer}
                theme="tertiary"
                onClick={() => redirect("MFA/google-authentication")}
                data-testid="google-auth-button"
              >
                <AddCircleOutline className="mr-xs" size="small" />{" "}
                {t("VIEWS.SELF-SERVICE.add")}
              </Button>
            )
          );
        case "emailMFA":
          return (
            unlocked && (
              <Button
                className={classes.pointer}
                theme="tertiary"
                onClick={addEmailMfa}
                data-testid="email-mfa-verify-button"
              >
                <AddCircleOutline className="mr-xs" size="small" />{" "}
                {t("VIEWS.SELF-SERVICE.add")}
              </Button>
            )
          );
        default:
          return null;
      }
    };

    const RemoveConstructor = (param) => {
      switch (param.factor) {
        case "phoneOKTA":
          return (
            unlocked && (
              <Button
                data-testid="sms-verify-button"
                className={classes.pointer}
                theme="tertiary"
                disabled={unlocked ? false : true}
                onClick={() => {
                  removeMfa(
                    factorsCatalog.find(
                      (myFactor) =>
                        (myFactor.factorType === "sms" || 
                        myFactor.factorType === "call") &&
                        myFactor.status === "ACTIVE"
                    )
                  );
                }}
              >
                <RemoveCircleOutline className="mr-xs" size="small" />{" "}
                {t("VIEWS.SELF-SERVICE.remove")}
              </Button>
            )
          );
        case "webauthnFIDO":
          return (
            unlocked && (
              <Button
                data-testid="webauth-button"
                theme="tertiary"
                className={classes.pointer}
                disabled={unlocked ? false : true}
                onClick={() => {
                  removeMfa(
                    webAuthFactors.find(
                      (myFactor) =>
                        myFactor.factorType === "webauthn" &&
                        myFactor.id === param.id &&
                        myFactor.status === "ACTIVE"
                    ),
                    param.id
                  );
                }}
              >
                <RemoveCircleOutline className="mr-xs" size="small" />{" "}
                {t("VIEWS.SELF-SERVICE.remove")}
              </Button>
            )
          );
        case "pushOKTA":
          return (
            unlocked && (
              <Button
                theme="tertiary"
                data-testid="okta-verify-button"
                className={classes.pointer}
                disabled={unlocked ? false : true}
                onClick={() => {
                  removeMfa(
                    factorsCatalog.find(
                      (myFactor) =>
                        myFactor.factorType === "push" &&
                        myFactor.status === "ACTIVE"
                    )
                  );
                }}
              >
                <RemoveCircleOutline className="mr-xs" size="small" />{" "}
                {t("VIEWS.SELF-SERVICE.remove")}
              </Button>
            )
          );
        case "token:software:totpGOOGLE":
          return (
            unlocked && (
              <Button
                theme="tertiary"
                data-testid="google-auth-button"
                className={classes.pointer}
                disabled={unlocked ? false : true}
                onClick={() => {
                  removeMfa(
                    factorsCatalog.find(
                      (myFactor) =>
                        myFactor.factorType === "token:software:totp" &&
                        myFactor.provider === "GOOGLE" &&
                        myFactor.status === "ACTIVE"
                    )
                  );
                }}
              >
                <RemoveCircleOutline className="mr-xs" size="small" />{" "}
                {t("VIEWS.SELF-SERVICE.remove")}
              </Button>
            )
          );
        case "emailMFA":
          return (
            unlocked && (
              <Button
                theme="tertiary"
                data-testid="email-MFA-button"
                className={classes.pointer}
                disabled={unlocked ? false : true}
                onClick={() => {
                  removeMfa(
                    factorsCatalog.find(
                      (myFactor) =>
                        myFactor.factorType === "email" &&
                        myFactor.provider === "OKTA" &&
                        myFactor.status === "ACTIVE"
                    )
                  );
                }}
              >
                <RemoveCircleOutline className="mr-xs" size="small" />{" "}
                {t("VIEWS.SELF-SERVICE.remove")}
              </Button>
            )
          );
        default:
          return null;
      }
    };

    const SecurityKeyOrBiometric = (param) => {
      return (
        <div id="test">
          {unlocked && (
            <Button
              theme="tertiary"
              disabled={!unlocked || webAuthFactors.length >= 3 ? true : false}
              onClick={() => redirect("MFA/webauthn-verify")}
              data-testid="register-sk-button"
            >
              <AddCircleOutline className="mr-xs" size="small" />{" "}
              {webAuthFactors.length > 0
                ? t("VIEWS.SELF-SERVICE.register-another")
                : t("VIEWS.SELF-SERVICE.add")}
            </Button>
          )}
        </div>
      );
    };

    const Mfa = () => {
      return (
        <div className="row">
          <div className="col">
            {factorsCatalog.find(
              (myFactor) => myFactor.factorType === "push"
            ) ? (
              <div className="mt-sm-m">
                <div className={classes.factorDiv}>
                  <div className={classes.factor}>
                    {factorsCatalog.find(
                      (myFactor) =>
                        myFactor.factorType === "push" &&
                        myFactor.status === "ACTIVE"
                    ) ? (
                      <CheckCircle
                        className={classes.checkCircle + " mr-s"}
                        size="medium"
                      />
                    ) : null}
                    {t("VIEWS.SELF-SERVICE.push-OKTA")}
                  </div>
                  {factorsCatalog.find(
                    (myFactor) =>
                      myFactor.factorType === "push" &&
                      myFactor.status === "ACTIVE"
                  ) ? (
                    <RemoveConstructor factor={"pushOKTA"} />
                  ) : (
                    <SetUpConstructor factor={"pushOKTA"} />
                  )}
                </div>
              </div>
            ) : null}
            {factorsCatalog.find(
              (myFactor) =>
                myFactor.factorType === "token:software:totp" &&
                myFactor.provider === "GOOGLE"
            ) ? (
              <div className="mt-sm-m">
                <div className={classes.factorDiv}>
                  <div className={classes.factor}>
                    {factorsCatalog.find(
                      (myFactor) =>
                        myFactor.factorType === "token:software:totp" &&
                        myFactor.provider === "GOOGLE" &&
                        myFactor.status === "ACTIVE"
                    ) ? (
                      <CheckCircle
                        className={classes.checkCircle + " mr-s"}
                        size="medium"
                      />
                    ) : null}
                    {t("VIEWS.SELF-SERVICE.google-authenticator")}
                  </div>
                  {factorsCatalog.find(
                    (myFactor) =>
                      myFactor.factorType === "token:software:totp" &&
                      myFactor.provider === "GOOGLE" &&
                      myFactor.status === "ACTIVE"
                  ) ? (
                    <RemoveConstructor factor={"token:software:totpGOOGLE"} />
                  ) : (
                    <SetUpConstructor factor={"token:software:totpGOOGLE"} />
                  )}
                </div>
              </div>
            ) : null}
            {factorsCatalog.find(
              (myFactor) => myFactor.factorType === "webauthn"
            ) ? (
              <div className="mt-sm-m">
                <div className={classes.factorDiv}>
                  <div className={classes.factor}>
                    {t("VIEWS.SELF-SERVICE.webauthn-FIDO")}
                  </div>

                  <SecurityKeyOrBiometric />
                </div>
                <div>
                  {webAuthFactors.map((factor) => {
                    return factor.factorType === "webauthn" &&
                      factor.status === "ACTIVE" ? (
                      <div key={factor.id} className="mt-s">
                        <div className="d-flex aling-items-center justify-content-between">
                          <div className="d-flex">
                            <CheckCircle
                              className={classes.checkCircle + " ml-l mr-s"}
                              size="medium"
                            />
                            <div className={classes.authName}>
                              {factor?.profile?.authenticatorName ||
                                t("VIEWS.SELF-SERVICE.authenticator")}
                            </div>
                          </div>

                          <RemoveConstructor
                            factor={"webauthnFIDO"}
                            id={factor.id}
                          />
                        </div>
                      </div>
                    ) : null;
                  })}
                </div>
              </div>
            ) : null}
            {factorsCatalog.find(
              (myFactor) => myFactor.factorType === "sms" || myFactor.factorType === "call"
            ) ? (
              <div className="mt-sm-m">
                <div className={classes.factorDiv}>
                  <div className={classes.factor}>
                    {factorsCatalog.find(
                      (myFactor) =>
                        (myFactor.factorType === "sms" &&
                        myFactor.status === "ACTIVE")
                        ||  (myFactor.factorType === "call" &&
                        myFactor.status === "ACTIVE")
                    ) ? (
                      <CheckCircle
                        className={classes.checkCircle + " mr-s"}
                        size="medium"
                      />
                    ) : null}

                    {(factorsCatalog.find((myFactor) => myFactor.factorType === "sms")
                      && (factorsCatalog.find((myFactor) =>myFactor.factorType === "call")))
                    ? (t("VIEWS.SELF-SERVICE.phone-OKTA"))
                    : (                            
                      factorsCatalog.find(
                        (myFactor) => myFactor.factorType === "sms") 
                        ? (t("VIEWS.SELF-SERVICE.sms-OKTA"))
                        : (t("VIEWS.SELF-SERVICE.call-OKTA"))
                    )}
                    {factorsCatalog.find(
                    (myFactor) =>
                    (myFactor.factorType === "sms" && myFactor.status === "ACTIVE")
                    ||  (myFactor.factorType === "call" && myFactor.status === "ACTIVE")) 
                    ? (<>
                        <br></br>
                        ({userCallPhoneNumber})
                      </>)
                    : (null)                
                  }
                  </div>
                  {factorsCatalog.find(
                    (myFactor) =>
                    (myFactor.factorType === "sms" && myFactor.status === "ACTIVE")
                    ||  (myFactor.factorType === "call" && myFactor.status === "ACTIVE")
                  ) ? (                    
                      <RemoveConstructor factor={"phoneOKTA"} />                    
                  ) : (
                    <SetUpConstructor factor={"phoneOKTA"} />
                  )}
                </div>
              </div>
            ) : null}
            {factorsCatalog.find(
              (myFactor) => myFactor.factorType === "email"
            ) ? (
              <div className="mt-sm-m">
                <div className={classes.factorDiv}>
                  <div className={classes.factor}>
                    {factorsCatalog.find(
                      (myFactor) =>
                        myFactor.factorType === "email" &&
                        myFactor.status === "ACTIVE"
                    ) ? (
                      <CheckCircle
                        className={classes.checkCircle + " mr-s"}
                        size="medium"
                      />
                    ) : null}
                    {t("VIEWS.SELF-SERVICE.email-MFA")}
                  </div>
                  {factorsCatalog.find(
                    (myFactor) =>
                      myFactor.factorType === "email" &&
                      myFactor.status === "ACTIVE" 
                  ) ? (
                    <RemoveConstructor factor={"emailMFA"} />
                  ) : (
                    <SetUpConstructor factor={"emailMFA"} />
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      );
    };

    return (
      <>
        {feedback && (
          <Notification
            text={errorMessage}
            theme={notificationTheme || "negative"}
          />
        )}
        {isLoading ? (
          <div className={classes.spinnerWrapper}>
            <SpinnerCircular
              className={classes.spinner}
              size={45}
              thickness={180}
              speed={100}
              color="#066AE3"
              secondaryColor="#C4C4C4"
            />
          </div>
        ) : anErrorHappened ? (
          <div className={classes.cardWrapper + " row col-md-6"}>
            <Card hideHeader>
              <div className="m-sm-m">
                <div
                  className={classes.errorIconContainer + " m-sm-m text-center"}
                >
                  <div className={classes.icon}>
                    <Error />
                  </div>
                </div>
                <div className="m-sm-m text-center">
                  <h2>{t("COMMON.generic-error")}</h2>
                </div>
                <Button
                  theme="tertiary"
                  onClick={() => window.location.reload()}
                >
                  {t("COMMON.try-again")}
                </Button>
              </div>
            </Card>
          </div>
        ) : /**
         * SmartCard:
         * Checking the "accessToAccount" claim to check if the user has access to the
         * account flow.
         *
         */
        !authState.idToken.claims["accessToAccount"] ? (
          <CardWithLogo centered noLogo withNavbar style={{ maxWidth: 700 }}>
            <div className="pb-l">
              <h1 className="text-center">
                {t("VIEWS.SELF-SERVICE.no-permission-account-title")}
              </h1>
            </div>
            <div className="pb-m">
              {t("VIEWS.SELF-SERVICE.no-permission-account-description")}
            </div>
          </CardWithLogo>
        ) : (
          <div className="mt-m mb-l">
            <CardWithLogo centered noLogo withNavbar style={{ maxWidth: 700 }}>
              <h1>{t("COMMON.settings")}</h1>

              {!unlocked && (
                <Button
                  iconBefore="lock"
                  onClick={handleUnlock}
                  data-testid="unlock-btn"
                >
                  {t("VIEWS.SELF-SERVICE.unlock")}
                </Button>
              )}

              <div className="d-flex align-items-center justify-content-between">
                <h4>
                  <span className={classes.semiBold}>
                    {t("VIEWS.SELF-SERVICE.personal-information")}
                  </span>
                </h4>
                {unlocked && (
                  <Button
                    theme="tertiary"
                    onClick={() => redirect("edit-personal-information")}
                    data-qa="buttonChangeInformation"
                    data-testid="edit-personal-information"
                  >
                    <ModeEdit className="mr-xs" size="small" />{" "}
                    {t("VIEWS.SELF-SERVICE.edit")}
                  </Button>
                )}
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col bold">
                      {t("VIEWS.SELF-SERVICE.first-name")}
                    </div>
                    <div className="col bold">
                      {t("VIEWS.SELF-SERVICE.last-name")}
                    </div>

                    <div className="w-100 d-none d-md-block mt-s"></div>

                    <div className={"col " + classes.longText}>
                      {user.firstName}
                    </div>
                    <div className={"col " + classes.longText}>
                      {user.lastName}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-l">
                <div className="col-6">
                  <div className="row">
                    <div className="col bold">
                      {t("VIEWS.SELF-SERVICE.preferred-language")}
                    </div>
                  </div>
                  <div className="row mt-s">
                    <div className="col">
                      {user.locale === "de_DE"
                        ? t("COMMON.german")
                        : user.locale === "fr_FR"
                        ? t("COMMON.french")
                        : user.locale === "en_US"
                        ? t("COMMON.english")
                        : ""}
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.line} />

              <div className="d-flex align-items-center justify-content-between">
                <h4 className="mb-s">
                  <span className={classes.semiBold}>
                    {t("VIEWS.SELF-SERVICE.email")}
                  </span>
                </h4>
                {unlocked && (
                  <Button
                    theme="tertiary"
                    onClick={() => checkChangeEmailTime()}
                    data-qa="buttonChangeEmail"
                    data-testid="edit-email"
                  >
                    <ModeEdit className="mr-xs" size="small" />{" "}
                    {t("VIEWS.SELF-SERVICE.edit")}
                  </Button>
                )}
              </div>

              <div className="mt-m" data-qa="labelEmail">
                {user.email}
              </div>

              <div className={classes.line} />

              <div className="d-flex align-items-center justify-content-between">
                <h4 className="mb-s">
                  <span className={classes.semiBold}>
                    {t("VIEWS.SELF-SERVICE.password")}
                  </span>
                </h4>
                {unlocked && (
                  <Button
                    theme="tertiary"
                    onClick={() => redirect("change-password")}
                    data-qa="buttonChangePassword"
                    data-testid="edit-password"
                  >
                    <ModeEdit className="mr-xs" size="small" />{" "}
                    {t("VIEWS.SELF-SERVICE.edit")}
                  </Button>
                )}
              </div>

              <div className="mt-m" data-qa="labelEmail">
                ••••••••••••••••
              </div>

              <div className={classes.line} />

              <h4 className="mb-s">
                <span className={classes.semiBold}>
                  {t("VIEWS.SELF-SERVICE.extra-verifications")}
                </span>
              </h4>

              <Mfa />
              <Warning
                header={t("VIEWS.CHANGE-EMAIL.warning-header")}
                body={t("VIEWS.CHANGE-EMAIL.warning-body")}
                close={t("VIEWS.CHANGE-EMAIL.warning-close")}
                onClose={() => setIsOpen(false)}
                isOpen={isOpen}
              />
            </CardWithLogo>
          </div>
        )}
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = {componentStack: "EnterCode"}
      return logError(error, info);
    },
  }
);

SelfService.displayName ='SelfService';

export default SelfService;
