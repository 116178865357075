import { memo } from "react";
import { Notification as BaseNotification } from "@epo/epods-react-components";

import { NOTIFICATION_CLOSE_TIME } from "../../../constants";
import { useStyles } from "./styles";

type Props = {
    text?: string;
    theme: "info" | "positive" | "warning" | "negative" | "neutral";
    autoHideDuration?: number;
    children?: React.ReactNode;
};

function Notification({ text, theme, autoHideDuration, children }: Props) {
    const classes = useStyles();

    return (
        <div className={classes.feedbackMessage}>
            <BaseNotification
                text={text}
                theme={theme}
                autoHideDuration={autoHideDuration || NOTIFICATION_CLOSE_TIME}
            >
                {children}
            </BaseNotification>
        </div>
    );
}

export default memo(Notification);
