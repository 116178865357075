import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  width180: {
    width: '180px',
  },
  email: {
    fontWeight:'bold'
  }
});
