import { Button, FormControl, Input } from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { config } from "../../../../config/config";
import CardWithLogo from "../../../common/cardwidthlogo/CardWithLogo";
import Notification from "../../../common/notification/Notification";
import { ErrorFallback, logError } from '../../../errorHandling';

const GoogleEnterCode = withErrorBoundary(
  () => {
    const [verificationCode, setVerificationCode] = useState("");
    const { authState } = useOktaAuth();
    const [t] = useTranslation("global");
    const history = useHistory();
    const [googleFactorId, seGoogleFactorId] = useState("");
    const [feedback, setFeedback] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [isWrongCodeFormat, setIsWrongCodeFormat] = useState(false);
    const [invalidCode, setInvalidCode] = useState(false);

    const redirect = (
      path: string,
      queryParams?: { name: string; value: string }
    ) => {
      path = "/" + path;
      if (queryParams) {
        path = path.concat(`?${queryParams.name}=${queryParams.value}`);
      }
      history.push(path);
    };

    const inputHandler = (e: any) => {
      setFeedback(false);
      setIsWrongCodeFormat(false);
      setInvalidCode(false);
      setVerificationCode(e.target.value);
    };

    const configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    useEffect(() => {
      axios
        .get(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors",
          configHeaders
        )
        .then((response) => {
          response.data.map((factor, i) =>
            factor.factorType === "token:software:totp" &&
            factor.provider === "GOOGLE"
              ? seGoogleFactorId(factor.id)
              : null
          );
        })
        .catch((error) => {
          setFeedback(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.idToken.claims.sub]);

    const verifyCode = () => {
      setSubmitted(true);
      axios
        .post(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors/" +
            googleFactorId +
            "/activate_google_factor",
          {
            passCode: verificationCode,
          },
          configHeaders
        )
        .then(() => {
          redirect("MFA/enrolled-factors", {
            name: "mfa_name",
            value: "google-auth",
          });
        })
        .catch((error) => {
          setFeedback(false);
          if (error.response.status === 400) {
            setIsWrongCodeFormat(true);
          } else if (error.response.status === 403) {
            setInvalidCode(true);
          } else {
            setFeedback(true);
            setErrorMessage(t("COMMON.generic-error"));
          }
        });
    };

    return (
      <>
        {feedback ? (
          <Notification text={errorMessage} theme="negative" />
        ) : null}
        <CardWithLogo noLogo centered withNavbar>
          <h1>{t("VIEWS.MFA.enter-code")}</h1>

          <p>{t("VIEWS.MFA.enter-the-code-displayed")}</p>
          <FormControl
            caption={
              (submitted && !verificationCode) ||
              isWrongCodeFormat ||
              invalidCode
                ? t("VIEWS.MFA.enter-valid-code")
                : null
            }
            error={
              (submitted && !verificationCode) ||
              isWrongCodeFormat ||
              invalidCode
                ? true
                : false
            }
          >
            <Input
              className="w-75"
              value={verificationCode}
              onChange={inputHandler}
              clearable
              data-testid="verification-code-input"
              onBlur={() => setSubmitted(true)}
            />
          </FormControl>

          <div className="row mt-xl">
            <div className="col text-right">
              <Button
                theme="secondary"
                onClick={() => redirect("MFA/google-verify/scan-qr")}
                data-testid="back-btn"
              >
                {t("VIEWS.MFA.back")}
              </Button>
              <Button
                type="submit"
                className="ml-m"
                onClick={verifyCode}
                data-testid="next-btn"
              >
                {t("VIEWS.MFA.next")}
              </Button>
            </div>
          </div>
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError: logError,
  }
);

export default GoogleEnterCode;
