import { Button, Card, Radio, RadioGroup } from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../../config/config";
import Notification from "../../common/notification/Notification";
import { useStyles } from "./styles";
import { SpinnerCircular } from "../../common/spinner/Spinner";
import { ErrorFallback, logError } from "../../errorHandling";

const useIsMountedRef = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    const currentTime = new Date().getTime();
    localStorage.setItem("loginTime", currentTime.toString());

    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

const PhoneSelectMethod = withErrorBoundary(
  () => {
    const { authState } = useOktaAuth();

    let history = useHistory();
    let location = useLocation();
    let data = location.state;
    let phoneNumber = data.oktaTokenFactorId;

    let configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    const [t] = useTranslation("global");
    const classes = useStyles();
    const [feedback, setFeedback] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [smsData, setSmsData] = useState({ enabled: false, id: null });
    const [callData, setCallData] = useState({ enabled: false, id: null });

    const [isLoading, setIsLoading] = useState(true);

    const isMountedRef = useIsMountedRef();

    const [value, setValue] = useState("sms");
    const radioHandler = (e: any) => {
      setValue(e.target.value);
    };

    useEffect(() => {
      setIsLoading(true);
      getFactors();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirect = (path: string, state: {}) => {
      history.push({
        pathname: "/" + path,
        state: { factorId: state, phoneNumber: phoneNumber },
      });
    };

    async function getFactors() {
      await axios
        .get(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors",
          configHeaders
        )
        .then((response) => {
          if (isMountedRef.current) {
            let callFactor = response.data?.find(
              (myFactor) =>
                myFactor.factorType === "call" && myFactor.status === "ACTIVE"
            );
            if (callFactor) {
              setValue("call");
              setCallData({
                enabled: true,
                id: callFactor.id,
              });
            }
            let smsFactor = response.data?.find(
              (myFactor) =>
                myFactor.factorType === "sms" && myFactor.status === "ACTIVE"
            );
            if (smsFactor) {
              setValue("sms");
              setSmsData({
                enabled: true,
                id: smsFactor.id,
              });
            }
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setFeedback(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
    }

    const handleSubmit = () => {
      if (value === "sms") {
        redirect("sms-OKTA-auth/", smsData.id);
      } else {
        redirect("call-OKTA-auth/", callData.id);
      }
    };

    return (
      <>
        {feedback && <Notification text={errorMessage} theme="negative" />}
        <div className={classes.wrapper + " container"}>
          <Card hideHeader className={classes.cardWrapper}>
            <h1 className={classes.title}>
              {t("VIEWS.UNLOCK.phone-select-method")}
            </h1>
            {isLoading ? (
              <div className="text-center">
                <SpinnerCircular
                  size={45}
                  thickness={180}
                  speed={100}
                  color="#066AE3"
                  secondaryColor="#C4C4C4"
                />
              </div>
            ) : (
              <div>
                <span className={classes.sizeText18}>
                  {value === "sms" ? (
                    <p>{t("VIEWS.UNLOCK.phone-select-method-sms")}</p>
                  ) : (
                    <p>{t("VIEWS.UNLOCK.phone-select-method-call")}</p>
                  )}

                  {smsData.enabled && callData.enabled ? (
                    <div>
                      <RadioGroup
                        style={{ zIndex: 999 }}
                        name="option"
                        value={value}
                        onChange={radioHandler}
                      >
                        <Radio
                          className="w-100 mb-s"
                          value="sms"
                          key="sms"
                          data-testid="select-sms-radio"
                        >
                          {t("VIEWS.UNLOCK.phone-select-method-sms-option")}
                        </Radio>
                        <Radio
                          className="w-100 mb-s"
                          value="call"
                          key="call"
                          data-testid="select-call-radio"
                        >
                          {t("VIEWS.UNLOCK.phone-select-method-call-option")}
                        </Radio>
                      </RadioGroup>
                    </div>
                  ) : null}
                </span>
                <br></br>
                <div className={classes.bottomButtons}>
                  <Button
                    className="mr-m"
                    theme="secondary"
                    onClick={() => redirect("unlock", {})}
                    data-testid="back-to-unlock-btn"
                  >
                    {t("VIEWS.MFA.back")}
                  </Button>
                  <Button onClick={handleSubmit} data-testid="next-btn">
                    {t("VIEWS.MFA.next")}
                  </Button>
                </div>
              </div>
            )}
          </Card>
        </div>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = { componentStack: "EnterCode" };
      return logError(error, info);
    },
  }
);

export default PhoneSelectMethod;
