import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  wrapper: {
    marginTop: "var(--epods--space-inset-m)",
    marginLeft: "auto",
    marginRight: "auto",
  },
  top: {
    justifyContent: "space-between",
    padding: "var(--epods--space-inset-m)",
  },
  passwordInformationContainer: {
    justifyContent: "space-between",
    paddingLeft: "var(--epods--space-inset-m)",
  },
  right: {
    float: "right",
  },
  preWrap: {
    whiteSpace: "pre-wrap",
    marginBottom: "0",
  },
  paddingLeft: {
    paddingLeft: "var(--epods--space-inset-m)",
  },
  p: {
    marginBottom: "0",
  },
  caption: {
    fontSize: "13px",
  },
  notificationText: {
    fontSize: "13px",
    lineHeight: "20px",
  },
  passwordCondition: {
    //make password condition one line
    marginRight: "-75px",
    marginBottom: "25px",
    fontSize: "14px",
    lineHeight: "18px",
    "& > span": {
      fontFamily: "var(--epods--font-secondary)",
    },
  },
});
