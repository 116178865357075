import {
  Button,
  FormControl,
  Input,
  Link,
} from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { config } from "../../../config/config";
import { useMigrated, useUnlocked } from "../../../context/user-context";
import CardWithLogo from "../../common/cardwidthlogo/CardWithLogo";
import Notification from "../../common/notification/Notification";
import { useStyles } from "./styles";
import { ErrorFallback, logError } from "../../errorHandling";

const SmsOktaAuth = withErrorBoundary(
  () => {
    const { authState } = useOktaAuth();
    let history = useHistory();
    let configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    const [t] = useTranslation("global");
    const classes = useStyles();
    const [feedback, setFeedback] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [invalidCode, setInvalidCode] = useState(false);
    const [code, setCode] = useState("");
    const { migratedUser } = useMigrated();
    const { setUnlocked } = useUnlocked();
    const [userCallPhone, setUserCallPhone] = useState();

    const { factorId } = (history.location && history.location.state) || {};

    const redirect = (path: string) => {
      history.push("/" + path);
    };

    useEffect(() => {
      handleSendCode();
      getWebAuthFactors();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formatPhoneNumber = (phoneNumber) => {
      const countryCode = phoneNumber.slice(0, 4);
      const restOfNumber = phoneNumber.slice(4);
      const formattedNumber = restOfNumber.replace(
        /\d{1,3}(?=(\d{3})+(?!\d))/g,
        "XXX "
      );
      return `${countryCode} ${formattedNumber}`;
    };

    const handleSendCode = async () => {
      setFeedback(false);
      axios
        .post(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors/" +
            factorId +
            "/verify",
          {},
          configHeaders
        )
        .catch((error) => {
          setFeedback(false);
          if (error.response.status === 429) {
            setFeedback(true);
            setErrorMessage(t("VIEWS.MFA.sms-already-sent-error-message"));
          } else {
            setFeedback(true);
            setErrorMessage(t("COMMON.generic-error"));
          }
        });
    };

    async function getWebAuthFactors() {
      axios
        .get(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors",
          configHeaders
        )
        .then((response) => {
          let webAuthFactorList = [];
          response.data.forEach((factor) => {
            if (
              factor.factorType === "webauthn" &&
              factor.status === "ACTIVE"
            ) {
              webAuthFactorList.push(factor);
            }
            if (factor.factorType === "sms" && factor.status === "ACTIVE") {
              setUserCallPhone(factor.profile.phoneNumber);
            }
          });
        })
        .catch(() => {
          setGenericError();
        });
    }

    const setGenericError = () => {
      setFeedback(true);
      setErrorMessage(t("COMMON.generic-error"));
    };

    const handleSubmit = async () => {
      if (code) {
        setSubmitted(true);
        axios
          .post(
            config.api.baseUrl +
              "/users/" +
              authState.idToken.claims.sub +
              "/factors/" +
              factorId +
              "/verify",
            {
              passCode: code,
            },
            configHeaders
          )
          .then(async (response) => {
            if (
              response.status === 200 &&
              response.data.factorResult === "SUCCESS"
            ) {
              setUnlocked(true);
              if (migratedUser.migrated) {
                redirect("migration");
              } else {
                redirect("account");
              }
            }
          })
          .catch((error) => {
            setFeedback(false);
            if (
              error.response.status === 403 ||
              error.response.status === 400
            ) {
              setInvalidCode(true);
            } else {
              setFeedback(true);
              setErrorMessage(t("COMMON.generic-error"));
            }
          });
      }
    };

    return (
      <>
        {feedback && <Notification text={errorMessage} theme="negative" />}
        <CardWithLogo noLogo centered withNavbar>
          <h1>{t("VIEWS.UNLOCK.verify-using-sms-code")}</h1>

          {t("VIEWS.UNLOCK.enter-code-you-received")}
          {userCallPhone && ` (${formatPhoneNumber(userCallPhone)})`}

          <FormControl
            label-props={{ style: { display: "none" } }}
            caption={
              (submitted && !code) || invalidCode
                ? t("VIEWS.UNLOCK.enter-valid-code")
                : null
            }
            error={(submitted && !code) || invalidCode ? true : false}
          >
            <Input
              className={classes.width180 + " mt-s"}
              value={code}
              clearable
              onChange={(e) => [
                setFeedback(false),
                setCode(e.target.value),
                setInvalidCode(false),
              ]}
              onBlur={() => {
                if (!code) {
                  setInvalidCode(true);
                }
              }}
              data-testid="code-input"
            />
          </FormControl>

          <p>
            {t("VIEWS.UNLOCK.havent-receive-sms")}{" "}
            <Link data-testid="scan-qr-link" onClick={handleSendCode}>
              {t("VIEWS.UNLOCK.request-sms-code")}
            </Link>
          </p>

          <div className="row mt-xl">
            <div className="col text-right">
              <Button
                theme="secondary"
                data-testid="back-btn"
                onClick={() => redirect("unlock")}
              >
                {t("VIEWS.UNLOCK.back")}
              </Button>
              <Button
                type="submit"
                data-testid="submit"
                className="ml-m"
                onClick={handleSubmit}
              >
                {t("VIEWS.UNLOCK.verify")}
              </Button>
            </div>
          </div>
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = { componentStack: "EnterCode" };
      return logError(error, info);
    },
  }
);

export default SmsOktaAuth;
