import { useHistory } from "react-router-dom";
import { Trans,useTranslation } from "react-i18next";
import { withErrorBoundary } from "react-error-boundary";
import axios from "axios";
import { Button,Link } from "@epo/epods-react-components";
import { CheckCircle } from "@epo/epods-react-components/lib/Icons/CheckCircle";
import { useOktaAuth } from "@okta/okta-react";

import { config } from "../../../config/config";
import { useStyles } from "./styles";
import CardWithLogo from "../../common/cardwidthlogo/CardWithLogo";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const [t] = useTranslation("global");

  return (
    <div role="alert">
      <p>{t("COMMON.something-went-wrong")}</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>{t("COMMON.try-again")}</button>
    </div>
  );
};

const ErrorFunction = (error: Error, info: { componentStack: string }) => {
  const { authState } = useOktaAuth();
  let configHeaders = {
    headers: {
      Authorization: `Bearer ${authState.accessToken.accessToken}`,
    },
  };

  axios.post(
    config.api.baseUrl + "/logs/" + authState.idToken.claims.sub + "/log/error",
    {
      msg: error.message,
      level: { info, error },
      stacktrace: error.stack,
      timestamp: Date.now(),
      component: "Confirmation",
      client: "CIAM APP",
    },
    configHeaders
  );
};

const Confirmation = withErrorBoundary(
  () => {
    const [t] = useTranslation("global");
    const classes = useStyles();
    let history = useHistory();

    const redirect = (path: string) => {
      history.push("/" + path);
    };

    return (
      <CardWithLogo centered>
        <div className="d-flex flex-column align-items-center">
          <div className={" m-sm-m text-center"}>
            <div className={classes.icon}>
              <CheckCircle className={classes.icon} size="48px" />
            </div>
          </div>

          <div className={"text-center"}>
            <h1 className="mt-s">
              {t("VIEWS.REGISTRATION.account-registered-message")}
            </h1>
          </div>

          <div className="mr-n-l" style={{ whiteSpace: 'break-spaces' }}>
            <Link
              data-testid="privacy-policy"
              onClick={() => window.location.replace("https://my.epo.org/")}
            >
              <Trans>{t(`VIEWS.WELCOME.myepo-link`)}</Trans>
            </Link>
          </div>

          <div className="my-l p-m">
            <Button
              data-testid="goto-homepage"
              onClick={() => window.location.replace("https://www.epo.org/")}
            >
              {t("VIEWS.REGISTRATION.go-back-to-intranet")}
            </Button>
            <Button
              data-testid="logButton"
              theme="tertiary"
              className="ml-m"
              onClick={() => {
                redirect("account");
              }}
            >
              {t("VIEWS.REGISTRATION.log-into-your-account")}
            </Button>
          </div>
        </div>
      </CardWithLogo>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError: ErrorFunction,
  }
);

export default Confirmation;
