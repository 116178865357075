import { Button } from "@epo/epods-react-components";
import { CheckCircle } from "@epo/epods-react-components/lib/Icons/CheckCircle";
import React, { useContext } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ChangeEmailContext,
} from "../../../../context/user-context";
import CardWithLogo from "../../../common/cardwidthlogo/CardWithLogo";
import { useStyles } from "./styles";
import { ErrorFallback, logError } from "../../../errorHandling";

const ConfirmationEmail = withErrorBoundary(
  () => {
    const [t] = useTranslation("global");
    const classes = useStyles();
    const history = useHistory();

    const redirect = (
      path: string,
      queryParams?: { name: string; value: string }
    ) => {
      path = "/" + path;
      if (queryParams) {
        path = path.concat(`?${queryParams.name}=${queryParams.value}`);
      }
      history.push(path);
    };

    const { changeEmailUser } = useContext(ChangeEmailContext);

    return (
      <CardWithLogo centered noLogo withNavbar>
        <div className="text-center">
          <CheckCircle className={classes.circleIcon} size="48px" />
          <h1>{t(`VIEWS.CHANGE-EMAIL.change-title-email`)}</h1>
        </div>
        <p>
          {t(`VIEWS.CHANGE-EMAIL.change-email-subtext`)}
          <strong>{changeEmailUser.email}</strong>
        </p>
        <p>
          <strong>{t("VIEWS.CHANGE-EMAIL.change-email-warning")}</strong>
        </p>

        <div className="text-center mt-l">
          <Button
            onClick={() => {
              redirect("account");
            }}
            data-testid="back-to-settings"
          >
            {t("VIEWS.MFA.go-back-to-account-settings")}
          </Button>
        </div>
      </CardWithLogo>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = {componentStack: "EnterCode"}
      return logError(error, info);
    },
  }
);

export default ConfirmationEmail;
