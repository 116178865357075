import {
  Button,
  FormControl,
  Input,
  Link,
  Radio,
  RadioGroup,
} from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import React, { useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { config } from "../../../../config/config";
import { PHONE_NUMBER_REGEX } from "../../../../constants";
import CardWithLogo from "../../../common/cardwidthlogo/CardWithLogo";
import Notification from "../../../common/notification/Notification";
import { ErrorFallback, logError } from "../../../errorHandling";
export interface Option {
  readonly id?: string | number;
  readonly label?: React.ReactNode;
  readonly disabled?: boolean;
  readonly clearableValue?: boolean;
  readonly isCreatable?: boolean;
  readonly __optgroup?: string;
  [others: string]: any;
}

const CantScanQr = withErrorBoundary(
  () => {
    const [t] = useTranslation("global");
    const { authState } = useOktaAuth();
    const configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    const [value, setValue] = useState("email");
    const radioHandler = (e: any) => {
      setValue(e.target.value);
    };

    const [smsValue, setSmsValue] = useState("");
    const [feedback, setFeedback] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const history = useHistory();

    const redirect = (path: string) => {
      history.push("/" + path);
    };
    let { factorId } = useParams();

    const handleSend = () => {
      setSubmitted(true);
      if (value === "email" || PHONE_NUMBER_REGEX.test(smsValue)) {
        axios
          .post(
            config.api.baseUrl +
              "/users/" +
              authState.idToken.claims.sub +
              "/factors/" +
              factorId +
              "/activate/" +
              value,
            value === "sms"
              ? {
                  profile: { phoneNumber: smsValue },
                }
              : {},
            configHeaders
          )
          .then(() => {
            value === "sms"
              ? redirect("MFA/okta-sms-sent/" + factorId)
              : redirect("MFA/okta-email-sent/" + factorId);
          })
          .catch(() => {
            setFeedback(true);
            setErrorMessage(t("COMMON.generic-error"));
          });
      }
    };

    return (
      <>
        {feedback ? (
          <Notification text={errorMessage} theme="negative" />
        ) : null}
        <CardWithLogo centered noLogo withNavbar>
          <h1>{t("VIEWS.MFA.receive-activation-link")}</h1>

          <p>{t("VIEWS.MFA.select-how-want-receive-activation-link")}</p>

          <div className="mt-l">
            <RadioGroup
              style={{ zIndex: 999 }}
              name="option"
              value={value}
              onChange={radioHandler}
            >
              <Radio className="w-100" value="email" data-testid="email-radio">
                {t("VIEWS.MFA.receive-link-via-email")}
              </Radio>
              <Radio className="w-100 mt-m" value="sms" data-testid="sms-radio">
                {t("VIEWS.MFA.receive-link-via-sms")}
              </Radio>
            </RadioGroup>

            <div style={{ width: "180px" }}>
              <FormControl
                label-props={{ style: { display: "none" } }}
                caption={
                  value === "sms" &&
                  submitted &&
                  !PHONE_NUMBER_REGEX.test(smsValue)
                    ? t("VIEWS.MFA.enter-valid-phone-number")
                    : null
                }
                error={
                  value === "sms" &&
                  submitted &&
                  !PHONE_NUMBER_REGEX.test(smsValue)
                }
              >
                <Input
                  className="mt-s"
                  value={smsValue}
                  placeholder={t("VIEWS.MFA.okta-phone-number-placeholder")} // +3311111111
                  disabled={value === "email"}
                  onChange={(e) => [
                    setFeedback(false),
                    setSmsValue(e.target.value),
                  ]}
                  onBlur={() => setSubmitted(true)}
                />
              </FormControl>
            </div>
          </div>

          <p>
            {t("VIEWS.MFA.prefer-scan")}{" "}
            <Link
              data-testid="scan-qr-link"
              onClick={() => redirect("MFA/okta-scan-qr")}
            >
              {t("VIEWS.MFA.scan-qr")}
            </Link>
          </p>

          <div className="row mt-xl">
            <div className="col text-right">
              <Button
                theme="secondary"
                onClick={() => redirect("MFA/okta-scan-qr")}
                data-testid="back-btn"
              >
                {t("VIEWS.MFA.back")}
              </Button>
              <Button
                type="submit"
                className="ml-m"
                onClick={() => handleSend()}
                name="send"
                data-testid="next-btn"
              >
                {t("VIEWS.MFA.next")}
              </Button>
            </div>
          </div>
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError: logError,
  }
);

export default CantScanQr;
