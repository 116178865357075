import { Button } from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import React, { useEffect } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { config } from "../../../config/config";
import CardWithLogo from "../../common/cardwidthlogo/CardWithLogo";
import googlePlayBadge from "./../../../assets/images/google-play-badge.png";
import appleStoreBadge from "./../../../assets/images/apple-store-badge.png";
import { useMigrated, useUser } from "../../../context/user-context";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const [t] = useTranslation("global");

  return (
    <div role="alert">
      <p>{t("COMMON.something-went-wrong")}</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>{t("COMMON.try-again")}</button>
    </div>
  );
};

const ErrorFunction = (error: Error, info: { componentStack: string }) => {
  const { authState } = useOktaAuth();
  let configHeaders = {
    headers: {
      Authorization: `Bearer ${authState.accessToken.accessToken}`,
    },
  };

  axios.post(
    config.api.baseUrl + "/logs/" + authState.idToken.claims.sub + "/log/error",
    {
      msg: error.message,
      level: { info, error },
      stacktrace: error.stack,
      timestamp: Date.now(),
      component: "GoogleAuthentication",
      client: "CIAM APP",
    },
    configHeaders
  );
};

const GoogleAuthentication = withErrorBoundary(
  () => {
    const [t] = useTranslation("global");
    const history = useHistory();
    const { user } = useUser();
    const { authState } = useOktaAuth();
    const { migratedUser } = useMigrated();

    let configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    useEffect(() => {
      logOpenGoogleAuthPage();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logOpenGoogleAuthPage = () => {
      axios.post(
        config.api.baseUrl +
          "/logs/" +
          authState.idToken.claims.sub +
          "/log/info",
        {
          msg: "Opening Google Authentication page",
          component: "GoogleAuthentication",
          client: "CIAM APP",
          loginName: user?.login,
          emailAddress: user?.email,
          epolinenumber: user?.epolineNumber,
        },
        configHeaders
      );
    };

    const redirect = (path: string) => {
      history.push("/" + path);
    };

    return (
      <CardWithLogo noLogo centered withNavbar>
        <h1>{t("VIEWS.MFA.download-ga-app")}</h1>

        <p>{t("VIEWS.MFA.start-by-installing-ga-app")}</p>

        <div className="mt-l">
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            target="_blank"
            rel="noopener noreferrer"
            data-testid="google-play-link"
          >
            <img
              src={googlePlayBadge}
              alt={t("COMMON.google-play-badge-text")}
            />
          </a>
          <a
            href="https://apps.apple.com/ar/app/google-authenticator/id388497605"
            target="_blank"
            rel="noopener noreferrer"
            data-testid="apple-store-link"
          >
            <img
              className="ml-m"
              src={appleStoreBadge}
              alt={t("COMMON.apple-store-badge-text")}
            />
          </a>
        </div>

        <div className="row mt-xl">
          <div className="col text-right">
            <Button
              theme="secondary"
              onClick={() =>
                migratedUser?.migrated
                  ? redirect("migration")
                  : redirect("account")
              }
              data-testid="back-to-settings"
            >
              {migratedUser?.migrated
                ? t("VIEWS.MFA.go-back-to-migration")
                : t("VIEWS.MFA.go-back-to-account-settings")}
            </Button>
            <Button
              type="submit"
              className="ml-m"
              onClick={() => redirect("MFA/google-verify/scan-qr")}
              data-testid="next-btn"
            >
              {t("VIEWS.MFA.next")}
            </Button>
          </div>
        </div>
      </CardWithLogo>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError: ErrorFunction,
  }
);

export default GoogleAuthentication;
