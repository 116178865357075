import { Button, Link } from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { config } from "../../../../config/config";
import CardWithLogo from "../../../common/cardwidthlogo/CardWithLogo";
import Notification from "../../../common/notification/Notification";
import { ErrorFallback, logError } from "../../../errorHandling";

const OktaScanQR = withErrorBoundary(
  () => {
    const [qrCodeImage, setQrCodeImage] = useState();
    const { authState } = useOktaAuth();
    const [t] = useTranslation("global");
    const history = useHistory();
    const configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };
    const [pollingFactorResult, setPollingFactorResult] = useState("WAITING");
    const [oktaFactorIdState, setOktaFactorIdState] = useState("WAITING");
    let oktaFactorId = "";
    const [feedback, setFeedback] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const redirect = (
      path: string,
      queryParams?: { name: string; value: string }
    ) => {
      path = "/" + path;
      if (queryParams) {
        path = path.concat(`?${queryParams.name}=${queryParams.value}`);
      }
      history.push(path);
    };

    const polling = () => {
      axios
        .post(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors/" +
            oktaFactorId +
            "/activate/poll",
          {},
          configHeaders
        )
        .then((res) => {
          setPollingFactorResult(res.data.factorResult);
          if (res.data.status === "ACTIVE") {
            redirect("MFA/enrolled-factors", {
              name: "mfa_name",
              value: "okta",
            });
          } else {
            setTimeout(function () {
              if (window.location.pathname === "/MFA/okta-scan-qr") polling();
            }, 3000);
          }
        })
        .catch(() => {
          setFeedback(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
    };

    useEffect(() => {
      setLoading(true);
      axios
        .post(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors",
          {
            factorType: "push",
            provider: "OKTA",
          },
          configHeaders
        )
        .then((response) => {
          setQrCodeImage(response.data._embedded.activation._links.qrcode.href);
          setLoading(false);
          setOktaFactorIdState(response.data.id);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          oktaFactorId = response.data.id;
          axios
            .post(
              config.api.baseUrl +
                "/users/" +
                authState.idToken.claims.sub +
                "/factors/" +
                response.data.id +
                "/activate_okta_factor",
              {},
              configHeaders
            )
            .then((response) => {
              if (response.data.status === "ACTIVE") {
                redirect("MFA/enrolled-factors", {
                  name: "mfa_name",
                  value: "okta",
                });
              } else if (
                response.data.factorResult === "TIMEOUT" ||
                response.data.factorResult === "REJECTED"
              ) {
                console.log("registration error");
              } else {
                if (pollingFactorResult === "WAITING") {
                  polling();
                }
              }
            })
            .catch((error) => {
              setFeedback(true);
              setErrorMessage(t("COMMON.generic-error"));
            });
        })
        .catch((error) => {
          setFeedback(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
    }, [authState.idToken.claims.sub, authState.accessToken.accessToken]);

    return (
      <>
        {feedback ? (
          <Notification text={errorMessage} theme="negative" />
        ) : null}
        <CardWithLogo centered noLogo withNavbar>
          <h1>{t("VIEWS.MFA.scan-barcode")}</h1>

          <p>{t("VIEWS.MFA.step1-launch-okta-verify")}</p>
          <p>
            {t("VIEWS.MFA.step2-select", {
              account: t("VIEWS.MFA.add-account"),
            })}
          </p>
          <p>
            {t("VIEWS.MFA.step3-choose-account-type", {
              organization: t("VIEWS.MFA.organization"),
            })}
          </p>
          <p>
            {t("VIEWS.MFA.step4-scan-qr-code-okta", {
              scanQrCode: t("VIEWS.MFA.scan-a-qr-code"),
            })}
          </p>

          {!loading ? (
            <img
              className="position-relative"
              src={qrCodeImage}
              alt={t("ALT-TEXT.okta-qr-code")}
              style={{ left: "-20px" }}
            />
          ) : null}

          <p>
            {t("VIEWS.MFA.cant-scan")}{" "}
            <Link
              data-testid="receive-an-activation-link"
              onClick={() =>
                redirect("MFA/okta-cant-scan-qr/" + oktaFactorIdState)
              }
            >
              {t("VIEWS.MFA.receive-an-activation-link")}
            </Link>
          </p>

          <div className="row mt-xl">
            <div className="col text-right">
              <Button
                theme="secondary"
                onClick={() => redirect("MFA/okta-verify")}
                data-testid="back-btn"
              >
                {t("VIEWS.MFA.back")}
              </Button>
              <Button type="submit" className="ml-m" disabled>
                {t("VIEWS.MFA.next")}
              </Button>
            </div>
          </div>
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError: logError,
  }
);

export default OktaScanQR;
