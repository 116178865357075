import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  passwordCondition: {
    //make password condition one line
    marginRight: "-75px",
    fontSize: "14px",
    lineHeight: "18px",
    "& > span": {
      fontFamily: "var(--epods--font-secondary)",
    },
  },
  icon: {
    width: "40px",
  },
  errorIconContainer: {
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  spinnerWrapper: {
    textAlign: "center",
    width: "100%",
  },
  spinner: {
    marginBottom: "var(--epods--space-inset-m)",
    color: "var(--epods--color-primary)",
    marginTop: "32px",
  },
  spinnerText: {
    lineHeight: "30px",
  },
  width400: {
    width: "400px",
  },
  checkbox: {
    paddingTop: "16px",
    // Overflow of terms in German
    "& > label": {
      "& > div": {
        overflow: "visible",
      },
    },
  },
});
