import { createContext, useContext } from "react";

export type User = {
  user: {
    firstName: string;
    lastName: string;
    email: string;
    locale: string;
    preferredLanguage?:string;
    login: string;
    mfa: boolean;
    epolineNumber: string;
    epoMatchSmartCard: string;
    epoMigratedUser: string;
    terms?:boolean
  };
  setUser: (u: {
    firstName: string;
    lastName: string;
    email: string;
    locale: string;
    preferredLanguage?:string;
    login: string;
    mfa: boolean;
    epolineNumber: string;
    epoMatchSmartCard: string;
    epoMigratedUser: string;
    terms?:boolean;
  }) => void;
};

export type Unlocked = {
  unlocked: boolean;
  setUnlocked: (u: boolean) => void;
};

export type Migrated = {
  migratedUser: {
    migrated: boolean;
    step: string;
    email?: string;
    password?: string;
    isClash?: boolean;
    numRegisteredFactors?: number;
    terms?:boolean;
  };

  setMigratedUser: (u: {
    migrated: boolean;
    step: string;
    email?: string;
    password?: string;
    isClash?: boolean;
    numRegisteredFactors?: number;
    terms?:boolean;
  }) => void;
};

export type ChangeEmail= {
  changeEmailUser:{
    expiresAt: string;
    id: string;
    emailId: string;
    email: string;
  }
  setChangeEmailUser: (u: {
    expiresAt: string;
    id: string;
    emailId: string;
    email: string;
  }) => void;
}

export const UserContext = createContext<User>({
  user: {
    firstName: "",
    lastName: "",
    email: "",
    locale: "",
    preferredLanguage:"",
    login: "",
    mfa: false,
    epolineNumber: "",
    epoMatchSmartCard: "",
    epoMigratedUser: "",
    terms:false
  },
  setUser: () => {
    /**
     * // setter
     */
  },
});

export const useUser = () => useContext(UserContext);

export const UnlockedContext = createContext<Unlocked>({
  unlocked: false,
  setUnlocked: () => {
    /**
     * // setter
     */
  },
});

export const useUnlocked = () => useContext(UnlockedContext);

export const MigratedContext = createContext<Migrated>({
  migratedUser: {
    migrated: false,
    step: "email",
    email: "",
    password: "",
    isClash: false,
    numRegisteredFactors: 0,
    terms:false
  },
  setMigratedUser: () => {
    /**
     * // setter
     */
  },
});

export const useMigrated = () => useContext(MigratedContext);


export const ChangeEmailContext = createContext<ChangeEmail>({
  changeEmailUser: {
    expiresAt: "",
    id: "",
    emailId: "",
    email: "",
  },
  setChangeEmailUser: () => {
    /**
     * // setter
     */
  },
  
  
});

export const useChangeEmail = () => useContext(ChangeEmailContext);


