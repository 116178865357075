import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import React, {  useCallback, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { config } from "../../config/config";
import {
  ChangeEmailContext,
  MigratedContext,
  UnlockedContext,
  UserContext,
} from "../../context/user-context";
import { setupInterceptors } from "../../interceptors/response-interceptor";
import Navbar from "../layout/Navbar";
import SignIn from "../login/SignIn";
import LogOut from "../logout/LogOut";
import EnrolledFactors from "../mfa/enrolled-factors/EnrolledFactors";
import GoogleCantScanQr from "../mfa/google-authentication/cant-scan-qr/GoogleCantScanQr";
import GoogleEnterCode from "../mfa/google-authentication/enter-code/GoogleEnterCode";
import GoogleAuthentication from "../mfa/google-authentication/GoogleAuthentication";
import GoogleScanQR from "../mfa/google-authentication/scan-qr/GoogleScanQR";
import CantScanQr from "../mfa/okta-verify/cant-scan-qr/CantScanQr";
import OktaEmailSent from "../mfa/okta-verify/email-sent/OktaEmailSent";
import OktaVerify from "../mfa/okta-verify/OktaVerify";
import OktaScanQR from "../mfa/okta-verify/scan-qr/OktaScanQR";
import EnterCode from "../mfa/sms-verify/enter-code/EnterCode";
import EnterCodePhone from "../mfa/phone-verify/enter-code/EnterCode";
import OktaSmsSent from "../mfa/okta-verify/sms-sent/OktaSmsSent";
import OktaPhoneSent from "../mfa/okta-verify/phone-sent/OktaPhoneSent";
import WebauthnVerify from "../mfa/webauthn-verify/WebauthnVerify";
import PhoneEnterNumber from "../mfa/phone-enter-number/PhoneEnterNumber";
import Migration from "../migration/Migration";
import Confirmation from "../registration/confirmation/Confirmation";
import MailboxConfirmation from "../registration/mailbox-confirmation/MailboxConfirmation";
import Registration from "../registration/Registration";
import Welcome from "../welcome/Welcome";
import RegistrationError from "../registration/registration-error/RegistrationError";
import ChangeEmail from "../selfservice/change-email/ChangeEmail";
import ChangePassword from "../selfservice/change-password/ChangePassword";
import EditPersonalInformation from "../selfservice/edit-personal-information/EditPersonalInformation";
import SelfService from "../selfservice/Selfservice";
import GoogleAuthenticatorAuth from "../unlock/google-authenticator-auth/GoogleAuthenticatorAuth";
import PushOktaAuth from "../unlock/push-OKTA-auth/PushOktaAuth";
import SmsOktaAuth from "../unlock/sms-OKTA-auth/SmsOktaAuth";
import PhoneOktaAuth from "../unlock/phone-OKTA-auth/PhoneOktaAuth";
import Unlock from "../unlock/Unlock";
import WebauthnFidoAuth from "../unlock/webauthn-FIDO-auth/WebauthnFidoAuth";
import PhoneSelectMethod from "../unlock/phone-select-method/PhoneSelectMethod";
import Home from "./Home";
import EmailMfaAuth from "../unlock/email-mfa-auth/EmailMfaAuth";
import SelectingMethods from "../migration/selecting-methods/SelectingMethods";
import ConfirmationEmail from "../selfservice/change-email/confirmation-email/ConfirmationEmail";
import ConfirmEmail from "../selfservice/change-email/confirm-email/ConfirmEmail";

interface ChangeEmailProps {
  expiresAt: string;
  id: string;
  emailId: string;
  email: string;
}
interface User {
  firstName: string;
  lastName: string;
  email: string;
  locale: string;
  login: string;
  mfa: boolean;
  epolineNumber: string;
  epoMatchSmartCard: string;
  epoMigratedUser: string;
}

interface Migrated {
  migrated: boolean;
  step: string;
  email?: string;
  password?: string;
  isClash?: boolean;
  numRegisteredFactors?: number;
}

const AppWithRouterAccess = (props) => {
  const [user, setUser] = useState<User>({
    firstName: "",
    lastName: "",
    email: "",
    locale: "",
    login: "",
    mfa: false,
    epolineNumber: "",
    epoMatchSmartCard: "",
    epoMigratedUser: "",
  });

  const handleChangeUser = useCallback((newUser:User) => {
    if(JSON.stringify(user) !== JSON.stringify(newUser)) {
      setUser(newUser)
    }
  },[user])

 


  const [changeEmailUser, setChangeEmailUser] = useState<ChangeEmailProps>({
    expiresAt: "",
    id: "",
    emailId: "",
    email: "",
  });

  const [unlocked, setUnlocked] = useState<boolean>(false);
  const [migratedUser, setMigratedUser] = useState<Migrated>({
    migrated: false,
    step: "email",
    email: "",
    password: "",
    isClash: false,
    numRegisteredFactors: 0,
  });

  const history = useHistory();
  const onAuthRequired = () => {
    history.push("/login");
  };

  const oktaAuth = new OktaAuth(config.oidc);

  setupInterceptors(history, oktaAuth);

  const restoreOriginalUri = (_, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };
 
  
  const handleChangeMigrated = useCallback((newUser:Migrated) => {
    if(JSON.stringify(migratedUser) !== JSON.stringify(newUser)) {
      setMigratedUser(newUser)
    }
  },[migratedUser])


  return (
    <MigratedContext.Provider value={{ migratedUser, setMigratedUser:handleChangeMigrated }}>
      <UnlockedContext.Provider value={{ unlocked, setUnlocked }}>
        <UserContext.Provider value={{ user, setUser: handleChangeUser}}>
          <ChangeEmailContext.Provider
            value={{ changeEmailUser, setChangeEmailUser }}
          >
            <Security
              oktaAuth={oktaAuth}
              restoreOriginalUri={restoreOriginalUri}
              onAuthRequired={onAuthRequired}
            >
              <Navbar />
              <Switch>
                <Route path="/login/callback" component={LoginCallback} />
                <Route
                  exact
                  path="/"
                  render={(homeProps) => <Home {...homeProps} />}
                />
                <Route exact path="/login" render={() => <SignIn />} />
                {/* Rename self-service to account failsafe for missing changes */}
                <Route path="/self-service">
                  <Redirect to="/account" />
                </Route>
                <SecureRoute
                  path="/account"
                  render={() => <SelfService {...props} />}
                />
                <SecureRoute path="/migration" render={() => <Migration />} />
                <SecureRoute path="/welcome" render={() => <Welcome />} />
                <Route
                  path="/migration-confirmation"
                  render={() => <SelectingMethods />}
                />
                <Route path="/registration" render={() => <Registration />} />
                <Route
                  path="/registration-error"
                  render={() => <RegistrationError />}
                />
                <Route
                  path="/signup/mailbox/:username"
                  render={() => <MailboxConfirmation />}
                />
                <Route
                  path="/signup/confirmation"
                  render={() => <Confirmation />}
                />
                <SecureRoute
                  path="/change-email"
                  render={() => <ChangeEmail {...props} />}
                />
                <SecureRoute
                  path="/confirm-email"
                  render={() => <ConfirmEmail {...props} />}
                />
                <SecureRoute
                  path="/confirmation-email"
                  render={() => <ConfirmationEmail {...props} />}
                />
                <SecureRoute
                  path="/change-password"
                  render={() => <ChangePassword {...props} />}
                />
                <SecureRoute
                  path="/edit-personal-information"
                  render={() => <EditPersonalInformation />}
                />
                <SecureRoute
                  path="/MFA/google-authentication"
                  render={() => <GoogleAuthentication />}
                />     
                <SecureRoute
                  path="/MFA/sms-enter-code"
                  render={() => <EnterCode />}
                />
                <SecureRoute
                  path="/MFA/phone-enter-code"
                  render={() => <EnterCodePhone />}
                />
                <SecureRoute
                  path="/MFA/okta-verify"
                  render={() => <OktaVerify />}
                />
                <SecureRoute
                  path="/MFA/okta-scan-qr"
                  render={() => <OktaScanQR />}
                />

                <SecureRoute
                  path="/MFA/okta-cant-scan-qr/:factorId"
                  render={() => <CantScanQr />}
                />
                <SecureRoute
                  path="/MFA/okta-sms-sent/:factorId"
                  render={() => <OktaSmsSent />}
                />
                <SecureRoute
                  path="/MFA/okta-phone-sent/:factorId"
                  render={() => <OktaPhoneSent />}
                />
                <SecureRoute
                  path="/MFA/okta-email-sent/:factorId"
                  render={() => <OktaEmailSent />}
                />
                <SecureRoute
                  path="/MFA/google-verify/scan-qr"
                  render={() => <GoogleScanQR />}
                />
                <SecureRoute
                  path="/MFA/google-cant-scan-qr"
                  render={() => <GoogleCantScanQr />}
                />
                <SecureRoute
                  path="/MFA/google-verify"
                  render={() => <GoogleAuthentication />}
                />
                <SecureRoute
                  path="/MFA/enrolled-factors"
                  render={() => <EnrolledFactors />}
                />
                <SecureRoute
                  path="/MFA/webauthn-verify"
                  render={() => <WebauthnVerify />}
                />
                <SecureRoute
                  path="/MFA/google-enter-code"
                  render={() => <GoogleEnterCode />}
                />
                <SecureRoute
                  path="/MFA/email-MFA-sent/:factorId"
                  render={() => <GoogleEnterCode />}
                />

                <SecureRoute path="/unlock" render={() => <Unlock />} />
                <SecureRoute
                  path="/sms-OKTA-auth"
                  render={() => <SmsOktaAuth />}
                />
                <SecureRoute
                  path="/call-OKTA-auth"
                  render={() => <PhoneOktaAuth />}
                />
                <SecureRoute
                  path="/phone-select-method"
                  render={() => <PhoneSelectMethod />}
                />
                  <SecureRoute
                  path="/MFA/phone-enter-number"
                  render={() => <PhoneEnterNumber />}
                />
                <SecureRoute
                  path="/google-authenticator-auth"
                  render={() => <GoogleAuthenticatorAuth />}
                />
                <SecureRoute
                  path="/push-OKTA-auth"
                  render={() => <PushOktaAuth />}
                />
                <SecureRoute
                  path="/email-OKTA-auth"
                  render={() => <EmailMfaAuth />}
                />
                <SecureRoute
                  path="/MFA/email-mfa"
                  render={() => <EmailMfaAuth />}
                />
                <SecureRoute
                  path="/webauthn-FIDO-auth"
                  render={() => <WebauthnFidoAuth />}
                />
                <SecureRoute
                  path="/email-MFA-auth"
                  render={() => <EmailMfaAuth />}
                />
                <Route path="/logout" render={() => <LogOut />} />
              </Switch>
            </Security>
          </ChangeEmailContext.Provider>
        </UserContext.Provider>
      </UnlockedContext.Provider>
    </MigratedContext.Provider>
  );
};
export default AppWithRouterAccess;
