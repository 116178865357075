import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../config/config";
import i18n from "../../i18n";
import CardWithLogo from "../common/cardwidthlogo/CardWithLogo";
import { useUser } from "../../context/user-context";
import { useStyles } from "./styles";
import { Button, Radio, RadioGroup } from "@epo/epods-react-components";
import { SpinnerCircular } from "../common/spinner/Spinner";
import { BUTTON_DELAY } from "../../constants";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Welcome = () => {
  const { setUser } = useUser();
  const [t] = useTranslation("global");
  const history = useHistory();
  const [homeTranslation, setHomeTranslation] = useState("");
  const { authState } = useOktaAuth();
  const [value, setValue] = useState("secure");
  const [client, setClient] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ciamRedirectUrl, setCiamRedirectUrl] = useState("");

  const classes = useStyles();
  const radioHandler = (e: any) => {
    setValue(e.target.value);
    if (authState?.isAuthenticated && value === "email") {
      removeEmailMfa();
    } else if (authState?.isAuthenticated && value === "secure") {
      addEmailMfa();
    }
  };

  let configOperation = {
    headers: {
      Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
    },
    withCredentials: true,
  };

  let configHeaders = {
    headers: {
      Authorization: `Bearer ${authState.accessToken.accessToken}`,
    },
  };

  let query = useQuery();

  const checkUrl = () => {
    const url = window.location.href;
    setIsLoading(true);

    setTimeout(() => {
      if (url.startsWith("https://ciam.epo.org/")) {
        handleOptionProd();
      } else if (url.startsWith("https://ciam-o.epo.org/")) {
        handleOptionOSA();
      } else {
        handleOptionDev();
      }
    }, BUTTON_DELAY);
  };

  const addEmailMfa = async () => {
    await axios.post(
      config.api.baseUrl +
        "/users/" +
        authState.idToken.claims.sub +
        "/choseEmailMfa?enabled=true",
      {
        factorType: "email",
        provider: "OKTA",
        profile: {
          email: client.email,
        },
      },
      configHeaders
    );
  };

  const removeEmailMfa = async () => {
    await axios.post(
      config.api.baseUrl +
        "/users/" +
        authState.idToken.claims.sub +
        "/choseEmailMfa?enabled=false",
      {
        factorType: "email",
        provider: "OKTA",
        profile: {
          email: client.email,
        },
      },
      configHeaders
    );
  };

  const redirect = () => {
    if (authState?.isAuthenticated) {
      history.push("/account");
    } else {
      history.push("/login");
    }
  };

  const handleOptionDev = () => {
    if (client.locale === "de_DE") {
      window.location.replace(
        `https://ciam-i.epo.org/success/reg-completed-i_DE.html?login=${client?.email}&ciam_redirect=${ciamRedirectUrl}`
      );
    } else if (client.locale === "fr_FR") {
      window.location.replace(
        `https://ciam-i.epo.org/success/reg-completed-i_FR.html?login=${client?.email}&ciam_redirect=${ciamRedirectUrl}`
      );
    } else {
      window.location.replace(
        `https://ciam-i.epo.org/success/reg-completed-i_EN.html?login=${client?.email}&ciam_redirect=${ciamRedirectUrl}`
      );
    }
  };

  const handleOptionOSA = () => {
    if (authState?.isAuthenticated) {
      if (client.locale === "de_DE") {
        window.location.replace(
          `https://ciam-o.epo.org/success/reg-completed-o_DE.html?login=${client?.email}&ciam_redirect=${ciamRedirectUrl}`
        );
      } else if (client.locale === "fr_FR") {
        window.location.replace(
          `https://ciam-o.epo.org/success/reg-completed-o_FR.html?login=${client?.email}&ciam_redirect=${ciamRedirectUrl}`
        );
      } else {
        window.location.replace(
          `https://ciam-o.epo.org/success/reg-completed-o_EN.html?login=${client?.email}&ciam_redirect=${ciamRedirectUrl}`
        );
      }
    }
  };

  const handleOptionProd = () => {
    if (authState?.isAuthenticated) {
      if (client.locale === "de_DE") {
        window.location.replace(
          `https://ciam.epo.org/success/reg-completed_DE.html?login=${client?.email}&ciam_redirect=${ciamRedirectUrl}`
        );
      } else if (client.locale === "fr_FR") {
        window.location.replace(
          `https://ciam.epo.org/success/reg-completed_FR.html?login=${client?.email}&ciam_redirect=${ciamRedirectUrl}`
        );
      } else {
        window.location.replace(
          `https://ciam.epo.org/success/reg-completed_EN.html?login=${client?.email}&ciam_redirect=${ciamRedirectUrl}`
        );
      }
    }
  };

  const getCookie = (cname: string) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  useEffect(() => {
    const r = getCookie("ciam_redirect");

    if (r.length > 0) {
      setCiamRedirectUrl(r);
    }
  }, []);

  useEffect(() => {
    const param = query.get("type_hint");
    if (param === "PASSWORD_RECOVERY") {
      setHomeTranslation("password-recovery");
    } else if (param === "ACTIVATION") {
      setHomeTranslation("activation");
    } else {
      setHomeTranslation("welcome");
    }
    setIsLoading(true);
    getUsers();
    document.title = "EPO – select verification methods";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getUsers() {
    await axios
      .get(
        config.api.baseUrl + "/users/" + authState.idToken.claims.sub,
        configOperation
      )
      .then((response) => {
        setUser(response.data);
        setClient(response.data);
        setLanguage(response.data.preferredLanguage);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  const setLanguage = (preferredLanguage: string) => {
    var locale = "en_US";   
    if (preferredLanguage === "DE") {
      locale = "de_DE";
    } else if (preferredLanguage === "FR") {
      locale = "fr_FR";
    }
    i18n.changeLanguage(locale.substring(0, 2));
  };

  if (isLoading) {
    return (
      <div className={classes.spinnerWrapper}>
        <SpinnerCircular
          data-testid="spinner"
          className={classes.spinner}
          size={45}
          thickness={180}
          speed={100}
          color="#066AE3"
          secondaryColor="#C4C4C4"
        />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <CardWithLogo noLogo>
        <div className="d-flex flex-column align-items-center justify-content-center">
          {homeTranslation === "activation" ? (
            <div>
              <div
                className="mr-n-l"
                style={{ whiteSpace: "break-spaces" }}
              ></div>
              <div className={" m-sm-m text-center"}>
                <svg
                  width="16"
                  height="16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 7.992C16 3.58 12.416 0 8 0S0 3.58 0 7.992c0 2.43 1.104 4.62 2.832 6.09.016.016.032.016.032.032.144.112.288.224.448.336.08.048.144.111.224.175a7.98 7.98 0 0 0 8.96 0c.08-.048.144-.111.224-.16.144-.111.304-.223.448-.335.016-.016.032-.016.032-.032 1.696-1.487 2.8-3.676 2.8-6.106Zm-8 7.001c-1.504 0-2.88-.48-4.016-1.279.016-.128.048-.255.08-.383a4.17 4.17 0 0 1 .416-.991c.176-.304.384-.576.64-.816.24-.24.528-.463.816-.639.304-.176.624-.304.976-.4A4.15 4.15 0 0 1 8 10.342a4.185 4.185 0 0 1 2.928 1.166c.368.368.656.8.864 1.295.112.288.192.592.24.911A7.03 7.03 0 0 1 8 14.993Zm-2.448-7.4a2.49 2.49 0 0 1-.208-1.024c0-.351.064-.703.208-1.023.144-.32.336-.607.576-.847.24-.24.528-.431.848-.575.32-.144.672-.208 1.024-.208.368 0 .704.064 1.024.208.32.144.608.336.848.575.24.24.432.528.576.847.144.32.208.672.208 1.023 0 .368-.064.704-.208 1.023a2.841 2.841 0 0 1-.576.848 2.84 2.84 0 0 1-.848.575 2.715 2.715 0 0 1-2.064 0 2.84 2.84 0 0 1-.848-.575 2.526 2.526 0 0 1-.56-.848v.001Zm7.424 5.306c0-.032-.016-.048-.016-.08a5.22 5.22 0 0 0-.688-1.406 4.884 4.884 0 0 0-1.088-1.135 5.21 5.21 0 0 0-1.04-.608c.168-.11.323-.24.464-.383.239-.236.448-.499.624-.784a3.623 3.623 0 0 0 .528-1.934 3.71 3.71 0 0 0-.288-1.47 3.799 3.799 0 0 0-.816-1.199 3.845 3.845 0 0 0-1.2-.8 3.72 3.72 0 0 0-1.472-.287 3.72 3.72 0 0 0-1.472.288 3.63 3.63 0 0 0-1.2.815 3.84 3.84 0 0 0-.8 1.199 3.71 3.71 0 0 0-.288 1.47c0 .352.048.688.144 1.007.096.336.224.64.4.927.16.288.384.544.624.784.144.144.304.271.48.383-.37.166-.72.376-1.04.624-.416.32-.784.703-1.088 1.119-.302.43-.534.904-.688 1.406-.016.032-.016.064-.016.08C1.776 11.636.992 9.91.992 7.992.992 4.14 4.144.991 8 .991s7.008 3.149 7.008 7.001a6.96 6.96 0 0 1-2.032 4.907Z"
                    fill="#5E5E5E"
                  />
                </svg>
                {" " + client?.email}
              </div>

              <div className={"text-center"}>
                <h1 className="mt-s">
                  {t("VIEWS.REGISTRATION.verification-methods")}
                </h1>
              </div>
              <div className={"text-center"}>
                <h5>{t("VIEWS.REGISTRATION.two-step")}</h5>
              </div>

              <div className="ml-l">
                <RadioGroup
                  style={{ zIndex: 999 }}
                  name="option"
                  value={value}
                  onChange={radioHandler}
                >
                  <Radio className="w-100 mb" value="secure">
                    <strong>{t("VIEWS.REGISTRATION.most-secure")}</strong>
                    {t("VIEWS.REGISTRATION.verify-secure")}
                  </Radio>
                  <div className="flex mb-m ml-l">
                    <li>{t("VIEWS.REGISTRATION.okta-verify")}</li>
                    <li>{t("VIEWS.REGISTRATION.google-authenticator")}</li>
                    <li>{t("VIEWS.REGISTRATION.sms-verify")}</li>
                  </div>
                  <Radio className="w-100 mb-m" value="email">
                    {t("VIEWS.REGISTRATION.verify-email")}
                  </Radio>
                </RadioGroup>
              </div>
              <div className={"text-center"}>
                {t("VIEWS.REGISTRATION.footer-message-begin")}
                <strong>{t("VIEWS.REGISTRATION.open-account-settings")}</strong>
                {t("VIEWS.REGISTRATION.footer-message-end")}                
              </div>
              <div className="text-center mt-l p-m">
                <Button data-testid="goto-homepage" onClick={checkUrl}>
                  {t("VIEWS.REGISTRATION.set-up-verification-methods")}
                </Button>
              </div>
            </div>
          ) : null}

          {homeTranslation === "password-recovery" ? (
            <div>
              <h3 className="mt-s">{t(`VIEWS.WELCOME.password-recovery`)}</h3>
            </div>
          ) : null}

          {homeTranslation === "welcome" ? (
            <div>
              <h3 className="mt-s">{t(`VIEWS.WELCOME.welcome`)}</h3>
            </div>
          ) : null}

          {homeTranslation === "welcome" ? (
            <div className="my-m p-m">
              <Button
                data-testid={"homepage-btn"}
                onClick={() => window.location.replace("https://www.epo.org/")}
              >
                {t("VIEWS.HOME.goto-homepage")}
              </Button>
              <Button
                data-testid={"login-btn"}
                theme="tertiary"
                className="ml-m"
                onClick={redirect}
              >
                {t("VIEWS.HOME.log-into-your-account")}
              </Button>
            </div>
          ) : null}
        </div>
      </CardWithLogo>
    </div>
  );
};
export default Welcome;
