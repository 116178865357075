import {
  Button,
  FormControl,
  Input,
  Link,
} from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useContext,  useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { config } from "../../../../config/config";
import { ChangeEmailContext } from "../../../../context/user-context";
import CardWithLogo from "../../../common/cardwidthlogo/CardWithLogo";
import Notification from "../../../common/notification/Notification";
import { useStyles } from "./styles";
import Warning from "../../../common/warning/dialogWarning";
import { ErrorFallback, logError } from "../../../errorHandling";

const ConfirmEmail = withErrorBoundary(
  () => {
    const { authState } = useOktaAuth();
    let history = useHistory();
    let configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    const [t] = useTranslation("global");
    const classes = useStyles();
    const [feedback, setFeedback] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [invalidCode, setInvalidCode] = useState(false);
    const [code, setCode] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const redirect = (path: string) => {
      history.push("/" + path);
    };

    const { changeEmailUser, setChangeEmailUser } =
      useContext(ChangeEmailContext);

      const checkChangeEmailTime = () => {
        const loginTime = localStorage.getItem("loginTime");
        const loginTimeMs = parseInt(loginTime, 10);
        const currentTime = new Date().getTime();
        const elapsedTimeMinutes = (currentTime - loginTimeMs) / (1000 * 60);
        if (elapsedTimeMinutes >= 15) {
          setIsOpen(true);
        } else {
          handleSubmit()
        }
      };

    const resendCode = (e : React.FormEvent) => {
      e.preventDefault()
      if (!authState?.isAuthenticated) {
        return null;
      } else  {
        axios
          .post(
            config.api.baseUrl + "/account/emails",
            {
              email: changeEmailUser.email,
              emailVerify: changeEmailUser.email,
            },
            configHeaders
          )
          .then(async (response) => {
            if (response.status === 200) {
              setChangeEmailUser({
                id: response.data.id,
                expiresAt: response.data.expiresAt,
                email: response.data.profile.email,
                emailId: response.data.profile.emailId,
              });
            }
          })
          .catch((error) => {
            if (error.response.status === 429) {
              setErrorMessage(t("VIEWS.MFA.phone-already-sent-error-message"));
            } else {
              setErrorMessage(t("COMMON.generic-error"));
            }
            setFeedback(true);
          })
      }
    };

    const handleSubmit = async () => {
      if (code) {
        setSubmitted(true);
        axios
          .post(
            config.api.baseUrl +
            "/account/emails/" +
            changeEmailUser.emailId +
              "/challenge/" +
              changeEmailUser.id +
              "/verify",
            {
              verificationCode: parseInt(code),
            },
            configHeaders
          )
          .then(async (response) => {
            if (
              response.status === 200
            ) {
              redirect("confirmation-email");
            }
          })
          .catch((error) => {
            setFeedback(false);
            if (
              error.response.status === 403 ||
              error.response.status === 400
            ) {
              setInvalidCode(true);
            } else {
              setFeedback(true);
              setErrorMessage(t("COMMON.generic-error"));
            }
          });
      }
    };

    return (
      <>
        {feedback && <Notification text={errorMessage} theme="negative" />}
        <CardWithLogo noLogo centered withNavbar>
          <h1>{t("VIEWS.CHANGE-EMAIL.verify-using-email-code")}</h1>

          {t(`VIEWS.CHANGE-EMAIL.enter-code-you-received-email`)} <strong>{changeEmailUser.email}</strong>
   

          <FormControl
            label-props={{ style: { display: "none" } }}
            caption={
              (submitted && !code) || invalidCode
                ? t("VIEWS.CHANGE-EMAIL.enter-valid-code")
                : null
            }
            error={(submitted && !code) || invalidCode ? true : false}
          >
            <Input
              className={classes.width180 + " mt-s"}
              value={code}
              clearable
              onChange={(e) => [
                setFeedback(false),
                setCode(e.target.value),
                setInvalidCode(false),
              ]}
              onBlur={() => {
                if (!code) {
                  setInvalidCode(true);
                }
              }}
              data-testid="code-input"
            />
          </FormControl>

         <p>
            {t("VIEWS.CHANGE-EMAIL.havent-receive-email")}{" "}
            <Link data-testid="scan-qr-link" onClick={resendCode}>
              {t("VIEWS.CHANGE-EMAIL.request-email-code")}
            </Link>
          </p>

          <div className="row mt-xl">
            <div className="col text-right">
              <Button
                theme="secondary"
                data-testid="back-btn"
                onClick={() => redirect("change-email")}
              >
                {t("VIEWS.CHANGE-EMAIL.back")}
              </Button>
              <Button
                type="submit"
                data-testid="submit"
                className="ml-m"
                onClick={() => checkChangeEmailTime()}
              >
                {t("VIEWS.CHANGE-EMAIL.verify")}
              </Button>
            </div>
          </div>
        </CardWithLogo>
        <Warning
          header={t("VIEWS.CHANGE-EMAIL.warning-header")}
          body={t("VIEWS.CHANGE-EMAIL.warning-body")}
          close={t("VIEWS.CHANGE-EMAIL.warning-close")}
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
        />
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = {componentStack: "EnterCode"}
      return logError(error, info);
    },
  }
);

export default ConfirmEmail;
