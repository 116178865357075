import { Button, Radio, RadioGroup } from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { config } from "../../config/config";
import { useMigrated } from "../../context/user-context";
import CardWithLogo from "../common/cardwidthlogo/CardWithLogo";
import Notification from "../common/notification/Notification";
import { SpinnerCircular } from "../common/spinner/Spinner";
import { ErrorFallback, logError } from "../errorHandling";
export interface Option {
  readonly id?: string | number;
  readonly label?: React.ReactNode;
  readonly disabled?: boolean;
  readonly clearableValue?: boolean;
  readonly isCreatable?: boolean;
  readonly __optgroup?: string;
  [others: string]: any;
}

const Unlock = withErrorBoundary(
  () => {
    const [t] = useTranslation("global");
    const { authState } = useOktaAuth();
    const configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    const formatPhoneNumber = (phoneNumber) => {
      const countryCode = phoneNumber.slice(0, 4);
      const restOfNumber = phoneNumber.slice(4);
      const formattedNumber = restOfNumber.replace(
        /\d{1,3}(?=(\d{3})+(?!\d))/g,
        "XXX "
      );
      return `${countryCode} ${formattedNumber}`;
    }; 

    const [options, setOptions] = useState<
      { label: string; id: string; path: string }[]
    >([
      {
        label: "",
        id: "",
        path: "",
      },
    ]);

    const [feedback, setFeedback] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [value, setValue] = useState("email");
    const [oktaTOTP, setOktaTOTP] = useState("");
    const radioHandler = (e: any) => {
      setValue(e.target.value);
    };

    const history = useHistory();
    const { migratedUser } = useMigrated();

    const redirect = (path: string, state: {}) => {
      history.push({
        pathname: "/" + path,
        state: { factorId: state, oktaTokenFactorId: oktaTOTP },
      });
    };

    useEffect(() => {
      axios
        .get(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors",
          configHeaders
        )
        .then((response) => {
          setDropdownOptions(response.data);
          setIsLoading(false);
        })
        .catch(() => {
          setFeedback(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.idToken.claims.sub, authState.accessToken.accessToken]);

    const handleSend = () => {
      const option = options.find((opt) => opt.id === value);
      if (option) {
        redirect(option.path, option.id);
      } else {
        setFeedback(true);
        setErrorMessage(t("COMMON.VALIDATION.fill-all-required"));
      }
    };

    const setDropdownOptions = (factors) => {

      let hasPhone = false;

      let optionsArray = [];
      factors.forEach((factor) => {
        if (factor.status === "ACTIVE") {
          if (
            factor.factorType === "token:software:totp" &&
            factor.provider === "OKTA"
          ) {
            setOktaTOTP(factor.id);
          }
          if (
            !(
              factor.factorType === "token:software:totp" &&
              factor.provider === "OKTA"
            )
          ) {
            if (
              factor.factorType === "token:software:totp" &&
              factor.provider === "GOOGLE"
            ) {
              optionsArray.push({
                label: t("VIEWS.SELF-SERVICE.google-authenticator"),
                id: factor.id,
                path: "google-authenticator-auth",
              });
            } else if (factor.factorType === "webauthn") {
              optionsArray.push({
                label:
                  factor?.profile?.authenticatorName ||
                  t("VIEWS.UNLOCK.authenticator"),
                id: factor.id,
                path: factor.factorType + "-" + factor.provider + "-auth",
              });

            } else if (factor.factorType === "sms" || factor.factorType === "call") {
              
              if (!hasPhone) {            
              hasPhone = true;
              optionsArray.push({
                label:
                  t("VIEWS.UNLOCK.authenticator-phone")+ "  (" + formatPhoneNumber(factor.profile.phoneNumber) + ")",
                id: factor.id,                
                path: "phone-select-method",
              });
            };
            
            } else if (factor.factorType !== "signed_nonce"){
              optionsArray.push({
                label: t(
                  "VIEWS.SELF-SERVICE." +
                    factor.factorType +
                    "-" +
                    factor.provider
                ),
                id: factor.id,
                path: factor.factorType + "-" + factor.provider + "-auth",
              });
            }
          }
        }
      });
      setOptions(optionsArray);
    };

    return (
      <>
        {feedback ? (
          <Notification text={errorMessage} theme="negative" />
        ) : null}
        <CardWithLogo noLogo centered withNavbar>
          <h1>{t("VIEWS.UNLOCK.select-mfa-method")}</h1>

          {isLoading ? (
            <div className="text-center">
              <SpinnerCircular
                size={45}
                thickness={180}
                speed={100}
                color="#066AE3"
                secondaryColor="#C4C4C4"
              />
            </div>
          ) : (
            <>
              <RadioGroup
                style={{ zIndex: 999 }}
                name="option"
                value={value}
                onChange={radioHandler}
              >
                {options.map((option) => (
                  <Radio
                  
                    className="w-100 mb-m"
                    value={option.id}
                    data-testid={option.id + "-radio"}
                    key={option.id}
                  >
                    {option.label}
                  </Radio>
              
                ))}
              </RadioGroup>

              <div className="row mt-xl">
                <div className="col text-right">
                  <Button
                    theme="secondary"
                    onClick={() =>
                      migratedUser.migrated
                        ? redirect("migration", {})
                        : redirect("account", {})
                    }
                    data-testid="back-btn"
                  >
                    {t("VIEWS.UNLOCK.back")}
                  </Button>
                  <Button
                    type="submit"
                    data-testid="next-btn"
                    className="ml-m"
                    onClick={handleSend}
                  >
                    {t("VIEWS.UNLOCK.next")}
                  </Button>
                </div>
              </div>
            </>
          )}
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = {componentStack: "EnterCode"}
      return logError(error, info);
    },
  }
);

export default Unlock;
