import { Button, Input, FormControl } from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { config } from "../../../config/config";
import { EMAIL_REGEX } from "../../../constants";
import { ChangeEmailContext, useUser } from "../../../context/user-context";
import CardWithLogo from "../../common/cardwidthlogo/CardWithLogo";
import Notification from "../../common/notification/Notification";
import Warning from "../../common/warning/dialogWarning";
import { ErrorFallback, logError } from "../../errorHandling";

const ChangeEmail = withErrorBoundary(
  () => {
    const { authState } = useOktaAuth();
    let history = useHistory();
    let configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    const { setChangeEmailUser } = useContext(ChangeEmailContext);

    const [t] = useTranslation("global");

    const [feedback, setFeedback] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [email, setEmail] = useState("");
    const [emailVerification, setEmailVerification] = useState("");
    const [emailHasError, setEmailHasError] = useState(false);
    const [emailIsCorrect, setEmailIsCorrect] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [emailVerificationHasError, setEmailVerificationHasError] =
      useState(false);
    const [emailVerificationIsCorrect, setEmailVerificationIsCorrect] =
      useState(false);
    const [emailVerificationErrorMessage, setEmailVerificationErrorMessage] =
      useState("");
    const [emailVerificationTouched, setEmailVerificationTouched] =
      useState(false);
    const { user } = useUser();

    const emailCharacterCondition = EMAIL_REGEX.test(email);

    const redirect = (path: string, state?) => {
      const location = {
        pathname: `/${path}`,
        state,
      };
      history.push(location);
    };

    useEffect(() => {
      if (!user.email) {
        redirect("account");
      } else {
        logOpenChangeEmailPage();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkChangeEmailTime = () => {
      const loginTime = localStorage.getItem("loginTime");
      const loginTimeMs = parseInt(loginTime, 10);
      const currentTime = new Date().getTime();
      const elapsedTimeMinutes = (currentTime - loginTimeMs) / (1000 * 60);
      if (elapsedTimeMinutes >= 15) {
        setIsOpen(true);
      } else {
        handleSubmit()
      }
    };

    const logOpenChangeEmailPage = () => {
      axios.post(
        config.api.baseUrl +
          "/logs/" +
          authState.idToken.claims.sub +
          "/log/info",
        {
          msg: "Opening Change Email page",
          component: "ChangeEmail",
          client: "CIAM APP",
          loginName: user?.login,
          emailAddress: user?.email,
          epolinenumber: user?.epolineNumber,
        },
        configHeaders
      );
    };

    const validateEmailField = () => {
      setEmailIsCorrect(false);
      setEmailHasError(false);
      setEmailErrorMessage("");
      if (!email) {
        setEmailHasError(true);
        setEmailErrorMessage(t("VIEWS.CHANGE-EMAIL.email-mandatory-field"));
      } else if (!emailCharacterCondition) {
        setEmailHasError(true);
        setEmailErrorMessage(t("VIEWS.CHANGE-EMAIL.email-format-condition"));
      } else {
        setEmailIsCorrect(true);
      }
      validateEmailVerificationField(false);
    };

    const validateEmailVerificationField = (isBlur: boolean) => {
      setEmailVerificationIsCorrect(false);
      setEmailVerificationHasError(false);
      setEmailVerificationErrorMessage("");
      if (emailVerification) {
        if (email !== emailVerification) {
          setEmailVerificationHasError(true);
          setEmailVerificationErrorMessage(
            t("VIEWS.CHANGE-EMAIL.email-match-condition")
          );
        } else {
          setEmailVerificationIsCorrect(true);
        }
        /**
         * Check empty verification email
         * only triggers if field has been touched
         * to prevent error to show when checking
         * on email field check
         */
      } else if (isBlur || emailVerificationTouched) {
        setEmailVerificationTouched(true);
        setEmailVerificationHasError(true);
        setEmailVerificationErrorMessage(
          t("VIEWS.CHANGE-EMAIL.email-validation-mandatory-field")
        );
      }
    };

    const checkFormValidity = (): boolean => {
      validateEmailField();
      validateEmailVerificationField(true);
      return emailIsCorrect && emailVerificationIsCorrect;
    };

    const handleSubmit = () => {
      setFeedback(false)
      if (!authState?.isAuthenticated) {
        return null;
      } else if (checkFormValidity()) {
        axios
          .post(
            config.api.baseUrl + "/account/emails",
            {
              email: email,
              emailVerify: emailVerification,
            },
            configHeaders
          )
          .then(async (response) => {
            if (response.status === 200) {
              setChangeEmailUser({
                id: response.data.id,
                expiresAt: response.data.expiresAt,
                email: response.data.profile.email,
                emailId: response.data.profile.emailId,
              });
              redirect("confirm-email", { fromEmailChange: true });
            }
          })
          .catch((error) => {
            if (error.response?.data?.errorCauses) {
              setErrorMessage(
                JSON.stringify(error.response.data.errorCauses)
                  .replaceAll("errorSummary", "")
                  .replaceAll("{", "")
                  .replaceAll("}", "")
                  .replaceAll("[", "")
                  .replaceAll("]", "")
                  .replaceAll(":", "")
                  .replaceAll('"', "")
                  .replace("email", "")
              );
              setFeedback(true);
            } else if (error.response?.data?.errors) {
              setErrorMessage(
                JSON.stringify(error.response.data.errors[0])
                  .replaceAll("errorSummary", "")
                  .replaceAll("{", "")
                  .replaceAll("}", "")
                  .replaceAll("[", "")
                  .replaceAll("]", "")
                  .replaceAll(":", "")
                  .replaceAll('"', "")
                  .replace("email", "")
              );
              setFeedback(true);
            } else if (error.response.status === 409) {
              setErrorMessage(t("VIEWS.CHANGE-EMAIL.email-already-registered"));
              setFeedback(true);
            } else {
              setErrorMessage(t("COMMON.generic-error"));
              setFeedback(true);
            }
          });
      }
    };

    const onPaste = useCallback((e) => e.preventDefault(), []);

    return (
      <>
        {feedback && <Notification text={errorMessage} theme="negative" />}
        <CardWithLogo centered noLogo withNavbar>
          <h1>{t("VIEWS.CHANGE-EMAIL.change-your-email-address")}</h1>
          <div className="mt-xl">
            <FormControl
              label={t("VIEWS.CHANGE-EMAIL.new-email-address")}
              caption={emailErrorMessage}
              error={emailHasError}
            >
              <Input
                clearable
                value={email}
                onChange={(e: any) => {
                  setEmail(e.target.value);
                  setFeedback(false);
                }}
                onBlur={() => validateEmailField()}
                data-testid="email-input"
              />
            </FormControl>

            <FormControl
              label={t("VIEWS.CHANGE-EMAIL.repeat-new-email-address")}
              caption={emailVerificationErrorMessage}
              error={emailVerificationHasError}
            >
              <Input
                clearable
                value={emailVerification}
                onChange={(e: any) => {
                  setEmailVerification(e.target.value);
                  setFeedback(false);
                }}
                onPaste={onPaste}
                onBlur={() => validateEmailVerificationField(true)}
                data-testid="email-verify-input"
              />
            </FormControl>
          </div>

          <div className="row mt-xl">
            <div className="col text-right">
              <Button
                theme="secondary"
                data-testid="cancel-btn"
                onClick={() => redirect("account")}
              >
                {t("VIEWS.CHANGE-EMAIL.cancel")}
              </Button>
              <Button
                type="submit"
                data-testid="save-changes"
                className="ml-m"
                onClick={() => checkChangeEmailTime()}
              >
                {t("VIEWS.CHANGE-EMAIL.save-changes")}
              </Button>
            </div>
          </div>
        </CardWithLogo>
        <Warning
          header={t("VIEWS.CHANGE-EMAIL.warning-header")}
          body={t("VIEWS.CHANGE-EMAIL.warning-body")}
          close={t("VIEWS.CHANGE-EMAIL.warning-close")}
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
        />
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = {componentStack: "EnterCode"}
      return logError(error, info);
    },
  }
);

export default ChangeEmail;
