import { Button, Card } from "@epo/epods-react-components";
import { Error } from "@epo/epods-react-components/lib/Icons/Error";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";
import { ErrorFallback, logError } from "../../errorHandling";

const RegistrationError = withErrorBoundary(
  () => {
    const [t] = useTranslation("global");
    const classes = useStyles();
    let history = useHistory();

    const redirect = (path: string) => {
      history.push("/" + path);
    };

    return (
      <div className="container">
        <div className={classes.wrapper + " row align-items-center"}>
          <div className="col">
            <h1 className={"pl-l"}>
              {t("VIEWS.REGISTRATION.account-registration")}
            </h1>
            <Card hideHeader>
              <div className="m-sm-m">
                <div
                  className={classes.errorIconContainer + " m-sm-m text-center"}
                >
                  <div className={classes.icon}>
                    <Error />
                  </div>
                </div>
                <div className="m-sm-m text-center" data-qa="errorLabel">
                  <h2>
                    {t("VIEWS.REGISTRATION.MAILBOX.email-already-registered")}
                  </h2>
                </div>
                <div className="m-sm-m text-center">
                  <p>
                    {t(
                      "VIEWS.REGISTRATION.MAILBOX.email-already-registered-redirect"
                    )}
                  </p>
                </div>
                <Button
                  theme="secondary"
                  data-testid="go-back-registration"
                  onClick={() => redirect("registration")}
                >
                  {t("VIEWS.REGISTRATION.MAILBOX.go-back-registration")}
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = {componentStack: "EnterCode"}
      return logError(error, info);
    },
  }
);

export default RegistrationError;
