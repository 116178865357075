import { Button } from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CryptoUtil from "../../mfa/webauthn-verify/CryptoUtil";
import { useMigrated, useUnlocked } from "../../../context/user-context";
import { withErrorBoundary } from "react-error-boundary";
import { config } from "../../../config/config";
import CardWithLogo from "../../common/cardwidthlogo/CardWithLogo";
import Notification from "../../common/notification/Notification";
import { ErrorFallback, logError } from "../../errorHandling";

const WebauthnFidoAuth = withErrorBoundary(
  () => {
    const { authState } = useOktaAuth();
    let history = useHistory();
    let configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    const [t] = useTranslation("global");
    const [feedback, setFeedback] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { setUnlocked } = useUnlocked();
    const { migratedUser } = useMigrated();
    const { factorId } = (history.location && history.location.state) || {};

    const redirect = (path: string) => {
      history.push("/" + path);
    };

    useEffect(() => {
      axios
        .post(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors/" +
            factorId +
            "/verify",
          {},
          configHeaders
        )
        .then(async (response) => {
          let credentialId: string;
          let obj: CredentialRequestOptions = response.data._embedded;

          obj.publicKey = response.data._embedded.challenge;

          credentialId = response.data._embedded.enrolledFactors.find(
            (factor) => factor.id === factorId
          ).profile.credentialId;

          // Failsafe in case _embedded object changes from OKTA
          if (!credentialId) {
            credentialId = (await getFactorInfo()).data.profile.credentialId;
          }

          obj.publicKey.allowCredentials = [
            {
              type: "public-key",
              id: CryptoUtil.strToBin(credentialId),
            },
          ];
          obj.publicKey.challenge = CryptoUtil.strToBin(
            response.data._embedded.challenge.challenge
          );

          const myHost = window.location.hostname;
          if (myHost.includes("localhost")) {
            obj.publicKey.rpId = "localhost";
          } else {
            obj.publicKey.rpId = myHost;
          }

          if (authState.idToken.claims.email === "webauthn.test@test.dum") {
            obj.publicKey.rpId = "epo.org";
          }

          navigator.credentials
            .get(obj)
            .then((assertion: any) => {
              // Get the client data, authenticator data, and signature data from callback result, convert from binary to string
              const clientData = CryptoUtil.binToStr(
                assertion.response.clientDataJSON
              );
              const authenticatorData = CryptoUtil.binToStr(
                assertion.response.authenticatorData
              );
              const signatureData = CryptoUtil.binToStr(
                assertion.response.signature
              );

              axios
                .post(
                  config.api.baseUrl +
                    "/users/" +
                    authState.idToken.claims.sub +
                    "/factors/" +
                    factorId +
                    "/verify_webauth",
                  {
                    clientData: clientData,
                    authenticatorData: authenticatorData,
                    signatureData: signatureData,
                  },
                  configHeaders
                )
                .then((res) => {
                  if (
                    res.status === 200 &&
                    res.data.factorResult === "SUCCESS"
                  ) {
                    setUnlocked(true);
                    if (migratedUser.migrated) {
                      redirect("migration");
                    } else {
                      redirect("account");
                    }
                  }
                })
                .catch(() => {
                  setFeedback(true);
                  setErrorMessage(t("COMMON.generic-error"));
                });
            })
            .catch((error) => {
              setFeedback(true);
              setErrorMessage(t("COMMON.generic-error"));
            });
        })
        .catch(() => {
          setFeedback(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFactorInfo = () => {
      return axios.get(
        config.api.baseUrl +
          "/users/" +
          authState.idToken.claims.sub +
          "/factors/" +
          factorId,
        configHeaders
      );
    };

    return (
      <>
        {feedback ? (
          <Notification text={errorMessage} theme="negative" />
        ) : null}
        <CardWithLogo noLogo centered withNavbar>
          <h1>{t("VIEWS.UNLOCK.follow-the-instructions")}</h1>

          <p>{t("VIEWS.UNLOCK.new-window-provide-instructions")}</p>

          <div className="row mt-xl pt-xl">
            <div className="col text-right">
              <Button
                theme="secondary"
                data-testid="back-btn"
                onClick={() => redirect("unlock")}
              >
                {t("VIEWS.UNLOCK.back")}
              </Button>
            </div>
          </div>
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = { componentStack: "EnterCode" };
      return logError(error, info);
    },
  }
);

export default WebauthnFidoAuth;
