import { Button, Link } from "@epo/epods-react-components";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CardWithLogo from "../../../common/cardwidthlogo/CardWithLogo";
import { ErrorFallback, logError } from '../../../errorHandling';

const GoogleCantScanQr = withErrorBoundary(
  () => {
    const [t] = useTranslation("global");
    const history = useHistory();

    const redirect = (path: string) => {
      history.push("/" + path);
    };
    const { sharedSecret } = (history.location && history.location.state) || {};

    return (
      <CardWithLogo centered noLogo withNavbar>
        <h1>{t("VIEWS.MFA.enter-setup-key")}</h1>

        <p>{t("VIEWS.MFA.before-clicking-next")}</p>
        <p>{t("VIEWS.MFA.step1-launch-google-authenticator")}</p>
        <p>{t("VIEWS.MFA.step2-tab-plus-icon", { plusIcon: "+" })}</p>
        <p>
          {t("VIEWS.MFA.step3-enter-setup-key", {
            enterSetupKey: t("VIEWS.MFA.enter-setup-key"),
          })}
        </p>
        <p>{t("VIEWS.MFA.step4-enter-your-username")}</p>

        <h1>{sharedSecret}</h1>

        <p>
          {t("VIEWS.MFA.prefer-scan")}{" "}
          <Link onClick={() => redirect("MFA/google-verify/scan-qr")}
            data-testid='scan-qr'>
            {t("VIEWS.MFA.scan-qr")}
          </Link>
        </p>

        <div className="row mt-xl">
          <div className="col text-right">
            <Button
              theme="secondary"
              onClick={() => redirect("MFA/google-verify/scan-qr")}
              name="backFactor"
              data-testid='back-btn'
            >
              {t("VIEWS.MFA.back")}
            </Button>
            <Button
              type="submit"
              className="ml-m"
              onClick={() => redirect("MFA/google-enter-code")}
              name="next"
              data-testid='next-btn'
            >
              {t("VIEWS.MFA.next")}
            </Button>
          </div>
        </div>
      </CardWithLogo>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError: logError,
  }
);

export default GoogleCantScanQr;
