import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  inputWrapper: {
    marginBottom: "25px",
  },
  p: {
    marginBottom: "var(--epods--space-inset-s)",
    float: "left",
    fontWeight: "bold",
  },
  createAccountTitle: {
    marginTop: "0px",
  },
  registerLabel: {
    marginBottom: "var(--epods--space-inset-l)",
  },
  dropdown: {
    width: "150px",
  },
  button: {
    float: "left",
    paddingTop: "16px",
  },
  spinner: {
    marginBottom: "var(--epods--space-inset-m)",
    color: "var(--epods--color-primary)",
    marginTop: "128px",
  },
  width80: {
    width: "80%",
  },
  checkbox: {
    paddingTop: "16px",
    // Overflow of terms in German
    "& > label": {
      "& > div": {
        overflow: "visible",
      },
    },
  },
  "@media (max-width: 700px)": {
    width80: {
      width: "100%",
    },
  },
  feedbackMessage: {
    position: "absolute",
    // Dropdown z-index is 1000
    zIndex: 1001,
    right: "20px",
  },
});
