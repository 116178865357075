import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  left: {
    alignItems: "center",
    display: "flex",
  },
  link: {
    marginRight: "10px",
  },
  logo: {
    height: "60px",
  },
  navBar: {
    borderBottom: "1px solid #f2f2f2",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  title: {
    fontWeight: "bold",
    paddingLeft: "10px",
  },
  right: {
    alignItems: "center",
    display: "flex",
    paddingRight: "30px",
    // DS visual bug fix for dropdown
    "& > div": {
      "& > span": {
        "& > div": {
          "& > div": {
            "& > div": {
              background: "transparent",
            },
          },
        },
      },
    },
  },
  profileDropdown: {
    "& i": {
      display: "none",
    },
    "& > button": {
      backgroundColor: "transparent",
      height: "fit-content",
      paddingRight: "0px",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
});
