import {
  Button,
  Checkbox,
  FormControl,
  Input,
  Link,
} from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { config } from "../../../config/config";
import { EMAIL_REGEX, EMAIL_EXCLUSION_REGEX } from "../../../constants";
import { useMigrated, useUser } from "../../../context/user-context";
import CardWithLogo from "../../common/cardwidthlogo/CardWithLogo";
import Notification from "../../common/notification/Notification";
import { SpinnerCircular } from "../../common/spinner/Spinner";
import { useStyles } from "./styles";
import { ErrorFallback, logError } from "../../errorHandling";

const MigrationEmail = withErrorBoundary(
  () => {
    const { authState } = useOktaAuth();
    let configOperation = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    const [t] = useTranslation("global");
    const classes = useStyles();
    const { migratedUser, setMigratedUser } = useMigrated();

    const [feedback, setFeedback] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [emailVerification, setEmailVerification] = useState("");
    const [emailHasError, setEmailHasError] = useState(false);
    const [emailIsCorrect, setEmailIsCorrect] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [emailVerificationHasError, setEmailVerificationHasError] =
      useState(false);
    const [emailVerificationIsCorrect, setEmailVerificationIsCorrect] =
      useState(false);
    const [emailVerificationErrorMessage, setEmailVerificationErrorMessage] =
      useState("");
    const [emailVerificationTouched, setEmailVerificationTouched] =
      useState(false);

    /////Changes
    const { user } = useUser();
    const [terms, setTerms] = useState(false);
    const [termsHasBeenTouched, setTermsTouched] = useState(false);
    const [termsHasError, setTermsHasError] = useState(false);
    const [termsIsCorrect, setTermsIsCorrect] = useState(false);
    const [termsErrorMessage, setTermsErrorMessage] = useState("");

    ///Finish changes


console.log("MIGRATION EMAIL",migratedUser)


    const emailCharacterCondition = EMAIL_REGEX.test(email);
    const emailForbiddenDomain = EMAIL_EXCLUSION_REGEX.test(email);

    const validateEmailField = () => {
      setEmailIsCorrect(false);
      setEmailHasError(false);
      setEmailErrorMessage("");
      if (!email) {
        setEmailHasError(true);
        setEmailErrorMessage(t("VIEWS.MIGRATION.email-mandatory-field"));
      } else if (!emailCharacterCondition) {
        setEmailHasError(true);
        setEmailErrorMessage(t("VIEWS.MIGRATION.email-format-condition"));
      } else if (emailForbiddenDomain) {
        setEmailHasError(true);
        setEmailErrorMessage(
          t("VIEWS.MIGRATION.email-format-forbidden-domain")
        );
      } else {
        setEmailIsCorrect(true);
      }
      validateEmailVerificationField(false);
    };

    const validateEmailVerificationField = (isBlur: boolean) => {
      setEmailVerificationIsCorrect(false);
      setEmailVerificationHasError(false);
      setEmailVerificationErrorMessage("");
      if (emailVerification) {
        if (email !== emailVerification) {
          setEmailVerificationHasError(true);
          setEmailVerificationErrorMessage(
            t("VIEWS.MIGRATION.email-match-condition")
          );
        } else {
          setEmailVerificationIsCorrect(true);
        }
        /**
         * Check empty verification email
         * only triggers if field has been touched
         * to prevent error to show when checking
         * on email field check
         */
      } else if (isBlur || emailVerificationTouched) {
        setEmailVerificationTouched(true);
        setEmailVerificationHasError(true);
        setEmailVerificationErrorMessage(
          t("VIEWS.MIGRATION.email-validation-mandatory-field")
        );
      }
    };

    const setError = (errMessage: string) => {
      setFeedback(true);
      setErrorMessage(t(errMessage));
    };

    const handleEmailSubmit = async (event: any) => {
      if (!authState?.isAuthenticated) {
        return null;
      } else if (checkEmailFormValidity()) {
        setIsLoading(true);
        submitEmailData();
      }
      event.preventDefault();
    };

    const checkEmailFormValidity = (): boolean => {
      isValidForm();
      validateTermsField();
      validateEmailField();
      validateEmailVerificationField(true);
      return emailIsCorrect && emailVerificationIsCorrect && terms;
    };

    const submitEmailData = () => {
      axios
        .post(
          `${config.api.baseUrl}/migrate/${authState.idToken.claims.sub}/smart_card_user/check_email`,
          {
            profile: {
              email: email,
              login: email,
            },
          },
          configOperation
        )
        .then((response) => {
          setIsLoading(false);
          if (response.data) {
            setMigratedUser({
              ...migratedUser,
              isClash: true,
              email: email,
              step: "password_clash",
              terms:terms,
            });
          } else {
            setMigratedUser({
              ...migratedUser,
              isClash: false,
              email: email,
              step: "password",
              terms:terms,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setFeedback(true);
          setError("VIEWS.MIGRATION.generic-error");
        });
    };

    ///// Changes

    useEffect(() => {
      if (termsHasBeenTouched) validateTermsField();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [terms, termsHasBeenTouched]);

    const onTermsChange = () => {
      setTerms(!terms);
      setTermsTouched(true);
    };

    const validateTermsField = () => {
      setTermsErrorMessage("");
      setTermsHasError(false);
      setTermsIsCorrect(false);
      if (!terms) {
        setTermsHasError(true);
        setTermsErrorMessage(t("VIEWS.REGISTRATION.terms-required-condition"));
      } else {
        setTermsIsCorrect(true);
      }
    };

    const navigateToTermsConditions = () => {
      const langId = user.locale;
      if (langId === "en_US") {
        window.open(
          "https://www.epo.org/footer/terms/online-services-electronic-infrastructure.html"
        );
      } else if (langId === "de_DE") {
        window.open(
          "https://www.epo.org/footer/terms/online-services-electronic-infrastructure_de.html"
        );
      } else if (langId === "fr_FR") {
        window.open(
          "https://www.epo.org/footer/terms/online-services-electronic-infrastructure_fr.html"
        );
      }
    };

    const navigateToPrivacyPolicy = () => {
      const langId = user.locale;
      if (langId === "en_US") {
        window.open(
          "https://www.epo.org/about-us/office/data-protection-and-privacy.html"
        );
      } else if (langId === "de_DE") {
        window.open(
          "https://www.epo.org/about-us/office/data-protection-and-privacy_de.html"
        );
      } else if (langId === "fr_FR") {
        window.open(
          "https://www.epo.org/about-us/office/data-protection-and-privacy_fr.html"
        );
      }
    };

    const isValidForm = (): boolean => {
      if (terms) {
        validateTermsField();
        return termsIsCorrect;
      } else {
        validateTermsField()
      }
    };

    ///Fimish changes

    return (
      <>
        {feedback && (
          <Notification
            data-testid="notification"
            text={errorMessage}
            theme="negative"
          />
        )}

        <CardWithLogo centered noLogo withNavbar>
          <>
            <h1>{t("VIEWS.MIGRATION.title-enter-email")}</h1>
            <p>{t("VIEWS.MIGRATION.email-migration-info")}</p>

            {isLoading ? (
              <div className={classes.spinnerWrapper}>
                <SpinnerCircular
                  data-testid="spinner"
                  className={classes.spinner}
                  size={45}
                  thickness={180}
                  speed={100}
                  color="#066AE3"
                  secondaryColor="#C4C4C4"
                />
              </div>
            ) : (
              <form onSubmit={(e) => handleEmailSubmit(e)}>
                <div className={classes.width400}>
                  <FormControl
                    className="mt-l"
                    caption={emailErrorMessage}
                    error={emailHasError}
                    positive={emailIsCorrect}
                    label={
                      <>
                        <span className="semi-bold">
                          {t("VIEWS.MIGRATION.input-label-email")}
                        </span>
                      </>
                    }
                  >
                    <Input
                      name="email"
                      value={email}
                      error={emailHasError}
                      positive={emailIsCorrect}
                      placeholder={t("COMMON.email-placeholder")}
                      aria-label={t("COMMON.email-ariaLabel")}
                      onBlur={() => validateEmailField()}
                      onChange={(e: any) => {
                        setEmail(e.target.value);
                        setFeedback(false);
                      }}
                      data-testid="email"
                    />
                  </FormControl>

                  <FormControl
                    className="mt-l"
                    caption={emailVerificationErrorMessage}
                    error={emailVerificationHasError}
                    positive={emailVerificationIsCorrect}
                    label={
                      <>
                        <span className="semi-bold">
                          {t("VIEWS.MIGRATION.input-label-repeat-email")}
                        </span>
                      </>
                    }
                  >
                    <Input
                      name="emailVerification"
                      value={emailVerification}
                      error={emailVerificationHasError}
                      positive={emailVerificationIsCorrect}
                      placeholder={t("COMMON.email-placeholder")}
                      aria-label={t("COMMON.emailVerification-ariaLabel")}
                      data-testid="email-verification"
                      onBlur={() => validateEmailVerificationField(true)}
                      onChange={(e: any) => {
                        setEmailVerification(e.target.value);
                        setFeedback(false);
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </FormControl>
                
                </div>

                <FormControl
                  caption={termsErrorMessage}
                  error={termsHasError}
                  positive={termsIsCorrect}
                  label={<div></div>}
                >
                  <Checkbox
                    checked={terms}
                    id="terms"
                    name="terms"
                    onChange={onTermsChange}
                    className={classes.checkbox + " mb-xs mr-n-xl"}
                    data-testid="terms-checkbox"
                  >
                    <div style={{ whiteSpace: "break-spaces" }}>
                      {t("VIEWS.MIGRATION.agree")}{" "}
                      <Link
                        data-testid="terms-conditions"
                        onClick={navigateToTermsConditions}
                      >
                        {t("VIEWS.MIGRATION.terms-conditions")}
                      </Link>
                    </div>
                  </Checkbox>
                </FormControl>
                <div className="mr-n-l" style={{ whiteSpace: "break-spaces" }}>
                  {t("VIEWS.REGISTRATION.personal-data")}{" "}
                  <Link
                    data-testid="privacy-policy"
                    onClick={navigateToPrivacyPolicy}
                  >
                    {t("VIEWS.REGISTRATION.privacy-policy")}
                  </Link>
                </div>

                <div className="row mt-xl">
                  <div className="col text-right">
                    <Button
                      type="submit"
                      data-testid="next"
                    >
                      {t("VIEWS.MIGRATION.next")}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </>
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = {componentStack: "EnterCode"}
      return logError(error, info);
    },
  }
);

export default MigrationEmail;
