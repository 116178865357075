import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  icon: {
    marginTop: "40px",
    color: "var(--epods--color-green-500)",
    "@media (max-width: 767px)": {
      marginTop: "20px",
      fontSize: "1.5rem",
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  radioLabel: {
    "& label": {
      alignItems: "flex-start",
    },
    "& label:div": {
      marginTop: 4,
    },
  },
  radio: {
    "@media (max-width: 1175px)": {
      alignItems: "flex-start",
    },
  },
  label: {
    width: "100%",
    "& div": {
      textOverflow: "initial",
      whiteSpace: "pre-wrap",
    },
  },
  label_2: {
    width: "100%",
    "& div": {
      textOverflow: "initial",
      whiteSpace: "pre-wrap",
    },
    marginBottom: 5,
  },
  spinnerWrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  spinner: {
    marginBottom: "var(--epods--space-inset-m)",
    color: "var(--epods--color-primary)",
    marginTop: "32px",
  },
  spinnerText: {
    lineHeight: "30px",
  },
});
