import { Button, Dialog } from "@epo/epods-react-components";
import { DialogFooter } from "@epo/epods-react-components/lib/Components/Dialog";
import { FC } from "react";

interface WarningProps {
    header:string,
    body:string,
    close: string,
    onClose: () => void,
    isOpen: boolean,
}

const Warning:FC<WarningProps> = ({header, body, close, onClose, isOpen}) => (
  <Dialog header={header} isOpen={isOpen} onClose={onClose}>
    <div>{body}</div>
    <DialogFooter>
      <Button onClick={onClose}>{close}</Button>
    </DialogFooter>
  </Dialog>
);

export default Warning;
