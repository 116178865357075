import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  wrapper: {
    maxWidth: "1200px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  top: {
    justifyContent: "space-between",
    padding: "var(--epods--space-inset-m)",
  },
  itemWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "var(--epods--space-inset-m)",
  },
  spinnerWrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  icon: {
    color: "var(--epods--space-inset-m)",
  },
  mfa: {
    textAlign: "left",
  },
  spinner: {
    marginBottom: "var(--epods--space-inset-m)",
    color: "#BE0F05",
    marginTop: "32px",
  },
  spinnerText: {
    lineHeight: "30px",
  },
  cardWrapper: {
    marginTop: "var(--epods--space-inset-m)",
    marginLeft: "auto",
    marginRight: "auto",
  },
  value: {
    marginLeft: "10px",
  },
  alignLeft: {
    textAlign: "left",
  },
  factor: {
    display: "flex",
    alignItems: "center",
  },
  factorDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  pointer: {
    cursor: "pointer",
  },
  errorIconContainer: {
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  webauthnButtons: {
    alignItems: "flex-end",
    display: "flex",
    flexDirection: "column",
  },
  webauthnButton: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "var(--epods--space-inset-m)",
    paddingLeft: "12px",
    width: "100%",
  },
  line: {
    marginTop: "var(--epods--space-inset-l)",
    marginBottom: "var(--epods--space-inset-m)",
    borderBottom: "1px solid #E0E0E0",
    boxShadow: "0px 2px 4px rgba(94, 124, 143, 0.15)",
  },
  semiBold: {
    fontWeight: "600",
  },
  longText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  checkCircle: {
    color: "var(--epods--color-green-500)",
  },
  authName: {
    paddingLeft: '4px',
    paddingTop: '2px'
  },
});
