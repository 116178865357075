import {
  Button,
  Checkbox,
  Dropdown,
  FormControl,
  Input,
  Link,
  Notification,
} from "@epo/epods-react-components";
import { Value } from "@epo/epods-react-components/lib/Components/Dropdown/types";
import { OktaAuth } from "@okta/okta-auth-js";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { config } from "../../config/config";
import {
  EMAIL_REGEX,
  EMAIL_EXCLUSION_REGEX,
  NAME_EXCLUSION_REGEX,
  NAME_MAX_LENGTH,
  NOTIFICATION_CLOSE_TIME,
} from "../../constants";
import FriendlyCaptcha from "../common/friendlycaptcha/FriendlyCaptcha";
import CardWithLogo from "../common/cardwidthlogo/CardWithLogo";
import { SpinnerCircular } from "../common/spinner/Spinner";
import { useStyles } from "./styles";
import { ErrorFallback, logError } from "../errorHandling";

const Registration = withErrorBoundary(
  () => {
    // eslint-disable-next-line no-useless-escape
    const [t, i18n] = useTranslation("global");
    const classes = useStyles();
    let history = useHistory();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [emailVerification, setEmailVerification] = useState("");
    const [terms, setTerms] = useState(false);
    const [termsHasBeenTouched, setTermsTouched] = useState(false);
    const [feedback, setFeedback] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dropDownValue, setValue] = useState<Value>([{ label: "", id: "" }]);
    const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");
    const [firstNameIsCorrect, setFirstNameIsCorrect] = useState(false);
    const [firstNameHasError, setFirstNameHasError] = useState(false);
    const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");
    const [lastNameIsCorrect, setLastNameIsCorrect] = useState(false);
    const [lastNameHasError, setLastNameHasError] = useState(false);
    const [emailHasError, setEmailHasError] = useState(false);
    const [emailIsCorrect, setEmailIsCorrect] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [emailVerificationTouched, setEmailVerificationTouched] =
      useState(false);
    const [emailVerificationHasError, setEmailVerificationHasError] =
      useState(false);
    const [emailVerificationIsCorrect, setEmailVerificationIsCorrect] =
      useState(false);
    const [emailVerificationErrorMessage, setEmailVerificationErrorMessage] =
      useState("");
    const [termsHasError, setTermsHasError] = useState(false);
    const [termsIsCorrect, setTermsIsCorrect] = useState(false);
    const [termsErrorMessage, setTermsErrorMessage] = useState("");
    const [captchaSolution, setCaptchaSolution] = useState("");

    const [preFill, setPrefill] = useState(false);

    const emailCharacterCondition = EMAIL_REGEX.test(email);
    const emailForbiddenDomain = EMAIL_EXCLUSION_REGEX.test(email);

    const language = useMemo(
      () =>
        i18n.language === "en_US" || i18n.language === "en"
          ? "en"
          : i18n.language === "de"
          ? "de"
          : "fr",
      [i18n.language]
    );

    const redirect = (path: string) => {
      history.push("/" + path);
    };

    const handleFirstNameInput = (e) => {
      setFeedback(false);
      setFirstName(e.target.value);
    };

    const handleLastNameInput = (e) => {
      setFeedback(false);
      setLastName(e.target.value);
    };

    const handleEmailInput = (e) => {
      setFeedback(false);
      setEmail(e.target.value);
    };

    const handleEmailVerificationInput = (e) => {
      setFeedback(false);
      setEmailVerification(e.target.value);
    };

    const authClient = new OktaAuth(config.oidc);
    const logout = async () => {
      if (authClient?.isAuthenticated()) {
        await authClient.revokeAccessToken();
        await authClient.closeSession();
      }
    };

    useEffect(() => {
      const queryString = window.location.search;

      const urlParams = new URLSearchParams(queryString);

      if (urlParams.has("ciam_redirect")) {
        const redirectUrl = urlParams.get("ciam_redirect");
        
        document.cookie = `ciam_redirect=${redirectUrl}; SameSite=Lax; path=/;`;

      } else {
        document.cookie =
          "ciam_redirect=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }

      if (urlParams.has("email")) {
        setEmail(urlParams.get("email"));
        setEmailVerification(urlParams.get("email"));
        setPrefill(true);
      }
      if (urlParams.has("firstname")) {
        setFirstName(urlParams.get("firstname"));
        setPrefill(true);
      }
      if (urlParams.has("lastname")) {
        setLastName(urlParams.get("lastname"));
        setPrefill(true);
      }

      logout();
      document.title = t("VIEWS.REGISTRATION.browser-tab-title");
      var userLang = navigator.language.substring(0, 2);
      if (userLang === "fr") {
        setValue([{ label: "Français", id: "fr_FR" }]);
        i18n.changeLanguage(userLang);
      } else if (userLang === "de") {
        setValue([{ label: "Deutsch", id: "de_DE" }]);
        i18n.changeLanguage(userLang);
      } else {
        setValue([{ label: "English", id: "en_US" }]);
        i18n.changeLanguage("en_US");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (lastName.length > 0) {
        validateLastNameField();
      }
      if (firstName.length > 0) {
        validateFirstNameField();
      }
      if (email.length > 0) {
        validateEmailField();
        validateEmailVerificationField(false);
      }

      // eslint-disable-next-line
    }, [preFill]);

    useEffect(() => {
      /**
       * FriendlyCaptcha link change
       */
      const fcLegalLinkEn =
        "https://documents.epo.org/projects/babylon/eponet.nsf/0/86A6EF2106129EEFC12589200051222C/$File/data_protection_statement_processing_personal_data_for_bot_protection_service_en.pdf";
      const fcLegalLinkDe =
        "https://documents.epo.org/projects/babylon/eponet.nsf/0/86A6EF2106129EEFC12589200051222C/$File/data_protection_statement_processing_personal_data_for_bot_protection_service_de.pdf";
      const fcLegalLinkFr =
        "https://documents.epo.org/projects/babylon/eponet.nsf/0/86A6EF2106129EEFC12589200051222C/$File/data_protection_statement_processing_personal_data_for_bot_protection_service_fr.pdf";
      const fcDomElement = document.getElementsByClassName("frc-captcha")[0];
      const mutationObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node["className"] === "frc-banner") {
              const langId = dropDownValue[0].id;
              if (langId === "de_DE") {
                (node.childNodes[0] as HTMLAnchorElement).href = fcLegalLinkDe;
              } else if (langId === "fr_FR") {
                (node.childNodes[0] as HTMLAnchorElement).href = fcLegalLinkFr;
              } else {
                (node.childNodes[0] as HTMLAnchorElement).href = fcLegalLinkEn;
              }
            }
          });
        });
      });
      const mutationConfig = {
        attributes: true,
        childList: true,
        characterData: true,
      };
      mutationObserver.observe(fcDomElement, mutationConfig);
    }, [dropDownValue]);

    useEffect(() => {
      if (termsHasBeenTouched) validateTermsField();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [terms, termsHasBeenTouched]);

    const dropDownChange = (e: any) => {
      setValue(e.value);
      i18n.changeLanguage(e.value[0].id.substring(0, 2));
      document.title = t("VIEWS.REGISTRATION.browser-tab-title");
    };

    const onTermsChange = () => {
      setTerms(!terms);
      setTermsTouched(true);
    };

    const navigateHelpRegister = () => {
      const langId = dropDownValue[0].id;
      if (langId === "en_US") {
        window.open(`${window.location.origin}/help/registration.html`);
      } else if (langId === "de_DE") {
        window.open(`${window.location.origin}/help/registration_de.html`);
      } else if (langId === "fr_FR") {
        window.open(`${window.location.origin}/help/registration_fr.html`);
      }
    };

    const navigateToTermsConditions = () => {
      const langId = dropDownValue[0].id;
      if (langId === "en_US") {
        window.open(
          "https://www.epo.org/footer/terms/online-services-electronic-infrastructure.html"
        );
      } else if (langId === "de_DE") {
        window.open(
          "https://www.epo.org/footer/terms/online-services-electronic-infrastructure_de.html"
        );
      } else if (langId === "fr_FR") {
        window.open(
          "https://www.epo.org/footer/terms/online-services-electronic-infrastructure_fr.html"
        );
      }
    };

    const navigateToPrivacyPolicy = () => {
      const langId = dropDownValue[0].id;
      if (langId === "en_US") {
        window.open(
          "https://www.epo.org/about-us/office/data-protection-and-privacy.html"
        );
      } else if (langId === "de_DE") {
        window.open(
          "https://www.epo.org/about-us/office/data-protection-and-privacy_de.html"
        );
      } else if (langId === "fr_FR") {
        window.open(
          "https://www.epo.org/about-us/office/data-protection-and-privacy_fr.html"
        );
      }
    };

    const validateFirstNameField = () => {
      setFirstNameIsCorrect(false);
      setFirstNameHasError(false);
      setFirstNameErrorMessage("");
      if (!firstName.trim()) {
        setFirstNameHasError(true);
        setFirstNameErrorMessage(
          t("VIEWS.REGISTRATION.first-name-mandatory-field")
        );
      } else if (NAME_EXCLUSION_REGEX.test(firstName)) {
        setFirstNameHasError(true);
        setFirstNameErrorMessage(
          t("VIEWS.REGISTRATION.first-name-format-condition")
        );
      } else {
        setFirstNameIsCorrect(true);
      }
    };

    const validateLastNameField = () => {
      setLastNameIsCorrect(false);
      setLastNameHasError(false);
      setLastNameErrorMessage("");
      if (!lastName.trim()) {
        setLastNameHasError(true);
        setLastNameErrorMessage(
          t("VIEWS.REGISTRATION.last-name-mandatory-field")
        );
      } else if (NAME_EXCLUSION_REGEX.test(lastName)) {
        setLastNameHasError(true);
        setLastNameErrorMessage(
          t("VIEWS.REGISTRATION.last-name-format-condition")
        );
      } else {
        setLastNameIsCorrect(true);
      }
    };

    const validateEmailField = () => {
      setEmailIsCorrect(false);
      setEmailHasError(false);
      setEmailErrorMessage("");
      if (!email) {
        setEmailHasError(true);
        setEmailErrorMessage(t("VIEWS.REGISTRATION.email-mandatory-field"));
      } else if (!emailCharacterCondition) {
        setEmailHasError(true);
        setEmailErrorMessage(t("VIEWS.REGISTRATION.email-format-condition"));
      } else if (emailForbiddenDomain) {
        setEmailHasError(true);
        setEmailErrorMessage(
          t("VIEWS.REGISTRATION.email-format-forbidden-domain")
        );
      } else {
        setEmailIsCorrect(true);
      }
      validateEmailVerificationField(false);
    };

    const validateEmailVerificationField = (isBlur: boolean) => {
      setEmailVerificationIsCorrect(false);
      setEmailVerificationHasError(false);
      setEmailVerificationErrorMessage("");
      if (emailVerification) {
        if (email !== emailVerification) {
          setEmailVerificationHasError(true);
          setEmailVerificationErrorMessage(
            t("VIEWS.REGISTRATION.email-match-condition")
          );
        } else {
          setEmailVerificationIsCorrect(true);
        }
        /**
         * Check empty verification email
         * only triggers if field has been touched
         * to prevent error to show when checking
         * on email field check
         */
      } else if (isBlur || emailVerificationTouched) {
        setEmailVerificationTouched(true);
        setEmailVerificationHasError(true);
        setEmailVerificationErrorMessage(
          t("VIEWS.REGISTRATION.email-validation-mandatory-field")
        );
      }
    };

    const validateTermsField = () => {
      setTermsErrorMessage("");
      setTermsHasError(false);
      setTermsIsCorrect(false);
      if (!terms) {
        setTermsHasError(true);
        setTermsErrorMessage(t("VIEWS.REGISTRATION.terms-required-condition"));
      } else {
        setTermsIsCorrect(true);
      }
    };

    const setError = (errMessage: string) => {
      setFeedback(true);
      setErrorMessage(t(errMessage));
    };

    const handleSubmit = (event: any) => {
      setFeedback(false);
      if (checkFormValidity()) {
        setIsLoading(true);
        submitData(captchaSolution);
      }
      event.preventDefault();
    };

    const checkFormValidity = (): boolean => {
      validateFirstNameField();
      validateLastNameField();
      validateEmailField();
      validateEmailVerificationField(true);
      validateTermsField();

      return (
        firstNameIsCorrect &&
        lastNameIsCorrect &&
        emailIsCorrect &&
        emailVerificationIsCorrect &&
        termsIsCorrect
      );
    };

    const submitData = (token) => {
      axios
        .post(`${config.api.baseUrl}/users/registration`, {
          profile: {
            firstName: firstName,
            lastName: lastName,
            email: email,
            login: email,
            locale: dropDownValue[0].id,
          },
          token: token,
        })
        .then(() => {
          setIsLoading(false);
          redirect(`signup/mailbox/${email}`);
        })
        .catch((error) => {
          setIsLoading(false);
          setFeedback(true);
          if (error.response.status === 409) {
            setError(t("VIEWS.REGISTRATION.email-already-registered-error"));
          } else if (error.response.status === 400) {
            setFeedback(true);
            if (
              error.response.data.errors !== null &&
              !error.response.data.errors?.includes("API_ERROR") &&
              !error.response.data.isHuman
            ) {
              setErrorMessage(t("VIEWS.REGISTRATION.error-bot-message"));
            } else {
              setErrorMessage(t("VIEWS.REGISTRATION.generic-error-message"));
            }
          } else {
            setError("VIEWS.REGISTRATION.generic-error-message");
          }
        });
    };

    const captchaOnDone = (solution) => {
      setCaptchaSolution(solution);
    };

    const captchaOnError = () => {
      setFeedback(true);
      setErrorMessage(t("COMMON.generic-error"));
    };

    return (
      <>
        {feedback && (
          <div className={classes.feedbackMessage}>
            <Notification
              text={errorMessage}
              theme="negative"
              autoHideDuration={NOTIFICATION_CLOSE_TIME}
            />
          </div>
        )}
        <CardWithLogo
          centered
          rightMenu={
            <div className="d-flex mr-s">
              <Dropdown
                className={classes.dropdown}
                onChange={dropDownChange}
                options={[
                  {
                    id: "de_DE",
                    label: "Deutsch",
                  },
                  {
                    id: "en_US",
                    label: "English",
                  },
                  {
                    id: "fr_FR",
                    label: "Français",
                  },
                ]}
                preset="select"
                value={dropDownValue}
              />
              <Link
                className="mx-l py-xs"
                onClick={navigateHelpRegister}
                id="resend"
              >
                {t("VIEWS.REGISTRATION.help")}
              </Link>
            </div>
          }
        >
          <h1 className={classes.createAccountTitle}>
            {t("VIEWS.REGISTRATION.account-registration")}
          </h1>
          <p className={classes.registerLabel}>
            {t("VIEWS.REGISTRATION.registration-info")}
          </p>
          {isLoading ? (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <SpinnerCircular
                className={classes.spinner}
                size={45}
                thickness={180}
                speed={100}
                color="#066AE3"
                secondaryColor="#C4C4C4"
              />
              <h2>{t("MESSAGES.validating-email")}</h2>
            </div>
          ) : (
            <div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className={classes.inputWrapper}>
                  <FormControl
                    caption={firstNameErrorMessage}
                    error={firstNameHasError}
                    positive={firstNameIsCorrect}
                    label={
                      <>
                        <span className="semi-bold">
                          {t("COMMON.first-name")}
                        </span>
                      </>
                    }
                  >
                    <Input
                      data-testid="firstName"
                      name="firstName"
                      type="text"
                      maxLength={NAME_MAX_LENGTH}
                      value={firstName}
                      error={firstNameHasError}
                      positive={firstNameIsCorrect}
                      className={classes.width80}
                      aria-label={t("COMMON.firstName-ariaLabel")}
                      onChange={handleFirstNameInput}
                      onBlur={validateFirstNameField}
                    />
                  </FormControl>
                </div>
                <div className={classes.inputWrapper}>
                  <FormControl
                    caption={lastNameErrorMessage}
                    error={lastNameHasError}
                    positive={lastNameIsCorrect}
                    label={
                      <>
                        <span className="semi-bold">
                          {t("COMMON.last-name")}
                        </span>
                      </>
                    }
                  >
                    <Input
                      name="lastName"
                      data-testid="lastName"
                      type="text"
                      maxLength={NAME_MAX_LENGTH}
                      value={lastName}
                      aria-label={t("COMMON.lastName-ariaLabel")}
                      onChange={handleLastNameInput}
                      className={classes.width80}
                      onBlur={validateLastNameField}
                    />
                  </FormControl>
                </div>
                <div className={classes.inputWrapper}>
                  <FormControl
                    caption={emailErrorMessage}
                    error={emailHasError}
                    positive={emailIsCorrect}
                    label={
                      <>
                        <span className="semi-bold">{t("COMMON.email")}</span>
                      </>
                    }
                  >
                    <Input
                      name="email"
                      placeholder={t("COMMON.email-placeholder")}
                      value={email}
                      aria-label={t("COMMON.email-ariaLabel")}
                      onChange={handleEmailInput}
                      className={classes.width80}
                      onBlur={validateEmailField}
                    />
                  </FormControl>
                </div>
                <div className={classes.inputWrapper}>
                  <FormControl
                    caption={emailVerificationErrorMessage}
                    error={emailVerificationHasError}
                    positive={emailVerificationIsCorrect}
                    label={
                      <>
                        <span className="semi-bold">
                          {t("COMMON.email-verification")}
                        </span>
                      </>
                    }
                  >
                    <Input
                      name="emailVerification"
                      placeholder={t("COMMON.email-placeholder")}
                      value={emailVerification}
                      aria-label={t("VIEWS.REGISTRATION.email-verification")}
                      onChange={handleEmailVerificationInput}
                      className={classes.width80}
                      onBlur={() => validateEmailVerificationField(true)}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </FormControl>
                </div>

                <FormControl
                  caption={termsErrorMessage}
                  error={termsHasError}
                  positive={termsIsCorrect}
                  label={<div></div>}
                >
                  <Checkbox
                    checked={terms}
                    id="terms"
                    name="terms"
                    onChange={onTermsChange}
                    className={classes.checkbox + " mb-xs mr-n-xl"}
                    data-testid="terms-checkbox"
                  >
                    <div style={{ whiteSpace: "break-spaces" }}>
                      {t("VIEWS.REGISTRATION.agree")}{" "}
                      <Link
                        data-testid="terms-conditions"
                        onClick={navigateToTermsConditions}
                      >
                        {t("VIEWS.REGISTRATION.terms-conditions")}
                      </Link>
                    </div>
                  </Checkbox>
                </FormControl>
                <div className="mr-n-l" style={{ whiteSpace: "break-spaces" }}>
                  {t("VIEWS.REGISTRATION.personal-data")}{" "}
                  <Link
                    data-testid="privacy-policy"
                    onClick={navigateToPrivacyPolicy}
                  >
                    {t("VIEWS.REGISTRATION.privacy-policy")}
                  </Link>
                </div>
                <Button
                  className="my-l"
                  disabled={captchaSolution.length < 1}
                  type="submit"
                  data-testid="register-btn"
                >
                  {t("VIEWS.REGISTRATION.register-account")}
                </Button>

                <FriendlyCaptcha
                  onDone={captchaOnDone}
                  onError={captchaOnError}
                  language={language}
                  key={language}
                />
              </form>
            </div>
          )}
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = {componentStack: "EnterCode"}
      return logError(error, info);
    },
  }
);

export default Registration;
