import { Button } from "@epo/epods-react-components";
import { Error } from "@epo/epods-react-components/lib/Icons/Error";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { config } from "../../config/config";
import { useMigrated, useUser } from "../../context/user-context";
import CardWithLogo from "../common/cardwidthlogo/CardWithLogo";
import MigrationEmail from "./migration-email/MigrationEmail";
import MigrationPassExisting from "./migration-pass-existing/MigrationPassExisting";
import MigrationPass from "./migration-pass/MigrationPass";
import { useStyles } from "./styles";
import SelectingMethods from "./selecting-methods/SelectingMethods";
import { ErrorFallback, logError } from "../errorHandling";

const Migration = withErrorBoundary(
  () => {
    const { authState } = useOktaAuth();
    const { migratedUser, setMigratedUser } = useMigrated();
    const { setUser } = useUser();
    let configOperation = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    const [t, i18n] = useTranslation("global");
    const classes = useStyles();

    const [anErrorHappened, setAnErrorHappened] = useState(false);

    useEffect(() => {
      document.title = t("VIEWS.MIGRATION.browser-tab-title");
      if (!authState?.isAuthenticated) {
        setUser(null);
      } else {
        axios
          .get(
            `${config.api.baseUrl}/users/${authState.idToken.claims.sub}`,
            configOperation
          )
          .then((response) => {            
            var locale:string = "en_US"
            if (response.data?.preferredLanguage === "DE") {
              locale = "de_DE";
            } else if (response.data?.preferredLanguage === "FR") {
              locale = "fr_FR";
            }    
            response.data.locale = locale;
            i18n.changeLanguage(locale.substring(0, 2))
            setUser(response.data);
            logOpenMigrationPage(response.data);
          })
          .catch(() => {
            setAnErrorHappened(true);
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.idToken.claims.sub]);

    useEffect(() => {
      if (
        authState.isAuthenticated &&
        authState.idToken.claims["accessToMigration"]
      ) {
        setMigratedUser({ ...migratedUser, migrated: true });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logOpenMigrationPage = (data) => {
      axios.post(
        config.api.baseUrl +
          "/logs/" +
          authState.idToken.claims.sub +
          "/log/info",
        {
          msg: "Opening migration page",
          component: "Migration",
          client: "CIAM APP",
          epoLineNumber: data.epolineNumber,
        },
        configOperation
      );
    };
    /**
     * SmartCard:
     * Checking the "accessToMigration" claim to check if the user has access to the
     * migration flow.
     *
     */
    return authState.idToken.claims["accessToMigration"] ? (
      <>
        {anErrorHappened ? (
          <CardWithLogo centered noLogo withNavbar>
            <div className="m-sm-m">
              <div
                className={classes.errorIconContainer + " m-sm-m text-center"}
              >
                <div className={classes.icon}>
                  <Error color="#BE0F05" size="30px" />
                </div>
              </div>
              <div className="m-sm-m text-center">
                <h2>{t("COMMON.generic-error")}</h2>
              </div>
              <Button
                theme="secondary"
                data-testid="reload-button"
                onClick={() => window.location.reload()}
              >
                {t("COMMON.try-again")}
              </Button>
            </div>
          </CardWithLogo>
        ) : (
          <>
            {migratedUser.step === "email" && <MigrationEmail />}
            {migratedUser.step === "password" && <MigrationPass />}
            {migratedUser.step === "password_clash" && <MigrationPassExisting />}
            {migratedUser.step === "methods" && <SelectingMethods />}
          </>
        )}
      </>
    ) : (
      <CardWithLogo centered noLogo withNavbar>
        <div className="pb-l">
          <h1 className="text-center">
            {t("VIEWS.MIGRATION.no-permission-migration-title")}
          </h1>
        </div>
        <div className="pb-m">
          {t("VIEWS.MIGRATION.no-permission-migration-description")}
        </div>
      </CardWithLogo>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = {componentStack: "EnterCode"}
      return logError(error, info);
    },
  }
);

export default Migration;
