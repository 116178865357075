import {
  Button,
  FormControl,
  Input,
} from "@epo/epods-react-components";
import { Info } from "@epo/epods-react-components/lib/Icons/Info";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { config } from "../../../config/config";
import { PASSWORD_REGEX } from "../../../constants";
import CardWithLogo from "../../common/cardwidthlogo/CardWithLogo";
import Notification from "../../common/notification/Notification";
import { SpinnerCircular } from "../../common/spinner/Spinner";
import { useStyles } from "./styles";
import {
  useMigrated,
  useUser,
} from "../../../context/user-context";
import { ErrorFallback, logError } from "../../errorHandling";

const MigrationPassExisting = withErrorBoundary(
  () => {
    const { authState } = useOktaAuth();
    let configOperation = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    const [t] = useTranslation("global");
    const classes = useStyles();
    const { migratedUser, setMigratedUser } = useMigrated();
    const [feedback, setFeedback] = useState(false);
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [passwordHasError, setPasswordHasError] = useState(false);
    const [passwordIsCorrect, setPasswordIsCorrect] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

    const { user } = useUser();

    const passwordCharacterCondition = PASSWORD_REGEX.test(password);

    const validatePasswordField = () => {
      setPasswordIsCorrect(false);
      setPasswordHasError(false);
      setPasswordErrorMessage("");
      if (password.length < 10 || !passwordCharacterCondition) {
        setPasswordErrorMessage(
          t("VIEWS.MIGRATION.clash-password-enter-valid")
        );
        setPasswordHasError(true);
      } else {
        setPasswordIsCorrect(true);
      }
    };

    const migrationError = (error) => {
      setIsLoading(false);
    };

    const setError = (errMessage: string) => {
      setFeedback(true);
      setErrorMessage(t(errMessage));
    };

    const handleSubmit = async (event: any) => {
      if (!authState?.isAuthenticated) {
        return null;
      } else if (checkFormValidity()) {
        setIsLoading(true);
        checkPassword();
        //clashMigration();
      }
      event.preventDefault();
    };
    const checkFormValidity = (): boolean => {
      validatePasswordField();
      return passwordIsCorrect;
    };

    const clashMigration = () => {
      axios
        .post(
          `${config.api.baseUrl}/migrate/${authState.idToken.claims.sub}/smart_card_user/clash`,
          {
            profile: {
              email: migratedUser?.email,
              login: migratedUser?.email,
              epolineNumber: user?.epolineNumber,
              oldLogin: user?.login,
            },
            credentials: {
              password: { value: password },
              passwordVerify: { value: password },
            },
            terms: migratedUser?.terms,
          },
          configOperation
        )
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          migrationError(error);
        });
    };

    const checkPassword = () => {
      axios
        .post(
          `${config.api.baseUrl}/migrate/${authState.idToken.claims.sub}/smart_card_user/check_password_clash`,
          {
            username: migratedUser.email,
            password: password,
          },
          configOperation
        )
        .then((response) => {
          setIsLoading(false);
          setMigratedUser({
            ...migratedUser,
            step: "methods",
            password: password,
            isClash: true,
          });
          clashMigration();
        })
        .catch((error) => {
          setFeedback(false);
          setIsLoading(false);
          if (error.response.status === 403) {

            if (error.response?.data?.hasEpolineNumber) {
              setFeedback(true);
              setError("VIEWS.MIGRATION.clash-has-epolinenumber");
            } else {
              setPasswordHasError(true);
              setPasswordIsCorrect(false);
              setPasswordErrorMessage(
                t("VIEWS.MIGRATION.clash-password-enter-valid")
              );
            }
          } else {
            setFeedback(true);
            setError("VIEWS.MIGRATION.generic-error");
          }
        });
    };

    useEffect(() => {
      const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      };
  
      document.addEventListener('keydown', handleKeyPress);
  
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }, []);
    
    return (
      <>
        {feedback && <Notification text={errorMessage} theme="negative" />}
        <CardWithLogo centered noLogo withNavbar widthPx="560px">
          <>
            <h1>{t("VIEWS.MIGRATION.title-enter-your-password")}</h1>
            <div className={classes.info + " d-flex p-m"}>
              <Info className="mr-s" color="#066AE3" size="24px" />
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("VIEWS.MIGRATION.already-registered-info", {
                      email: migratedUser.email,
                    }),
                  }}
                ></div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("VIEWS.MIGRATION.already-registered-info-cont", {
                      email: migratedUser.email,
                    }),
                  }}
                ></div>
              </div>
            </div>
            {isLoading ? (
              <div className={classes.spinnerWrapper}>
                <SpinnerCircular
                  data-testid="spinner"
                  className={classes.spinner}
                  size={45}
                  thickness={180}
                  speed={100}
                  color="#066AE3"
                  secondaryColor="#C4C4C4"
                />
              </div>
            ) : (
              <form onSubmit={(e) => handleSubmit(e)}>
                <FormControl
                  className="mt-l"
                  caption={passwordErrorMessage}
                  error={passwordHasError}
                  positive={passwordIsCorrect}
                  label={
                    <>
                      <span className="semi-bold">
                        {t("VIEWS.MIGRATION.input-label-password")}
                      </span>
                    </>
                  }
                >
                  <Input
                    data-testid="password"
                    name="password"
                    type="password"
                    clearable
                    value={password}
                    error={passwordHasError}
                    positive={passwordIsCorrect}
                    aria-label={t("COMMON.password-ariaLabel")}
                    onBlur={() => validatePasswordField()}
                    onChange={(e: any) => {
                      setPassword(e.target.value);
                      setFeedback(false);
                    }}
                  />
                </FormControl>
                <div>{t("VIEWS.MIGRATION.forgotten-password")}</div>
                <div className="mt-s">
                  {" "}
                  {t("VIEWS.MIGRATION.contact-support")}
                </div>
                <div className="row mt-xl">
                  <div className="col text-right">
                    <Button
                      data-testid="back"
                      theme="secondary"
                      onClick={() => {
                        setMigratedUser({ ...migratedUser, step: "email" });
                      }}
                    >
                      {t("VIEWS.MIGRATION.back")}
                    </Button>
                    <Button type="submit" className="ml-m" data-testid="next">
                      {t("VIEWS.MIGRATION.next")}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </>
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = {componentStack: "EnterCode"}
      return logError(error, info);
    },
  }
);
export default MigrationPassExisting;
