import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  wrapper: {
    marginTop: "var(--epods--space-inset-m)",
    marginLeft: "auto",
    marginRight: "auto",
  },
  top: {
    justifyContent: "space-between",
    padding: "var(--epods--space-inset-m)",
  },
  bottomButtons: {
    justifyContent: "space-between",
  },
  preWrap: {
    whiteSpace: "pre-wrap",
  },
  image: {
    height: "50px",
  },
  alignCenter: {
    textAlign: "center",
  },
  alignItems: {
    alignItems: "center",
  },
  sendCode: {
    paddingLeft: "20px",
  },
  width180: {
    width: "180px",
  },
});
