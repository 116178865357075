import { BrowserRouter as Router } from "react-router-dom";
import AppWithRouterAccess from "./AppWithRouterAccess";

const App = () => {
  return (
    <div>
      <Router>
        <AppWithRouterAccess />
      </Router>
    </div>
  );
};
export default App;
