import { Button, FormControl, Input } from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { config } from "../../../config/config";
import { useUser } from "../../../context/user-context";
import { useStyles } from "./styles";
import CardWithLogo from "../../common/cardwidthlogo/CardWithLogo";
import Notification from "../../common/notification/Notification";
import { PASSWORD_REGEX } from "../../../constants";
import { ErrorFallback, logError } from "../../errorHandling";

const ChangePassword = withErrorBoundary(
  () => {
    const { authState } = useOktaAuth();
    let history = useHistory();
    let configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };
    const [t] = useTranslation("global");
    const classes = useStyles();
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVerification, setPasswordVerification] = useState("");
    const [feedback, setFeedback] = useState(false);
    const [oldPasswordHasError, setOldPasswordHasError] = useState(false);
    const [oldPasswordIsCorrect, setOldPasswordIsCorrect] = useState(false);
    const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState("");
    const [passwordHasError, setPasswordHasError] = useState(false);
    const [passwordIsCorrect, setPasswordIsCorrect] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const [passwordVerificationHasError, setPasswordVerificationHasError] =
      useState(false);
    const [passwordVerificationIsCorrect, setPasswordVerificationIsCorrect] =
      useState(false);
    const [
      passwordVerificationErrorMessage,
      setPasswordVerificationErrorMessage,
    ] = useState("");
    const [passwordVerificationTouched, setPasswordVerificationTouched] =
      useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const { user } = useUser();

    useEffect(() => {
      logOpenChangePasswordPage();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logOpenChangePasswordPage = () => {
      axios.post(
        config.api.baseUrl +
          "/logs/" +
          authState.idToken.claims.sub +
          "/log/info",
        {
          msg: "Opening Change Password page",
          component: "ChangePassword",
          client: "CIAM APP",
          loginName: user?.login,
          emailAddress: user?.email,
          epolinenumber: user?.epolineNumber,
        },
        configHeaders
      );
    };

    const redirect = (path: string, state?) => {
      const location = {
        pathname: `/${path}`,
        state,
      };
      history.push(location);
    };

    const setError = (errMessage: string) => {
      setErrorMessage(t(errMessage));
      setFeedback(true);
    };

    const validateOldPasswordField = () => {
      setOldPasswordIsCorrect(false);
      setOldPasswordHasError(false);
      setOldPasswordErrorMessage("");
      if (oldPassword.length < 10 || !PASSWORD_REGEX.test(oldPassword)) {
        setOldPasswordErrorMessage(
          t("VIEWS.CHANGE-PASSWORD.old-password-enter-valid")
        );
        setOldPasswordHasError(true);
      } else {
        setOldPasswordIsCorrect(true);
      }
    };

    const validatePasswordField = () => {
      setPasswordIsCorrect(false);
      setPasswordHasError(false);
      setPasswordErrorMessage("");
      if (password.length < 10 || !PASSWORD_REGEX.test(password)) {
        setPasswordErrorMessage(
          t("VIEWS.CHANGE-PASSWORD.password-enter-valid")
        );
        setPasswordHasError(true);
      } else {
        setPasswordIsCorrect(true);
      }
      validatePasswordVerificationField(false);
    };

    const validatePasswordVerificationField = (isBlur: boolean) => {
      setPasswordVerificationIsCorrect(false);
      setPasswordVerificationHasError(false);
      setPasswordVerificationErrorMessage("");
      if (passwordVerification) {
        if (password !== passwordVerification) {
          setPasswordVerificationHasError(true);
          setPasswordVerificationErrorMessage(
            t("VIEWS.CHANGE-PASSWORD.passwords-do-not-match")
          );
        } else {
          setPasswordVerificationIsCorrect(true);
        }
        /**
         * Check empty verification password
         * only triggers if field has been touched
         * to prevent error to show when checking
         * on password field check
         */
      } else if (isBlur || passwordVerificationTouched) {
        setPasswordVerificationTouched(true);
        setPasswordVerificationHasError(true);
        setPasswordVerificationErrorMessage(
          t("VIEWS.CHANGE-PASSWORD.password-validation-enter-valid")
        );
      }
    };

    const checkFormValidity = (): boolean => {
      validateOldPasswordField();
      validatePasswordField();
      validatePasswordVerificationField(true);
      return (
        oldPasswordIsCorrect &&
        passwordIsCorrect &&
        passwordVerificationIsCorrect
      );
    };
    const handleSubmit = () => {
      setFeedback(false);
      if (!authState?.isAuthenticated) {
        return null;
      } else if (checkFormValidity()) {
        axios
          .post(
            config.api.baseUrl +
              "/users/" +
              authState.idToken.claims.sub +
              "/credentials/change_password",
            {
              oldPassword: { value: oldPassword },
              newPassword: { value: password },
              newPasswordVerify: { value: passwordVerification },
            },
            configHeaders
          )
          .then(() => {
            redirect("account", { fromPasswordChange: true });
          })
          .catch((error) => {
            if (
              error.response?.status === 403 &&
              error.response?.data?.errorCauses[0]?.errorSummary?.includes(
                "Password has been used too recently"
              )
            ) {
              setError("COMMON.VALIDATION.password-used-error");
            } else if (error.response?.data?.errorCauses) {
              setError(
                JSON.stringify(error.response.data.errorCauses)
                  .replaceAll("errorSummary", "")
                  .replaceAll("{", "")
                  .replaceAll("}", "")
                  .replaceAll("[", "")
                  .replaceAll("]", "")
                  .replaceAll(":", "")
                  .replaceAll('"', "")
              );
            } else if (error.response?.data?.errors) {
              setError(
                JSON.stringify(error.response.data.errors[0])
                  .replaceAll("errorSummary", "")
                  .replaceAll("{", "")
                  .replaceAll("}", "")
                  .replaceAll("[", "")
                  .replaceAll("]", "")
                  .replaceAll(":", "")
                  .replaceAll('"', "")
              );
            } else {
              setError("COMMON.generic-error");
            }
          });
      }
    };

    return (
      <>
        {feedback && <Notification theme="negative" text={errorMessage} />}
        <CardWithLogo centered noLogo withNavbar>
          <h1>{t("VIEWS.CHANGE-PASSWORD.change-your-password")}</h1>
          <div className="mt-xl">
            <FormControl
              label={t("VIEWS.CHANGE-PASSWORD.old-password")}
              caption={oldPasswordErrorMessage}
              error={oldPasswordHasError}
            >
              <Input
                type="password"
                value={oldPassword}
                error={oldPasswordHasError}
                onChange={(e: any) => {
                  setOldPassword(e.target.value);
                  setFeedback(false);
                }}
                onBlur={validateOldPasswordField}
                data-testid="old-password-verify-input"
              />
            </FormControl>

            <FormControl
              label={t("VIEWS.CHANGE-PASSWORD.new-password")}
              caption={passwordErrorMessage}
              error={passwordHasError}
            >
              <Input
                type="password"
                value={password}
                error={passwordHasError}
                onChange={(e: any) => {
                  setPassword(e.target.value);
                  setFeedback(false);
                }}
                onBlur={validatePasswordField}
                data-testid="password-input"
              />
            </FormControl>
            <div
              className={classes.passwordCondition}
              style={{
                marginTop: passwordHasError ? "-16px" : "-12px",
                color: passwordHasError ? "#BF360C" : "",
              }}
            >
              <span>
                {t("VIEWS.CHANGE-PASSWORD.password-length-condition")}
              </span>
              <br />
              <span>
                {t("VIEWS.CHANGE-PASSWORD.password-character-condition")}
              </span>
            </div>
          </div>
          <FormControl
            label={t("VIEWS.CHANGE-PASSWORD.repeat-new-password")}
            caption={passwordVerificationErrorMessage}
            error={passwordVerificationHasError}
          >
            <Input
              type="password"
              value={passwordVerification}
              error={passwordVerificationHasError}
              onChange={(e: any) => {
                setPasswordVerification(e.target.value);
                setFeedback(false);
              }}
              onBlur={() => validatePasswordVerificationField(true)}
              onPaste={(e) => {
                e.preventDefault();
              }}
              data-testid="password-verify-input"
            />
          </FormControl>
          <div className="row mt-xl">
            <div className="col text-right">
              <Button
                data-testid="cancel-btn"
                theme="secondary"
                onClick={() => redirect("account")}
              >
                {t("VIEWS.CHANGE-EMAIL.cancel")}
              </Button>
              <Button
                type="submit"
                className="ml-m"
                data-testid="save-changes"
                onClick={handleSubmit}
              >
                {t("VIEWS.CHANGE-EMAIL.save-changes")}
              </Button>
            </div>
          </div>
        </CardWithLogo>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = {componentStack: "EnterCode"}
      return logError(error, info);
    },
  }
);

export default ChangePassword;
