import { Button } from '@epo/epods-react-components';
import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { config } from '../../config/config';
import i18n from '../../i18n';
import CardWithLogo from '../common/cardwidthlogo/CardWithLogo';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const Home = () => {
  const [t] = useTranslation('global');
  const history = useHistory();
  const [homeTranslation, setHomeTranslation] = useState('');
  const { authState } = useOktaAuth();
  let configOperation = {
    headers: {
      Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
    },
    withCredentials: true,
  };
  let query = useQuery();

  const redirect = () => {
    if (authState?.isAuthenticated) {
      history.push('/account');
    } else {
      history.push('/login');
    }
  };

  useEffect(() => {
    const param = query.get('type_hint');
    if (param === 'PASSWORD_RECOVERY') {
      setHomeTranslation('password-recovery');
    } else {
      setHomeTranslation('welcome');
    }
    setLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLanguage = () => {
    if (authState?.isAuthenticated) {
      axios
        .get(
          `${config.api.baseUrl}/users/${authState.idToken.claims.sub}`,
          configOperation
        )
        .then((response) => {
          if (response.data?.preferredLanguage === 'DE') {
            response.data.locale = 'de_DE';
          } else if (response.data?.preferredLanguage === 'FR') {
            response.data.locale = 'fr_FR';
          } else {
            response.data.locale = 'en_US';
          }
          response.data?.locale &&
          (response.data?.locale === 'en_US' ||
            response.data?.locale === 'fr_FR' ||
            response.data?.locale === 'de_DE')
            ? i18n.changeLanguage(response.data?.locale.substring(0, 2))
            : i18n.changeLanguage('en_US');
        });
    } else {
      const userLang = navigator.language.substring(0, 2);
      if (userLang === 'fr') {
        i18n.changeLanguage(userLang);
      } else if (userLang === 'de') {
        i18n.changeLanguage(userLang);
      } else {
        i18n.changeLanguage('en_US');
      }
    }
  };

  return (
    <CardWithLogo centered>
      <div className="d-flex flex-column align-items-center">
        <h1 className="mt-s">{t(`VIEWS.HOME.${homeTranslation}`)}</h1>
        <div className="my-m p-m">
          <Button
            data-testid={'homepage-btn'}
            onClick={() => window.location.replace('https://www.epo.org/')}
          >
            {t('VIEWS.HOME.goto-homepage')}
          </Button>
          <Button
            data-testid={'login-btn'}
            theme="tertiary"
            className="ml-m"
            onClick={redirect}
          >
            {t('VIEWS.HOME.log-into-your-account')}
          </Button>
        </div>
      </div>
    </CardWithLogo>
  );
};
export default Home;
