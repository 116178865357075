import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { config } from "../../../config/config";
import i18n from "../../../i18n";
import CardWithLogo from "../../common/cardwidthlogo/CardWithLogo";
import { useMigrated, useUser } from "../../../context/user-context";
import { useStyles } from "./styles";
import { Button, Radio, RadioGroup } from "@epo/epods-react-components";
import { SpinnerCircular } from "../../common/spinner/Spinner";
import { BUTTON_DELAY } from "../../../constants";

const SelectingMethods = () => {
  const { user } = useUser();
  const [t] = useTranslation("global");
  const { authState } = useOktaAuth();
  const [value, setValue] = useState("secure");
  const classes = useStyles();
  const { migratedUser } = useMigrated();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let configHeaders = {
    headers: {
      Authorization: `Bearer ${authState.accessToken.accessToken}`,
    },
  };

  const checkUrl = () => {
    const url = window.location.href;
    setIsLoading(true);

    setTimeout(() => {
      if (url.startsWith("https://ciam.epo.org/")) {
        handleOptionProd();
      } else if (url.startsWith("https://ciam-o.epo.org/")) {
        handleOptionOSA();
      } else {
        handleOptionDev();
      }
    }, BUTTON_DELAY);
  };

  const radioHandler = (e: any) => {
    setValue(e.target.value);
    if (authState?.isAuthenticated && value === "email") {
      removeEmailMfa();
    } else if (authState?.isAuthenticated && value === "secure") {
      addEmailMfa();
    }
  };

  const addEmailMfa = async () => {
    await axios.post(
      config.api.baseUrl +
        "/users/" +
        authState.idToken.claims.sub +
        "/choseEmailMfa?enabled=true",
      {
        factorType: "email",
        provider: "OKTA",
        profile: {
          email: migratedUser.email,
        },
      },
      configHeaders
    );
  };

  const removeEmailMfa = async () => {
    await axios.post(
      config.api.baseUrl +
        "/users/" +
        authState.idToken.claims.sub +
        "/choseEmailMfa?enabled=false",
      {
        factorType: "email",
        provider: "OKTA",
        profile: {
          email: migratedUser.email,
        },
      },
      configHeaders
    );
  };

  const handleOptionDev = () => {
    if (authState?.isAuthenticated) {
      if (user.locale === "de_DE") {
        window.location.replace(
          `https://ciam-i.epo.org/success/mig-completed-i_DE.html?login=${migratedUser?.email}`
        );
      } else if (user.locale === "fr_FR") {
        window.location.replace(
          `https://ciam-i.epo.org/success/mig-completed-i_FR.html?login=${migratedUser?.email}`
        );
      } else {
        window.location.replace(
          `https://ciam-i.epo.org/success/mig-completed-i_EN.html?login=${migratedUser?.email}`
        );
      }
    }
  };

  const handleOptionOSA = () => {
    if (authState?.isAuthenticated) {
      if (user.locale === "de_DE") {
        window.location.replace(
          `https://ciam-o.epo.org/success/mig-completed-o_DE.html?login=${migratedUser?.email}`
        );
      } else if (user.locale === "fr_FR") {
        window.location.replace(
          `https://ciam-o.epo.org/success/mig-completed-o_FR.html?login=${migratedUser?.email}`
        );
      } else {
        window.location.replace(
          `https://ciam-o.epo.org/success/mig-completed-o_EN.html?login=${migratedUser?.email}`
        );
      }
    }
  };

  const handleOptionProd = () => {
    if (authState?.isAuthenticated) {
      if (user.locale === "de_DE") {
        window.location.replace(
          `https://ciam.epo.org/success/mig-completed_DE.html?login=${migratedUser?.email}`
        );
      } else if (user.locale === "fr_FR") {
        window.location.replace(
          `https://ciam.epo.org/success/mig-completed_FR.html?login=${migratedUser?.email}`
        );
      } else {
        window.location.replace(
          `https://ciam.epo.org/success/mig-completed_EN.html?login=${migratedUser?.email}`
        );
      }
    }
  };

  useEffect(() => {
    document.title = "EPO – select verification methods";
  }, []);

  if (isLoading) {
    return (
      <div className={classes.spinnerWrapper}>
        <SpinnerCircular
          data-testid="spinner"
          className={classes.spinner}
          size={45}
          thickness={180}
          speed={100}
          color="#066AE3"
          secondaryColor="#C4C4C4"
        />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <CardWithLogo noLogo>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div style={{ width: "100%" }}>
            <div
              className="mr-n-l"
              style={{ whiteSpace: "break-spaces" }}
            ></div>
            <div className={" m-sm-m text-center"}>
              <svg
                width="16"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 7.992C16 3.58 12.416 0 8 0S0 3.58 0 7.992c0 2.43 1.104 4.62 2.832 6.09.016.016.032.016.032.032.144.112.288.224.448.336.08.048.144.111.224.175a7.98 7.98 0 0 0 8.96 0c.08-.048.144-.111.224-.16.144-.111.304-.223.448-.335.016-.016.032-.016.032-.032 1.696-1.487 2.8-3.676 2.8-6.106Zm-8 7.001c-1.504 0-2.88-.48-4.016-1.279.016-.128.048-.255.08-.383a4.17 4.17 0 0 1 .416-.991c.176-.304.384-.576.64-.816.24-.24.528-.463.816-.639.304-.176.624-.304.976-.4A4.15 4.15 0 0 1 8 10.342a4.185 4.185 0 0 1 2.928 1.166c.368.368.656.8.864 1.295.112.288.192.592.24.911A7.03 7.03 0 0 1 8 14.993Zm-2.448-7.4a2.49 2.49 0 0 1-.208-1.024c0-.351.064-.703.208-1.023.144-.32.336-.607.576-.847.24-.24.528-.431.848-.575.32-.144.672-.208 1.024-.208.368 0 .704.064 1.024.208.32.144.608.336.848.575.24.24.432.528.576.847.144.32.208.672.208 1.023 0 .368-.064.704-.208 1.023a2.841 2.841 0 0 1-.576.848 2.84 2.84 0 0 1-.848.575 2.715 2.715 0 0 1-2.064 0 2.84 2.84 0 0 1-.848-.575 2.526 2.526 0 0 1-.56-.848v.001Zm7.424 5.306c0-.032-.016-.048-.016-.08a5.22 5.22 0 0 0-.688-1.406 4.884 4.884 0 0 0-1.088-1.135 5.21 5.21 0 0 0-1.04-.608c.168-.11.323-.24.464-.383.239-.236.448-.499.624-.784a3.623 3.623 0 0 0 .528-1.934 3.71 3.71 0 0 0-.288-1.47 3.799 3.799 0 0 0-.816-1.199 3.845 3.845 0 0 0-1.2-.8 3.72 3.72 0 0 0-1.472-.287 3.72 3.72 0 0 0-1.472.288 3.63 3.63 0 0 0-1.2.815 3.84 3.84 0 0 0-.8 1.199 3.71 3.71 0 0 0-.288 1.47c0 .352.048.688.144 1.007.096.336.224.64.4.927.16.288.384.544.624.784.144.144.304.271.48.383-.37.166-.72.376-1.04.624-.416.32-.784.703-1.088 1.119-.302.43-.534.904-.688 1.406-.016.032-.016.064-.016.08C1.776 11.636.992 9.91.992 7.992.992 4.14 4.144.991 8 .991s7.008 3.149 7.008 7.001a6.96 6.96 0 0 1-2.032 4.907Z"
                  fill="#5E5E5E"
                />
              </svg>
              {" " + migratedUser?.email}
            </div>

            <div className={"text-center"}>
              <h1 className="mt-s">
                {t("VIEWS.MIGRATION.verification-methods")}
              </h1>
            </div>
            <div className={classes.label}>
              <div className={"text-left"}>
                <h5>{t("VIEWS.MIGRATION.two-step")}</h5>
              </div>
            </div>

            <div className="ml-m w-100">
              <RadioGroup
                style={{ zIndex: 999 }}
                name="option"
                value={value}
                onChange={radioHandler}
                className={classes.label}
              >
                <Radio className="w-100 mb-xs" value="secure">
                  <div className={`${classes.label} w-100`}>
                    <strong>{t("VIEWS.MIGRATION.most-secure")}</strong>
                    {t("VIEWS.MIGRATION.verify-secure")}
                  </div>
                </Radio>

                <div className={classes.label}>
                  <div className="flex mb-l ml-l">
                    <li>{t("VIEWS.MIGRATION.okta-verify")}</li>
                    <li>{t("VIEWS.MIGRATION.google-authenticator")}</li>
                    <li>{t("VIEWS.MIGRATION.sms-verify")}</li>
                  </div>
                </div>
                <Radio className="w-100 mb-m" value="email">
                  <div className={classes.label}>
                    {t("VIEWS.MIGRATION.verify-email")}
                  </div>
                </Radio>
              </RadioGroup>
            </div>
            <div className={classes.label}>
              <div className={"text-center"}>
                {t("VIEWS.MIGRATION.footer-message-begin")}
                <strong>{t("VIEWS.MIGRATION.open-account-settings")}</strong>
                {t("VIEWS.MIGRATION.footer-message-end")}
              </div>
            </div>
            <div className="text-center mt-l p-m">
              <Button data-testid="goto-homepage" onClick={checkUrl}>
                {t("VIEWS.MIGRATION.set-up-verification-methods")}
              </Button>
            </div>
          </div>
        </div>
      </CardWithLogo>
    </div>
  );
};
export default SelectingMethods;
