import {
  Button,
  Card,
  FormControl,
  Input,
  Link,
} from "@epo/epods-react-components";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { config } from "../../../../config/config";
import Notification from "../../../common/notification/Notification";
import { useStyles } from "./styles";
import { ErrorFallback, logError } from "../../../errorHandling";

const EnterCode = withErrorBoundary(
  () => {
    const { authState } = useOktaAuth();
    let history = useHistory();
    let configHeaders = {
      headers: {
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      withCredentials: true,
    };

    const [t] = useTranslation("global");
    const classes = useStyles();
    const [feedback, setFeedback] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [code, setCode] = useState("");
    const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
    const [phoneCodeHasError, setPhoneCodeHasError] = useState(false);

    let phoneFactorId = "";
    const { phone } = (history.location && history.location.state) || {};

    const redirect = (
      path: string,
      queryParams?: { name: string; value: string }
    ) => {
      path = "/" + path;
      if (queryParams) {
        path = path.concat(`?${queryParams.name}=${queryParams.value}`);
      }
      history.push(path);
    };

    const sendAgain = () => {
      axios
        .post(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors",
          {
            factorType: "call",
            provider: "OKTA",
            profile: {
              phoneNumber: phone,
            },
          },
          configHeaders
        )
        .then(() => {
          return;
        })
        .catch((error) => {
          setFeedback(false);
          if (error.response.status === 429) {
            setFeedback(true);
            setErrorMessage(t("VIEWS.MFA.phone-already-sent-error-message"));
          } else {
            setFeedback(true);
            setErrorMessage(t("COMMON.generic-error"));
          }
        });
    };

    const handleSubmit = async () => {
      setPhoneErrorMessage("");
      setPhoneCodeHasError(false);
      await axios
        .get(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors",
          configHeaders
        )
        .then((response) => {
          response.data.forEach((factor, i) => {
            if (factor.factorType === "call") phoneFactorId = factor.id;
          });
        })
        .catch(() => {
          setFeedback(true);
          setErrorMessage(t("COMMON.generic-error"));
        });
      axios
        .post(
          config.api.baseUrl +
            "/users/" +
            authState.idToken.claims.sub +
            "/factors/" +
            phoneFactorId +
            "/activate_call_factor",
          {
            passCode: code,
          },
          configHeaders
        )
        .then(() => {
          redirect("MFA/enrolled-factors", {
            name: "mfa_name",
            value: "call",
          });
        })
        .catch((error) => {
          setFeedback(false);
          if (error.response.status === 403) {
            setPhoneCodeHasError(true);
            setPhoneErrorMessage(t("VIEWS.MFA.invalid-code"));
          } else if (
            error.response.status === 400 &&
            error.response?.data?.errors[0]?.passCode
          ) {
            setPhoneCodeHasError(true);
            setPhoneErrorMessage(t("VIEWS.MFA.phone-invalid-code"));
          } else {
            setFeedback(true);
            setErrorMessage(t("COMMON.generic-error"));
          }
        });
    };

    return (
      <>
        {feedback && <Notification text={errorMessage} theme="negative" />}
        <div className={classes.wrapper + " container"}>
          <Card hideHeader className={classes.cardWrapper}>
            <div className="mb-sm-l">
              <h1 className={classes.title}>{t("VIEWS.MFA.enter-the-code")}</h1>
              <FormControl
                caption={phoneErrorMessage}
                error={phoneCodeHasError}
                label={
                  <>
                    <span className={classes.sizeText18}>
                      {t("VIEWS.MFA.enter-code-phone")}
                    </span>
                  </>
                }
              >
                <Input
                  className={classes.inputPhone}
                  clearable
                  value={code}
                  onChange={(e) => [
                    setFeedback(false),
                    setCode(e.target.value),
                  ]}
                  data-testid="phone-enter-code"
                ></Input>
              </FormControl>
              <span className={classes.sizeText18}>
                {t("VIEWS.MFA.phone-call-not-received")}{" "}
                <Link data-testid="request-code" onClick={sendAgain}>
                  {t("VIEWS.MFA.request-code-phone")}
                </Link>
              </span>
            </div>

            <div className={classes.bottomButtons}>
              <Button
                className="mr-m"
                theme="secondary"
                data-testid="back-btn"
                onClick={() => redirect("MFA/phone-enter-number")}
              >
                {t("VIEWS.MFA.back")}
              </Button>
              <Button onClick={handleSubmit} data-testid="next-btn">
                {t("VIEWS.MFA.next")}
              </Button>
            </div>
          </Card>
        </div>
      </>
    );
  },
  {
    FallbackComponent: ErrorFallback,
    onError(error) {
      const info = { componentStack: "EnterCode" };
      return logError(error, info);
    },
  }
);

export default EnterCode;
