import { Button } from '@epo/epods-react-components';
import { CheckCircle } from '@epo/epods-react-components/lib/Icons/CheckCircle';
import { OktaAuth } from '@okta/okta-auth-js';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { config } from '../../config/config';
import CardWithLogo from '../common/cardwidthlogo/CardWithLogo';
import { useStyles } from './styles';
import { useLocation } from 'react-router-dom';
import React from 'react';
import i18n from '../../i18n';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

function LogOut() {
  const [t] = useTranslation('global');
  const classes = useStyles();
  const history = useHistory();
  const authClient = new OktaAuth(config.oidc);
  const [translation, setTranslation] = useState('');

  let query = useQuery();

  useEffect(() => {
    logout();
  });

  useEffect(() => {
    const param = query.get('type_hint');
    if (param === 'ATTEMPTS') {
      setTranslation('logged-out-too-many-attempts');
    } else {
      setTranslation('logged-out');
    }
    const userLang = navigator.language.substring(0, 2);
    if (userLang === 'fr') {
      i18n.changeLanguage(userLang);
    } else if (userLang === 'de') {
      i18n.changeLanguage(userLang);
    } else {
      i18n.changeLanguage('en_US');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = async () => {
    await authClient.revokeAccessToken(); // strongly recommended
    authClient.closeSession().catch((e) => {
      if (e.xhr && e.xhr.status === 429) {
        // Too many requests
      }
    });
  };

  const redirectToLogin = () => {
    history.push('/account');
  };

  return (
    <CardWithLogo centered>
      <div className="d-flex flex-column align-items-center">
        <CheckCircle className={classes.icon} size="48px" />
        <h1 className="mt-s" style={{ textAlign: 'center' }}>
          {' '}
          {t(`VIEWS.LOGOUT.${translation}`)}
        </h1>
        <div className="my-m p-m">
          <Button
            data-testid={'homepage-btn'}
            onClick={() => window.location.replace('https://www.epo.org/')}
          >
            {t('VIEWS.LOGOUT.goto-homepage')}
          </Button>
          <Button
            data-testid={'login-btn'}
            theme="tertiary"
            className="ml-m"
            onClick={redirectToLogin}
          >
            {t('VIEWS.LOGOUT.log-into-your-account')}
          </Button>
        </div>
      </div>
    </CardWithLogo>
  );
}

export default memo(LogOut);
